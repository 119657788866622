import Vue from 'vue'

export default (store) => {
  return new Vue({
    data: {
      criteria: {
        PC5Code: false,
        PC9Code: false,
        prodPricePos: false,
        prodType: false,
        seasonId: false
      },
      filterHash: {},
      showErrors: false
    },
    computed: {
      allSelected () {
        return this.selectedCodes.length === this.enabledSections.length
      },
      hasCriteria () {
        if (this.criteria.seasonId) {
          return true
        }
        return false
      },
      isLoading () {
        return this.$asyncComputed.results.updating
      },
      isValid () {
        return this.selectedCodes.length
      },
      params () {
        const keys = Object.keys(this.criteria)
        const params = keys.reduce((acc, key) => {
          if (this.criteria[key] && this.criteria[key].length) {
            if (Array.isArray(this.criteria[key])) {
              const stringValue = this.criteria[key].join(',')
              if (key === 'prodPricePos') {
                acc[key] = store.codes.mapProdPricePos(stringValue)
              } else {
                acc[key] = stringValue
              }
            } else if (typeof this.criteria[key] === 'string') {
              acc[key] = this.criteria[key]
            }
          }
          return acc
        }, {})

        // Short-circuit the pagination
        params.perPage = '-1'
        params.sortBy = 'colorwayName'
        return params
      },
      selectedCodes () {
        const selected = this.results.filter((option) => {
          return this.filterHash[option.PC9Code]
        })

        return selected.map((option) => {
          return option.PC9Code
        })
      },
      pc9Selection () {
        return this.results.map((option) => {
          if (option.powerCoreIndicator !== 'Y' && option.lscoMrpLock !== 'Y') {
            return option
          }
        }).filter(PC9Code => PC9Code !== undefined)
      },
      enabledSections () {
        return this.pc9Selection
      }
    },
    asyncComputed: {
      results: {
        default: [],
        lazy: true,
        async get () {
          if (this.hasCriteria) {
            const url = '/api/search/PC9'
            const response = await store.api.get({
              url,
              params: this.params
            })

            const justCodes = response.results.map((bom) => {
              return {
                PC9Code: bom.lscoColorwayCode,
                colorwayName: bom.colorwayName,
                lscoMrpLock: bom.lscoMrpLock,
                powerCoreIndicator: bom.powerCoreIndicator
              }
            })
            return justCodes
          }
          return []
        }
      }
    },
    methods: {
      deselectAll () {
        this.filterHash = {}
      },
      isSelected (pc9code) {
        return this.filterHash[pc9code]
      },
      reset ({
        PC5Code = false,
        PC9Code = false,
        prodPricePos = false,
        prodType = false,
        seasonId = false
      }) {
        this.criteria.PC9Code = false
        this.criteria.seasonId = false
        this.criteria.PC5Code = false
        this.criteria.prodPricePos = false
        this.criteria.prodType = false
        this.showErrors = false
        this.filterHash = {}

        // Give vue a tick to render the empty list before setting criteria
        this.$nextTick(() => {
          this.criteria.PC5Code = PC5Code
          this.criteria.prodPricePos = prodPricePos
          this.criteria.prodType = prodType
          this.criteria.PC9Code = PC9Code
          this.criteria.seasonId = seasonId
        })
      },
      selectAll () {
        this.filterHash = this.enabledSections.reduce((acc, option) => {
          acc[option.PC9Code] = true
          return acc
        }, {})
      },
      setSelected (pc9code, value) {
        Vue.set(this.filterHash, pc9code, value)
      }
    }
  })
}
