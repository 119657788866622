import Vue from 'vue'

export default (store, uid) => {
  return new Vue({
    data: {
      isLoading: false,
      results: [],
      selectedIndex: -1,
      showResults: false,
      uid
    },
    computed: {
      selectedResult () {
        if (this.selectedIndex === -1) {
          return false
        }
        return this.results[this.selectedIndex]
      }
    },
    methods: {
      buildParams ({ type, term, seasonId, perPage = 25, allowViewAll = false }) {
        if (type.startsWith('PC9') || type.startsWith('PC5')) {
          if (!seasonId) {
            seasonId = store.bomSearch.seasonId
          }
          return {
            perPage,
            seasonId,
            term,
            allowViewAll
          }
        } else if (store.findMaterial.sectionName === 'finish') {
          let types = store.findMaterial.types
          return {
            perPage,
            term,
            types
          }
        } else {
          return {
            perPage,
            term
          }
        }
      },
      clearSelection () {
        this.selectedIndex = -1
      },
      async fetch ({ type, term, id, seasonId, perPage, allowViewAll }) {
        this.isLoading = true
        const url = `/api/lookup/${type}`
        const params = this.buildParams({ type, term, seasonId, perPage, allowViewAll })
        const results = await store.api.get({
          url,
          params
        })

        if (results.results) {
          this.results = results.results
        } else {
          this.results = results
        }
        this.isLoading = false
      },
      hide () {
        this.showResults = false
      },
      pageDown () {
        if (this.selectedIndex + 5 < this.results.length - 1) {
          this.selectedIndex = this.selectedIndex + 5
        } else {
          this.selectedIndex = this.results.length - 1
        }
      },
      pageUp () {
        if (this.selectedIndex - 5 > 0) {
          this.selectedIndex = this.selectedIndex - 5
        } else {
          this.selectedIndex = 0
        }
      },
      reset (id) {
        this.results = []
        this.selectedIndex = -1
      },
      selectNext () {
        if (this.selectedIndex < this.results.length - 1) {
          this.selectedIndex = this.selectedIndex + 1
        } else {
          this.selectedIndex = -1
        }
      },
      selectPrevious () {
        if (this.selectedIndex > -1) {
          this.selectedIndex = this.selectedIndex - 1
        } else {
          this.selectedIndex = this.results.length - 1
        }
      },
      show () {
        this.showResults = true
      }
    }
  })
}
