<template>
  <div class="FavoriteSeasonCard">
    <div class="preCardHeader">
      <h3>
        <StarIcon />
        <span>Favorites</span>
      </h3>

      <ModalLink
        class="addFavoriteSeasonLink"
        name="add-favorite-season"
      >
        <PlusIcon />
        Add New
      </ModalLink>
    </div>
    <div class="card">
      <FavoriteSeasonList v-if="!isEmpty" />

      <div
        v-else-if="isEmpty"
        class="empty"
      >
        <div>
          <p>
            You have not added any Favorite Seasons.
          </p>
          <p>
            Click ‘+ Add New’ above to begin.
          </p>
        </div>
      </div>

      <Spinner
        v-if="!isReady"
        class="stretch"
      />
    </div>

    <Modal
      name="add-favorite-season"
      title="Add New Favorite Season"
    >
      <AddFavoriteSeason />
    </Modal>

    <Modal
      name="remove-favorite-season"
      title="Confirm Removal"
    >
      <FavoriteSeasonConfirmRemoval />
    </Modal>
  </div>
</template>

<script>
import AddFavoriteSeason from './AddFavoriteSeason.vue'
import FavoriteSeasonConfirmRemoval from './FavoriteSeasonConfirmRemoval.vue'
import FavoriteSeasonList from './FavoriteSeasonList.vue'
import ModalLink from '../ModalLink.vue'
import Modal from '../Modal.vue'
import PlusIcon from '../icons/PlusIcon.vue'
import Spinner from '../Spinner.vue'
import StarIcon from '../icons/StarIcon.vue'

export default {
  components: {
    AddFavoriteSeason,
    FavoriteSeasonConfirmRemoval,
    FavoriteSeasonList,
    ModalLink,
    Modal,
    PlusIcon,
    Spinner,
    StarIcon
  },
  inject: ['store'],
  computed: {
    isReady () {
      return this.store.favoriteSeasons.isReady
    },
    isEmpty () {
      return this.store.favoriteSeasons.isEmpty
    }
  }
}
</script>

<style lang="scss">
.FavoriteSeasonCard {
  display: flex;
  flex-direction: column;

  .preCardHeader {
    @include preCardHeader();

    .addFavoriteSeasonLink {
      display: flex;
      align-items: center;

      svg {
        margin-right: 0.5rem;
      }
    }
  }

  .card {
    @include card;

    display: flex;
    flex-direction: column;
    padding: 0;
    overflow: hidden;
    min-height: 134px;

    .Scroller {
      padding: 0;
    }

    .empty {
      padding: 3rem;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      color: $gray-sec;
    }
  }

  .Spinner {
    position: absolute;
    background-color: lighten($gray-overlay, 40%);
  }
}
</style>
