import Vue from 'vue'
import whitespaceToCommas from '../../util/whitespaceToCommas.js'

export default (store) => {
  return new Vue({
    data: {
      codesText: '',
      showErrors: false
    },
    computed: {
      PC9Code: {
        get () {
          return whitespaceToCommas(this.codesText).trim()
        },
        set (value) {
          this.codesText = value
        }
      },
      isValid () {
        return (
          store.assign.chooseSeason.seasonId.length &&
          this.PC9Code.length
        )
      }
    },
    methods: {
      reset () {
        this.codesText = ''
        this.showErrors = false
      }
    }
  })
}
