import localeCompare from '../../util/localeCompare.js'

const all = [{
  value: 'BON',
  label: 'Bonded'
}, {
  value: 'DNE',
  label: 'Drop needle'
}, {
  value: 'DOH',
  label: 'Honeycomb'
}, {
  value: 'DOW',
  label: 'Waffle'
}, {
  value: 'ENG',
  label: 'Engineered Knit'
}, {
  value: 'FAF',
  label: 'Faux Fur'
}, {
  value: 'FRB',
  label: 'French Rib'
}, {
  value: 'INT',
  label: 'Interlock'
}, {
  value: 'LAC',
  label: 'Lace'
}, {
  value: 'LIT',
  label: 'Loop Terry'
}, {
  value: 'MES',
  label: 'Mesh'
}, {
  value: 'MPIF',
  label: 'Micro Fleece'
}, {
  value: 'MPIQ',
  label: 'Micro Pique'
}, {
  value: 'NADK',
  label: 'Double Knit'
}, {
  value: 'NAFT',
  label: 'Felt'
}, {
  value: 'NAHK',
  label: 'Herringbone Knit'
}, {
  value: 'NAJK',
  label: 'Jaquard Knit'
}, {
  value: 'NAJR',
  label: 'Jersey'
}, {
  value: 'NANA',
  label: 'N/A'
}, {
  value: 'NAPU',
  label: 'Pucker Knit'
}, {
  value: 'NATR',
  label: 'Tricot'
}, {
  value: 'ONE',
  label: '1x1'
}, {
  value: 'OPK',
  label: 'Other (Pile Knit)'
}, {
  value: 'PDR',
  label: 'Ponte De Roma'
}, {
  value: 'PIF',
  label: 'Fleece'
}, {
  value: 'PIK',
  label: 'Pile Knit'
}, {
  value: 'PIS',
  label: 'Sherpa'
}, {
  value: 'PIT',
  label: 'French Terry'
}, {
  value: 'PL',
  label: 'Pointelle'
}, {
  value: 'PLR',
  label: 'Pointelle Rib'
}, {
  value: 'POL',
  label: 'Polar Fleece'
}, {
  value: 'PQB',
  label: "Bird's Eye Pique"
}, {
  value: 'RSE',
  label: 'Raschel'
}, {
  value: 'RSL',
  label: 'Raschel Lace'
}, {
  value: 'TEY',
  label: 'Terry'
}, {
  value: 'TWO',
  label: '2x2'
}, {
  value: 'VEL',
  label: 'Velour'
}, {
  value: 'VRB',
  label: 'Variegated Rib'
}, {
  value: 'FSB',
  label: 'Fusible'
}, {
  value: 'NAHR',
  label: 'Herringbone'
}, {
  value: 'PLW',
  label: 'Plain Weave'
}, {
  value: 'PVC',
  label: 'Other (Pocketing)'
}, {
  value: 'TWL',
  label: 'Twill'
}, {
  value: 'BAE',
  label: 'Barre'
}, {
  value: 'BAR',
  label: 'Barathea'
}, {
  value: 'BAT',
  label: 'Batiste'
}, {
  value: 'BEN',
  label: 'Bengaline'
}, {
  value: 'BOT',
  label: 'Burnout'
}, {
  value: 'BOU',
  label: 'Boucle'
}, {
  value: 'BRO',
  label: 'Brocade'
}, {
  value: 'CAR',
  label: 'Charmeuse'
}, {
  value: 'CDA',
  label: 'Corduroy - 15 wale to 19 wale'
}, {
  value: 'CDB',
  label: 'Bedford Cord'
}, {
  value: 'CDC',
  label: 'Crepe De Chine'
}, {
  value: 'CDM',
  label: 'Corduroy - Medium Wale (8-16 wale)'
}, {
  value: 'CDN',
  label: 'Corduroy - Narrow Wale (over 16 wale)'
}, {
  value: 'CDU',
  label: 'Uncut Cord'
}, {
  value: 'CDV',
  label: 'Corduroy - Novelty'
}, {
  value: 'CDW',
  label: 'Corduroy - Wide Wale (under 8 wale)'
}, {
  value: 'CDX',
  label: 'Corduroy - 9 wale to 14 wale'
}, {
  value: 'CDY',
  label: 'Corduroy - 8 wale or less'
}, {
  value: 'CDZ',
  label: 'Corduroy - 20 wale or greater'
}, {
  value: 'CHA',
  label: 'Challis'
}, {
  value: 'CHI',
  label: 'Chiffon'
}, {
  value: 'DAM',
  label: 'Damask'
}, {
  value: 'DCDW',
  label: 'Double Cloth/Double Weave'
}, {
  value: 'DOL',
  label: 'Leno'
}, {
  value: 'DOO',
  label: 'Hopsack'
}, {
  value: 'DOP',
  label: 'Pique'
}, {
  value: 'DOU',
  label: 'Huckaback'
}, {
  value: 'DSK',
  label: 'Doeskin'
}, {
  value: 'FAI',
  label: 'Faille'
}, {
  value: 'GAB',
  label: 'Gabardine'
}, {
  value: 'GAU',
  label: 'Gauze'
}, {
  value: 'GEO',
  label: 'Georgette'
}, {
  value: 'GRO',
  label: 'Grosgrain'
}, {
  value: 'HBRT',
  label: 'Heavyweight (12oz. And Up) Broken Twill'
}, {
  value: 'HHER',
  label: 'Heavyweight (12oz. And Up) Herringbone'
}, {
  value: 'HLHT',
  label: 'Heavyweight (12oz. And Up) 3x1 LHT'
}, {
  value: 'HNOV',
  label: 'Heavyweight (12oz. And Up) Novelty'
}, {
  value: 'HOU',
  label: 'Houndstooth'
}, {
  value: 'HRHT',
  label: 'Heavyweight (12oz. And Up) 3x1 RHT'
}, {
  value: 'LBRT',
  label: 'Lightweight (less than 8oz.) Broken Twill'
}, {
  value: 'LHER',
  label: 'Lightweight (less than 8oz.) Herringbone'
}, {
  value: 'LHTA',
  label: 'Twill 3x1 LHT'
}, {
  value: 'LHTB',
  label: 'Twill 2x1 LHT'
}, {
  value: 'LHTC',
  label: 'Twill 2x2 LHT'
}, {
  value: 'LHTD',
  label: 'Twill 2x1 RHT'
}, {
  value: 'LHTE',
  label: 'Twill 2x2 RHT'
}, {
  value: 'LHTF',
  label: 'Twill 3x1 RHT'
}, {
  value: 'LHTG',
  label: 'Twill 4/1 LHT'
}, {
  value: 'LHTH',
  label: 'Twill 4/1 RHT'
}, {
  value: 'LLHA',
  label: 'Lightweight (less than 8oz.) 2x1 LHT'
}, {
  value: 'LLHB',
  label: 'Lightweight (less than 8oz.) 2x2 LHT'
}, {
  value: 'LLHC',
  label: 'Lightweight (less than 8oz.) 3x1 LHT'
}, {
  value: 'LLHD',
  label: 'Lightweight (less than 8oz.) 2x1 RHT'
}, {
  value: 'LLHE',
  label: 'Lightweight (less than 8oz.) 2x2 RHT'
}, {
  value: 'LLHF',
  label: 'Lightweight (less than 8oz.) 3x1 RHT'
}, {
  value: 'LNOV',
  label: 'Lightweight (less than 8oz.) Novelty'
}, {
  value: 'MBRT',
  label: 'Midweight (8oz. -11.9oz.) Broken Twill'
}, {
  value: 'MEL',
  label: 'Melton'
}, {
  value: 'MHER',
  label: 'Midweight (8oz. -11.9oz.) Herringbone'
}, {
  value: 'MLHA',
  label: 'Midweight (8oz. -11.9oz.) 2x1 LHT'
}, {
  value: 'MLHB',
  label: 'Midweight (8oz. -11.9oz.) 2x2 LHT'
}, {
  value: 'MLHC',
  label: 'Midweight (8oz. -11.9oz.) 3x1 LHT'
}, {
  value: 'MLHD',
  label: 'Midweight (8oz. -11.9oz.) 2x1 RHT'
}, {
  value: 'MLHE',
  label: 'Midweight (8oz. -11.9oz.) 2x2 RHT'
}, {
  value: 'MLHF',
  label: 'Midweight (8oz. -11.9oz.) 3x1 RHT'
}, {
  value: 'MNOV',
  label: 'Midweight (8oz. -11.9oz.) Novelty'
}, {
  value: 'MOL',
  label: 'Moleskin'
}, {
  value: 'MUS',
  label: 'Muslin'
}, {
  value: 'NARS',
  label: 'Rip Stop'
}, {
  value: 'NASC',
  label: 'Suedecloth'
}, {
  value: 'NATW',
  label: 'Tweed'
}, {
  value: 'NAV',
  label: 'Velvet'
}, {
  value: 'OBC',
  label: 'Other (Brushed Cloth)'
}, {
  value: 'OBW',
  label: 'Basket Weave'
}, {
  value: 'ODOB',
  label: 'Dobby'
}, {
  value: 'OJAC',
  label: 'Jaquard'
}, {
  value: 'OPC',
  label: 'Other (Puckered Cloth)'
}, {
  value: 'OPLW',
  label: 'Plain Weave'
}, {
  value: 'ORO',
  label: 'Organza / Organdy'
}, {
  value: 'OTWI',
  label: 'Other (Twill)'
}, {
  value: 'PAN',
  label: 'Panama'
}, {
  value: 'PER',
  label: 'Percale'
}, {
  value: 'PLI',
  label: 'Plisse'
}, {
  value: 'PWV',
  label: 'Velvet/Velveteen'
}, {
  value: 'SAN',
  label: 'Satin'
}, {
  value: 'SAT',
  label: 'Sateen'
}, {
  value: 'SDC',
  label: 'Swiss Dot / Clip Spot'
}, {
  value: 'SHA',
  label: 'Shantung'
}, {
  value: 'SHR',
  label: 'Shearling'
}, {
  value: 'SWC',
  label: 'Canvas'
}, {
  value: 'SWH',
  label: 'Chambray'
}, {
  value: 'SWL',
  label: 'Lawn / Voile'
}, {
  value: 'SWN',
  label: 'Linen'
}, {
  value: 'SWO',
  label: 'Ottoman'
}, {
  value: 'SWP',
  label: 'Poplin'
}, {
  value: 'SWS',
  label: 'Seersucker'
}, {
  value: 'SWT',
  label: 'Sheeting'
}, {
  value: 'SWX',
  label: 'Oxford'
}, {
  value: 'TAF',
  label: 'Taffeta'
}, {
  value: 'TLB',
  label: 'Broken Twill'
}, {
  value: 'TLC',
  label: 'Cavalry Twill'
}, {
  value: 'TLF',
  label: 'Flannel'
}]

export default all.sort((lhs, rhs) => {
  return localeCompare(lhs.label, rhs.label)
})
