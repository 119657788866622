import capitalizeWords from '../util/capitalizeWords.js'
import Vue from 'vue'

const components = (store) => {
  return new Vue({
    methods: {
      formatComponent (component) {
        const display = store.codes.getComponentLabel(component).toUpperCase()
        if (display && display.length) {
          return display
        }
        return '(No Component)'
      },
      formatComponentLocation (location) {
        const label = store.codes.getLocationLabel(location)
        const display = capitalizeWords(label)
        if (display && display.length) {
          return display
        }
        return '(No Component Location)'
      }
    }
  })
}

export default components
