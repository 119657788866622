<template>
  <div class="FavoriteSeasonConfirmRemoval">
    <div>
      <em class="highlight">{{ seasonName }}</em>
      will be removed from your favorite seasons.
    </div>
    <div class="action-block">
      <button
        class="button cancel"
        @click="onCancelClick"
      >
        Cancel
      </button>
      <button
        class="button remove"
        @click="onConfirmClick"
      >
        Remove
      </button>
    </div>
  </div>
</template>

<script>
export default {
  inject: [ 'store' ],
  computed: {
    season () {
      const { seasonId } = this.store.modals.params
      return this.store.seasons.find(seasonId)
    },
    seasonName () {
      if (this.season) {
        return this.season.seasonName
      }
      return ''
    }
  },
  methods: {
    onCancelClick () {
      this.store.modals.hide()
    },
    onConfirmClick () {
      this.store.favoriteSeasons.remove(this.season.seasonId)
      this.store.modals.hide(true)
    }
  }
}
</script>
