<template>
  <li
    class="FavoriteSeasonItem"
    :class="{ highlighted: isHighlighted }"
    :data-id="season.seasonId"
  >
    <div class="handle">
      <GrabHandleIcon />
    </div>

    <div class="title">
      {{ season.seasonName }}
    </div>

    <div class="contextMenu">
      <FavoriteSeasonContextMenu :season="season" />
    </div>
  </li>
</template>

<script>
import FavoriteSeasonContextMenu from './FavoriteSeasonContextMenu.vue'
import GrabHandleIcon from '../icons/GrabHandleIcon.vue'

export default {
  components: {
    FavoriteSeasonContextMenu,
    GrabHandleIcon
  },
  inject: [ 'store' ],
  props: {
    season: {
      type: Object,
      required: true
    }
  },
  computed: {
    isHighlighted () {
      return this.season.seasonId === this.store.addFavoriteSeason.lastSeasonId
    }
  }
}
</script>

<style lang="scss">
.FavoriteSeasonItem {
  display: flex;
  align-items: center;
  padding: 16px 0;
  background-color: white;
  height: 60px;

  // Prevent highlight animations from snapping in new borders
  border: 1px solid transparent;

  &:not(:last-child) {
    border-bottom: 1px solid $gray-lines;
  }

  &.highlighted {
    animation: SelectAndFade 2s none;
  }

  .handle {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-basis: 32px;
    align-self: stretch;
    cursor: grab;
    transition: opacity 250ms ease;
  }

  .title {
    flex-grow: 2;
  }

  .contextMenu {
    width: 32px;
    text-align: right;
    height: 100%;
    padding: 0;
    opacity: 1;
    transition: opacity 250ms ease;
  }

  &.sortable-chosen {
    .contextMenu {
      display: none;
    }
  }

  &.sortable-fallback {
    div.handle {
      opacity: 1;
    }
  }

  &.sortable-ghost {
    background-color: $blue-light;

    .handle {
      opacity: 0;
    }
  }
}

</style>
