export default {
  fabric: 'Fabric',
  sundries: 'Sundries',
  thread: 'Thread',
  finish: 'Finish',
  embellishment: 'Embellishment',
  careLabel: 'Care Labels',
  other: 'Other',
  artwork: 'Artwork',
  pkgAndLabels: 'Packaging'
}
