export default [
  {
    value: 'Both',
    label: 'Both'
  }, {
    value: 'Y',
    label: 'Yes'
  }, {
    value: 'N',
    label: 'No'
  }]
