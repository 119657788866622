import Vue from 'vue'

const createDevBomPreview = (store) => {
  return new Vue({
    data: {
      sectionsToCopy: []
    },
    computed: {
      allSectionsSelected () {
        return this.sectionsToCopy.length === store.codes.rbacSectionOptions.length
      },
      showClearAll () {
        return this.sectionsToCopy.length > 0
      }
    },
    methods: {
      selectAllSections () {
        this.sectionsToCopy = store.codes.rbacSectionOptions.map((option) => {
          return option.value
        })
      },
      clearAllSections () {
        this.sectionsToCopy = []
      }
    }
  })
}

export default createDevBomPreview
