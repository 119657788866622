const capitalizeWord = (word) => {
  return word.charAt(0).toUpperCase() + word.substring(1)
}

export default (str = '') => {
  if (!str) {
    return ''
  }
  return str.toLowerCase().split(/\s+/g).map(capitalizeWord).join(' ')
}
