import localeCompare from '../../util/localeCompare.js'

const all = [{
  value: 'DK',
  label: 'Double Knit'
}, {
  value: 'FC',
  label: 'Fleece'
}, {
  value: 'FE',
  label: 'Fleece [TO BE REMOVED]'
}, {
  value: 'FL',
  label: 'Felt'
}, {
  value: 'HK',
  label: 'Herringbone Knit'
}, {
  value: 'IC',
  label: 'Interlock'
}, {
  value: 'JE',
  label: 'Jersey'
}, {
  value: 'KJ',
  label: 'Jacquard Knit'
}, {
  value: 'PC',
  label: 'Pucker Knit'
}, {
  value: 'PI',
  label: 'Pile Knit'
}, {
  value: 'PQ',
  label: 'Pique'
}, {
  value: 'PVC',
  label: 'PVC [TO BE REMOVED]'
}, {
  value: 'RB',
  label: 'Rib'
}, {
  value: 'TR',
  label: 'Tricot'
}, {
  value: 'FU',
  label: 'Fusible'
}, {
  value: 'IN',
  label: 'Interlinings'
}, {
  value: 'PV',
  label: 'Pocketing'
}, {
  value: 'BC',
  label: 'Bedford Cord'
}, {
  value: 'BE',
  label: 'Basket Weave'
}, {
  value: 'BH',
  label: 'Brushed Cloth'
}, {
  value: 'CD',
  label: 'Corduroy'
}, {
  value: 'DD',
  label: 'Double Cloth/Double Weave'
}, {
  value: 'DE',
  label: 'Denim'
}, {
  value: 'DO',
  label: 'Dobby'
}, {
  value: 'HE',
  label: 'Herringbone'
}, {
  value: 'JA',
  label: 'Jacquard'
}, {
  value: 'OM',
  label: 'Ottoman'
}, {
  value: 'PK',
  label: 'Puckered Cloth'
}, {
  value: 'PW',
  label: 'Pile Weave'
}, {
  value: 'RS',
  label: 'Rip Stop'
}, {
  value: 'SA',
  label: 'Satin'
}, {
  value: 'SC',
  label: 'Suede [TO BE REMOVED]'
}, {
  value: 'SE',
  label: 'Sateen'
}, {
  value: 'SR',
  label: 'Shearling'
}, {
  value: 'SW',
  label: 'Plain Weave'
}, {
  value: 'TD',
  label: 'Tweed'
}, {
  value: 'TL',
  label: 'Twill'
}]

export default all.sort((lhs, rhs) => {
  return localeCompare(lhs.label, rhs.label)
})
