import Vue from 'vue'

const seasons = (store) => {
  return new Vue({
    computed: {
      error () {
        return this.$asyncComputed.items.exception
      },
      isLoading () {
        const notStarted = (
          this.items.length === 0 &&
          this.$asyncComputed.items.success
        )
        return (
          notStarted ||
          this.$asyncComputed.items.updating
        )
      },
      isReady () {
        return (
          this.$asyncComputed.items.success &&
          this.items.length > 0
        )
      },
      isUnauthorized () {
        return (
          this.error &&
          this.error.status === 401
        )
      }
    },
    asyncComputed: {
      items: {
        default: [],
        lazy: true,
        async get () {
          const response = await store.api.get({
            url: '/api/seasons',
            params: {
              perPage: -1
            }
          })
          if (response.message === 'Unauthorized') {
            const error = new Error('Unauthorized')
            error.status = 401
            throw error
          }
          return response.results.filter((result) => {
            return result.year
          })
        }
      }
    },
    methods: {
      find (seasonId) {
        return this.items.find((season) => {
          return season.seasonId === seasonId
        })
      },
      findByParts ({ brand, gender, season, type, year }) {
        return this.items.find((seasonItem) => {
          return (
            seasonItem.lscoBrand === brand &&
            seasonItem.lscoGender === gender &&
            seasonItem.lscoSeason === season &&
            seasonItem.lscoCategory === type &&
            seasonItem.year === year
          )
        })
      }
    }
  })
}

export default seasons
