import { decode, encode } from '../util/params.js'
import Vue from 'vue'

const modals = (store) => {
  return new Vue({
    computed: {
      params () {
        return decode(store.router.hash.modalParams || '')
      }
    },
    methods: {
      hide (useReplace = false) {
        store.router.clearHash([ 'modal', 'modalParams' ], { useReplace })
      },
      isShowing (name) {
        return store.router.hash.modal === name
      },
      show (name, params = {}) {
        if (Object.keys(params).length) {
          const paramsStr = encode(params)
          store.router.setHash({
            modal: name,
            modalParams: paramsStr
          })
        } else {
          store.router.setHash({ modal: name })
        }
      },
      updateParams (newParams = {}, options) {
        const mergedParams = { ...this.params, ...newParams }
        const paramsStr = encode(mergedParams)
        store.router.setHash({
          modalParams: paramsStr
        }, options)
      }
    }
  })
}

export default modals
