import Vue from 'vue'

const alertDialog = (store) => {
  return new Vue({
    data: {
      cancelFunc: false,
      cancelClass: false,
      cancelText: false,
      component: false,
      confirmFunc: false,
      confirmClass: false,
      confirmText: false,
      isShowing: false,
      props: false,
      resolve: false,
      title: false
    },
    methods: {
      cancel () {
        this.hide(false)
      },
      confirm () {
        this.hide(true)
      },
      hide (result) {
        const resolve = this.resolve
        if (resolve) {
          resolve(result)
        }
        this.reset()
      },
      reset () {
        this.cancelClass = false
        this.cancelText = false
        this.component = false
        this.confirmClass = false
        this.confirmText = false
        this.isShowing = false
        this.props = false
        this.result = false
        this.title = false
      },
      show ({
        cancelClass = '',
        cancelText = 'Cancel',
        component,
        confirmClass = '',
        props,
        title = 'Notification'
      }) {
        // console.log("js")
        this.cancelClass = cancelClass
        this.cancelText = cancelText
        this.component = component
        this.confirmClass = confirmClass
        this.props = props
        this.title = title

        this.isShowing = true
        return new Promise((resolve) => {
          this.resolve = resolve
        })
      }
    }
  })
}

export default alertDialog
