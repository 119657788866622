<template>
  <div
    :key="item.bompartId"
    class="tr FavoriteDevBomItem"
  >
    <div class="td title">
      <router-link :to="linkTarget">
        {{ item.title }}
      </router-link>
    </div>
    <div class="td createdBy">
      {{ item.authorName }}
    </div>
    <div class="td lastEdited">
      {{ timeAgo }}
    </div>
    <div class="td menu">
      <DevBomContextMenu :item="item" />
    </div>
  </div>
</template>

<script>
import DevBomContextMenu from './FavoriteDevBomContextMenu.vue'
import time from '../../util/time.js'

export default {
  components: {
    DevBomContextMenu
  },
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  computed: {
    linkTarget () {
      return `/dev-bom/fabric?id=${this.item.bompartId}`
    },
    timeAgo () {
      return time.ago(this.item.modifyStamp)
    }
  }
}
</script>
