export default {
  brand: {
    489: 'Denizen',
    482: 'Dockers',
    483: "Levi's",
    276: 'Signature'
  },
  gender: {
    663: 'Male',
    664: 'Female'
  },
  season: {
    S1: '1',
    S2: '2',
    S3: '3',
    S4: '4'
  },
  type: {
    673: 'Tops',
    674: 'Bottoms',
    508: 'Accessories'
  }
}
