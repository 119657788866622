/* eslint-disable brace-style */
import Vue from 'vue'
import { v4 } from 'uuid'

const clickStream = store => {
  return new Vue({
    data () {
      return {
        objectMap: {},
        userId: '',
        config: [],
        objectType: '',
        sequenceId: 0,
        timestamp: '',
        subType: '',
        apiParams: '',
        apiPayload: ''
      }
    },
    methods: {
      async clickPost () {
        const url = `/clickstreamapi`
        try {
          this.config = [
            {
              sessionId: localStorage.getItem('sessionIdBom'),
              userId: this.userId,
              sequenceId: Number(localStorage.getItem('sequenceIdBom')),
              timestamp: this.timestamp,
              objectType: this.objectType,
              objectMap: this.objectMap
            }
          ]
          await store.api.post({ url: url,
            body: this.config
          })
        } catch (ex) {
          if (ex.message !== '400') {
            throw ex
          }
        }
      },
      generateSessionId () {
        localStorage.setItem('sessionIdBom', v4())
        localStorage.setItem('sequenceIdBom', 1)
      },
      async initialisation (lscoUserName, lscoFullName, lscoEmailAddress) {
        this.userId = lscoUserName
        this.objectType = 'User'
        this.timestamp = new Date().toISOString().toString()
        this.objectMap = {
          name: lscoFullName,
          email: lscoEmailAddress
        }
        if (localStorage.getItem('sequenceIdBom') === '1') {
          await this.clickPost()
          this.obDevice()
          this.obApplication()
        }
      },
      async obDevice () {
        this.objectType = 'Device'
        localStorage.setItem(
          'sequenceIdBom',
          Number(localStorage.getItem('sequenceIdBom')) + 1
        )
        let OSName = ''
        let Version = ''
        let nAgt = navigator.userAgent
        let browser = navigator.appName
        let version = '' + parseFloat(navigator.appVersion)
        let majorVersion = parseInt(navigator.appVersion, 10)
        let nameOffset, verOffset, ix

        if ((verOffset = nAgt.indexOf('Opera')) !== -1) {
          browser = 'Opera'
          version = nAgt.substring(verOffset + 6)
          if ((verOffset = nAgt.indexOf('Version')) !== -1) {
            version = nAgt.substring(verOffset + 8)
          }
        }
        // Opera Next
        if ((verOffset = nAgt.indexOf('OPR')) !== -1) {
          browser = 'Opera'
          version = nAgt.substring(verOffset + 4)
        }
        // Legacy Edge
        else if ((verOffset = nAgt.indexOf('Edge')) !== -1) {
          browser = 'Microsoft Legacy Edge'
          version = nAgt.substring(verOffset + 5)
        }
        // Edge (Chromium)
        else if ((verOffset = nAgt.indexOf('Edg')) !== -1) {
          browser = 'Microsoft Edge'
          version = nAgt.substring(verOffset + 4)
        }
        // MSIE
        else if ((verOffset = nAgt.indexOf('MSIE')) !== -1) {
          browser = 'Microsoft Internet Explorer'
          version = nAgt.substring(verOffset + 5)
        }
        // Chrome
        else if ((verOffset = nAgt.indexOf('Chrome')) !== -1) {
          browser = 'Chrome'
          version = nAgt.substring(verOffset + 7)
        }
        // Safari
        else if ((verOffset = nAgt.indexOf('Safari')) !== -1) {
          browser = 'Safari'
          version = nAgt.substring(verOffset + 7)
          if ((verOffset = nAgt.indexOf('Version')) !== -1) {
            version = nAgt.substring(verOffset + 8)
          }
        }
        // Firefox
        else if ((verOffset = nAgt.indexOf('Firefox')) !== -1) {
          browser = 'Firefox'
          version = nAgt.substring(verOffset + 8)
        }
        // MSIE 11+
        else if (nAgt.indexOf('Trident/') !== -1) {
          browser = 'Microsoft Internet Explorer'
          version = nAgt.substring(nAgt.indexOf('rv:') + 3)
        }
        // Other browsers
        else if (
          (nameOffset = nAgt.lastIndexOf(' ') + 1) <
          (verOffset = nAgt.lastIndexOf('/'))
        ) {
          browser = nAgt.substring(nameOffset, verOffset)
          version = nAgt.substring(verOffset + 1)
          if (browser.toLowerCase() === browser.toUpperCase()) {
            browser = navigator.appName
          }
        }
        // trim the version string
        if ((ix = version.indexOf(';')) !== -1) { version = version.substring(0, ix) }
        if ((ix = version.indexOf(' ')) !== -1) { version = version.substring(0, ix) }
        if ((ix = version.indexOf(')')) !== -1) { version = version.substring(0, ix) }

        majorVersion = parseInt('' + version, 10)
        if (isNaN(majorVersion)) {
          version = '' + parseFloat(navigator.appVersion)
          majorVersion = parseInt(navigator.appVersion, 10)
        }

        if (navigator.userAgent.indexOf('Windows NT 10.0') !== -1) {
          OSName = 'Windows'
          Version = '10.0'
        }
        if (navigator.userAgent.indexOf('Windows NT 6.3') !== -1) {
          OSName = 'Windows 8.1'
          Version = '6.3'
        }
        if (navigator.userAgent.indexOf('Windows NT 6.2') !== -1) {
          OSName = 'Windows 8'
          Version = '6.2'
        }
        if (navigator.userAgent.indexOf('Windows NT 6.1') !== -1) {
          OSName = 'Windows 7'
          Version = '6.1'
        }
        if (navigator.userAgent.indexOf('Windows NT 6.0') !== -1) {
          OSName = 'Windows Vista'
          Version = '6.0'
        }
        if (navigator.userAgent.indexOf('Windows NT 5.1') !== -1) {
          OSName = 'Windows XP'
          Version = '5.1'
        }
        if (navigator.userAgent.indexOf('Windows NT 5.0') !== -1) {
          OSName = 'Windows 2000'
          Version = '5.0'
        }
        if (navigator.userAgent.indexOf('Mac') !== -1) {
          OSName = 'Mac/iOS'
        }
        if (navigator.userAgent.indexOf('X11') !== -1) {
          OSName = 'UNIX'
        }
        if (navigator.userAgent.indexOf('Linux') !== -1) {
          OSName = 'Linux'
        }

        this.objectMap = {
          systemName: OSName,
          systemVersion: Version,
          screenWidthPixels: screen.width,
          screenHeightPixels: screen.height,
          browserName: browser,
          browserVersion: version
        }

        await this.clickPost()
      },
      async obApplication () {
        this.objectType = 'Application'
        localStorage.setItem(
          'sequenceIdBom',
          Number(localStorage.getItem('sequenceIdBom')) + 1
        )
        this.objectMap = {
          name: 'BomApp',
          fullVersion: 'WEB'
        }
        this.clickPost()
      },
      obEvent (
        type,
        subType,
        apiPath = null,
        apiMethod = null,
        apiResponseHttpCode = null,
        apiPayload = null,
        apiParams = null
      ) {
        if (
          this.subType !== subType
          // ||
          // this.apiParams !== apiParams ||
          // this.apiPayload !== apiPayload
        ) {
          if (Number(localStorage.getItem('sequenceIdBom')) > 1) {
            this.objectType = 'Event'
            localStorage.setItem(
              'sequenceIdBom',
              Number(localStorage.getItem('sequenceIdBom')) + 1
            )
            this.timestamp = new Date().toISOString().toString()
            this.objectMap = {
              type: type,
              subType: subType,
              apiHost:
                   window.location.href.split('/')[2] === ''
                     ? 'bomapp.io'
                     : window.location.href.split('/')[2],
              apiPort: 443,
              apiPath: apiPath,
              apiMethod: apiMethod,
              apiResponseHttpCode: apiResponseHttpCode
            }
            this.subType = subType
            this.apiParams = apiParams
            this.apiPayload = apiPayload
            this.clickPost()
          }
        }
      }
    }
  })
}
export default clickStream
