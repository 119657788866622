import { join } from '../util/url.js'
import Vue from 'vue'

const baseUrl = '/api/lookup/materialSupplierColor'

const findMaterial = (store) => {
  return new Vue({
    data: {
      colorFilter: '',
      isOpen: false,
      perPage: 5,
      material: '',
      mode: 'add',
      pane: 'materialCode',
      p_supplier: '',
      currentPage: 1,
      sectionName: '',
      types: 'FinishRecipe'
    },
    computed: {
      skip () {
        return this.currentPage * this.perPage - this.perPage
      },
      colorFilterLowerCase () {
        return this.colorFilter.toLowerCase()
      },
      colorLookupUrl () {
        const params = join(this.material, this.supplier)
        return join(baseUrl, params)
      },
      isCurrentMaterialNameReady () {
        return this.$asyncComputed.currentMaterialName.state !== 'updating'
      },
      currentSupplierName () {
        if (this.supplier) {
          if (this.supplierOptions && this.supplierOptions.length) {
            const found = this.supplierOptions.find((option) => {
              return (option.supplierCode === this.supplier)
            })
            if (found && found.supplierName) {
              return found.supplierName
            }
          }
          return this.supplier
        }
        return ''
      },
      start () {
        return this.skip + 1
      },
      end () {
        const pageEnd = this.skip + this.perPage
        const listEnd = this.total
        return Math.min(pageEnd, listEnd)
      },
      isColorOptionsReady () {
        return this.$asyncComputed.colorOptions.success
      },
      isSupplierOptionsReady () {
        return this.$asyncComputed.supplierOptions.success
      },
      supplier: {
        get () {
          if (this.p_supplier.length === 0 && this.supplierOptions.length > 0) {
            return this.supplierOptions[0].supplierCode
          }
          return this.p_supplier || ''
        },
        set (value) {
          this.p_supplier = value
        }
      },
      supplierLookupUrl () {
        return join(baseUrl, this.material)
      },
      total () {
        return this.colorOptions.totalResults
      }
    },
    asyncComputed: {
      colorOptions: {
        lazy: true,
        default: {
          results: [],
          totalResults: 0
        },
        async get () {
          if (this.material.length > 2 && this.supplier.length) {
            const url = this.colorLookupUrl
            const params = {
              perPage: this.perPage,
              skip: this.skip
            }
            if (this.colorFilter && this.colorFilter.length) {
              params.term = this.colorFilter
            }
            if (this.sectionName === 'finish') {
              params.types = this.types
            }
            return store.api.get({
              url,
              params
            })
          }
          return {
            results: [],
            totalResults: 0
          }
        }
      },
      currentMaterialName: {
        lazy: true,
        default: '',
        async get () {
          if (this.material.length > 2 && this.pane === 'colorSupplier') {
            const url = '/api/search'
            const params = {
              materialCode: this.material
            }
            const response = await store.api.get({
              url,
              params
            })
            if (response.results && response.results.length) {
              const foundMaterial = response.results.find((material) => {
                return material.lscoMaterialCode === this.material
              })
              if (foundMaterial) {
                return foundMaterial.materialName
              }
            }
          }
          return this.material
        }
      },
      results: {
        lazy: true,
        default: [],
        async get () {
          return this.colorOptions.results
        }
      },
      supplierOptions: {
        lazy: true,
        default: [],
        async get () {
          if (this.material.length > 2 && this.pane === 'colorSupplier') {
            const url = this.supplierLookupUrl
            const params = { perPage: -1 }
            if (this.sectionName === 'finish') {
              params.types = this.types
            }
            const response = await store.api.get({
              url,
              params
            })
            return response.results
          }
          return []
        }
      }
    },
    methods: {
      close () {
        this.isOpen = false
        this.reset()
      },
      open () {
        this.isOpen = true
      },
      nextPane () {
        if (this.pane === 'materialCode') {
          this.pane = 'colorSupplier'
        } else if (this.pane === 'colorSupplier') {
          this.findMaterial()
          this.pane = 'results'
        }
      },
      previousPane () {
        if (this.pane === 'colorSupplier') {
          this.pane = 'materialCode'
        } else if (this.pane === 'results') {
          this.pane = 'colorSupplier'
        }
      },
      reset () {
        this.material = ''
        this.supplier = ''
        this.colorFilter = ''
        this.pane = 'materialCode'
        store.chooseMaterialCode.reset()
      }
    }
  })
}

export default findMaterial
