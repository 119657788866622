import localeCompare from '../../util/localeCompare.js'

const all = [{
  value: 1,
  label: 'Acetate'
}, {
  value: 2,
  label: 'Acrylic'
}, {
  value: 3,
  label: 'Cotton'
}, {
  value: 4,
  label: 'Elastane'
}, {
  value: 6,
  label: 'Linen'
}, {
  value: 9,
  label: 'Modacrylic'
}, {
  value: 11,
  label: 'Polyester'
}, {
  value: 12,
  label: 'Polyurethane'
}, {
  value: 13,
  label: 'Polyamide'
}, {
  value: 14,
  label: 'Ramie'
}, {
  value: 16,
  label: 'Silk'
}, {
  value: 18,
  label: 'Polyethylene'
}, {
  value: 19,
  label: 'Tussah Silk'
}, {
  value: 20,
  label: 'Viscose'
}, {
  value: 21,
  label: 'Wool'
}, {
  value: 22,
  label: 'Other Fibers'
}, {
  value: 23,
  label: 'Mohair'
}, {
  value: 29,
  label: 'Hemp'
}, {
  value: 32,
  label: 'Lyocell'
}, {
  value: 34,
  label: 'Alpaca'
}, {
  value: 35,
  label: 'Angora Rabbit Hair'
}, {
  value: 36,
  label: 'Cashmere'
}, {
  value: 47,
  label: 'Metallic Fiber'
}, {
  value: 48,
  label: 'Modal'
}, {
  value: 59,
  label: 'Elastolefin'
}, {
  value: 60,
  label: 'Lastol'
}, {
  value: 61,
  label: 'Elastomultiester'
}, {
  value: 81,
  label: 'Cupro'
}, {
  value: 82,
  label: 'Flax'
}, {
  value: 83,
  label: 'Eri Silk'
}, {
  value: 84,
  label: 'Crailar Flax'
}, {
  value: 85,
  label: 'Mulberry Silk'
}, {
  value: 86,
  label: 'Polypropylene'
}, {
  value: 87,
  label: 'Rabbit Hair'
}, {
  value: 88,
  label: 'Sorona'
}, {
  value: 89,
  label: 'Paper'
}, {
  value: 90,
  label: 'Repreve Recycled Polyester'
}, {
  value: 91,
  label: 'Ecovero'
}, {
  value: 101,
  label: 'Cordura Nylon'
}, {
  value: 102,
  label: 'Elasterell-P'
}, {
  value: 103,
  label: 'Lurex'
}, {
  value: 104,
  label: 'Lycra'
}, {
  value: 105,
  label: 'Merino Wool'
}, {
  value: 106,
  label: 'Micro Modal'
}, {
  value: 107,
  label: 'Micro Modal Air'
}, {
  value: 108,
  label: 'Nylon'
}, {
  value: 109,
  label: 'Organic cotton'
}, {
  value: 110,
  label: 'Pima Cotton'
}, {
  value: 111,
  label: 'Rayon'
}, {
  value: 112,
  label: 'Recycled Cotton'
}, {
  value: 113,
  label: 'Recycled Polyester'
}, {
  value: 114,
  label: 'Recycled Wool'
}, {
  value: 115,
  label: 'Spandex'
}, {
  value: 116,
  label: 'Supima Cotton'
}, {
  value: 117,
  label: 'Tencel'
}, {
  value: 118,
  label: 'Vectran'
}, {
  value: 119,
  label: 'Virgin Wool'
}, {
  value: 120,
  label: 'Dyneema'
}, {
  value: 121,
  label: 'T162R'
}, {
  value: 122,
  label: 'T400'
}, {
  value: 123,
  label: 'Lambs Wool'
}, {
  value: 124,
  label: 'Coolmax'
}, {
  value: 125,
  label: 'Coolmax Everyday'
}, {
  value: 126,
  label: 'Coolmax All Season'
}, {
  value: 127,
  label: 'Thermolite'
}, {
  value: 128,
  label: 'Refibra'
}]

export default all.sort((lhs, rhs) => {
  return localeCompare(lhs.label, rhs.label)
})
