<template>
  <div class="DashboardSearch">
    <div class="formWrapper">
      <form
        class="quickSearch"
        @submit="onSubmit"
      >
        <div class="col season">
          <label>Season</label>
          <DashboardSearchSeasonDropdown />
        </div>
        <div class="col pc9s">
          <label>PC9s</label>
          <input
            type="text"
            placeholder="Type or paste your PC9 codes"
            :value="store.quickBomSearch.query"
            @input="onInput"
          >
        </div>
        <div class="col submitButton">
          <button
            type="submit"
            class="button"
          >
            Quick Search
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import DashboardSearchSeasonDropdown from './DashboardSearchSeasonDropdown.vue'
import time from '../../util/time.js'

export default {
  components: {
    DashboardSearchSeasonDropdown
  },
  inject: ['store'],
  computed: {
    fullGreeting () {
      const greeting = time.greeting()
      return `${greeting}!`
    }
  },
  methods: {
    onInput (event) {
      this.store.quickBomSearch.query = event.target.value
    },
    onSubmit (event) {
      event.preventDefault()
      this.store.quickBomSearch.goToSearch()
      this.store.quickBomSearch.query = ''
      this.store.clickStream.obEvent('Search', 'Quick Search')
    }
  }
}
</script>

<style lang="scss">
.DashboardSearch {
  height: 38.2vh;
  min-height: 160px;
  max-height: 320px;
  background-color: $gray;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  &::before {
    content: "";
    background-image: url(/images/bg-eureka-bottoms.jpg);
    opacity: 0.3;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  .formWrapper {
    @include centerColumn;

    padding-bottom: 3rem;

    // Need to stack this above the hero image
    z-index: 0;

    h2.greeting {
      font-size: 4rem;
      margin: 3.5rem 0;
      color: $white;
    }

    form.quickSearch {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;

      .col {
        &.season {
          flex-basis: 30%;
          margin-right: 2rem;
        }

        &.pc9s {
          flex-basis: 55%;
          margin-right: 2rem;
        }

        &.submitButton {
          flex-basis: 15%;
        }
      }

      label {
        color: $white;
      }

      input[type=text],
      select,
      button {
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
        border: none;
      }

      button {
        width: 100%;
      }
    }
  }
}
</style>
