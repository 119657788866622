import Vue from 'vue'
import whitespaceToCommas from '../../util/whitespaceToCommas.js'

export default (store) => {
  return new Vue({
    data: {
      showErrors: false,
      limitToPC5: false,
      PC5CodeText: '',
      prodPricePos: [],
      prodType: []
    },
    computed: {
      isPC5Valid () {
        if (this.limitToPC5) {
          return this.PC5CodeText.trim().length
        }
        return true
      },
      PC5Code: {
        get () {
          if (this.limitToPC5) {
            return whitespaceToCommas(this.PC5CodeText)
          }
          return ''
        },
        set (value) {
          this.PC5CodeText = value
          if (value && value.length) {
            this.limitToPC5 = true
          } else {
            this.limitToPC5 = false
          }
        }
      }
    }
  })
}
