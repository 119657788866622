import Vue from 'vue'

const copyFromPC9 = (store) => {
  return new Vue({
    data: {
      foundBom: false,
      showSearchError: false,
      PC9Code: ''
    },
    methods: {
      async find (bomId) {
        const url = `/api/bom/${bomId}`
        const response = await store.api.get({ url })
        this.foundBom = response.BOMSearchMetadata
      },
      hasBom (bomId) {
        return (
          this.foundBom &&
          this.foundBom.BOMResults[0].bompartId === bomId
        )
      },
      async search () {
        this.showSearchError = false
        const url = '/api/bom'
        const params = {
          PC9Code: this.PC9Code,
          seasonId: store.bomSearch.seasonId,
          allowViewAll: true
        }
        const response = await store.api.get({
          url,
          params
        })
        if (response.BOMSearchMetadata.BOMResults.length === 1) {
          const bomId = response.BOMSearchMetadata.BOMResults[0].bompartId
          store.modals.updateParams({
            preview: bomId
          })
          this.foundBom = response.BOMSearchMetadata
        } else {
          this.showSearchError = true
        }
      }
    }
  })
}

export default copyFromPC9
