import Vue from 'vue'

var count = 0

const alerts = (store) => {
  return new Vue({
    data: {
      errors: [],
      warnings: []
    },
    computed: {
      isShowing () {
        if (store.config.showErrorAlerts) {
          return this.errors.length || this.warnings.length
        }
        return false
      }
    },
    methods: {
      addError (error) {
        const exists = this.errors.find(({ message }) => {
          return error === message
        })
        if (!exists) {
          count++
          this.errors.push({
            message: error,
            id: count
          })
          return count
        }
      },
      addWarning (warning) {
        const exists = this.warnings.find(({ message }) => {
          return warning === message
        })
        if (!exists) {
          count++
          this.warnings.push({
            message: warning,
            id: count
          })
          return count
        }
      },
      removeError (id) {
        this.errors = this.errors.filter((error) => {
          return error.id !== id
        })
      },
      removeWarning (id) {
        this.warnings = this.warnings.filter((warning) => {
          return warning.id !== id
        })
      }
    }
  })
}

export default alerts
