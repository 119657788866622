import Vue from 'vue'

const favoriteSeasons = (store) => {
  return new Vue({
    data: {
      isSaving: false
    },
    computed: {
      isEmpty () {
        return (
          this.isReady &&
          this.items.length === 0
        )
      },
      isReady () {
        return (
          this.$asyncComputed.items.success &&
          this.isSaving === false
        )
      },
      nextSortingNumber () {
        const highest = this.items.reduce((acc, item) => {
          const current = item.sortingNumber || -1
          return Math.max(acc, current)
        }, -1)
        return highest + 1
      }
    },
    asyncComputed: {
      items: {
        default: [],
        lazy: true,
        async get () {
          const response = await store.api.get({
            url: '/api/me/seasons'
          })
          return response || []
        }
      }
    },
    methods: {
      fetch () {
        return this.$asyncComputed.items.update()
      },
      find (seasonId) {
        return this.items.find((season) => {
          return season.seasonId === seasonId
        })
      },
      getSortOrder (sortable) {
        return this.items.map((season) => {
          return season.seasonId
        })
      },
      includes (seasonId) {
        return this.items.find((season) => {
          return season.seasonId === seasonId
        })
      },
      async remove (seasonId) {
        this.isSaving = true
        store.addFavoriteSeason.lastSeasonId = false
        const url = `/api/me/seasons/${seasonId}`
        await store.api.delete({
          url
        })
        this.isSaving = false
        return this.fetch()
      },
      setSortOrder (sortable) {
        const ids = sortable.toArray()
        const oldOrder = this.getSortOrder()
        const changed = ids.reduce((acc, id, index) => {
          if (id !== oldOrder[index]) {
            return true
          }
          return acc
        }, false)

        if (changed) {
          const newOrder = ids.map((seasonId, index) => {
            return {
              seasonId,
              sortingNumber: index
            }
          })
          this.saveSortOrder(newOrder)
        }
      },
      async saveSortOrder (newOrder) {
        const url = `/api/me/seasons`
        this.isSaving = true
        store.addFavoriteSeason.lastSeasonId = false
        await store.api.put({
          url,
          body: newOrder
        })
        await this.fetch()
        this.isSaving = false
      }
    }
  })
}

export default favoriteSeasons
