import Vue from 'vue'

export default (store) => {
  return new Vue({
    data: {
      isLoading: false,
      params: {
        available: 'YES',
        brands: [],
        gender: '',
        productCategory: '',
        season: '',
        colorwayCode: null,
        productCode: null,
        powerCoreIndicator: 'Both'
      },
      showDownloadError: false,
      showValidationError: false,
      showErrorMessage: ''
    },
    computed: {
      availableLabel () {
        if (this.params.available === 'YES') {
          return 'Available'
        }
        return 'Unavailable'
      },
      brandsLabel () {
        const labels = this.params.brands.map((brand) => {
          return store.codes.seasons.brand[brand]
        })
        return labels.join('-')
      },
      fileName () {
        return `${this.params.season} - ${this.brandsLabel} - ${this.availableLabel} - ${this.productCategoryLabel} - ${this.genderLabel}.csv`
      },
      genderLabel () {
        return store.codes.seasons.gender[this.params.gender]
      },
      isValid () {
        return (
          this.params.available.length > 0 &&
          this.params.brands.length > 0 &&
          this.params.gender.length > 0 &&
          this.params.productCategory.length > 0 &&
          this.params.season.length > 0
        )
      },
      productCategoryLabel () {
        return store.codes.seasons.type[this.params.productCategory]
      }
    },
    methods: {
      async download () {
        if (this.isLoading) {
          return false
        }

        this.showDownloadError = false
        this.showValidationError = false

        if (!this.isValid) {
          this.showValidationError = true
          return
        }

        try {
          this.isLoading = true
          const response = await this.submit()
          if (response.status === 200) {
            const blob = await response.blob()
            var link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = this.fileName
            link.click()
            this.isLoading = false
          } else {
            this.showErrorMessage = await response.text()
            throw new Error(`Report download request failed: ${this.showErrorMessage}`)
          }
          store.clickStream.obEvent(
            'Bom Report',
            'Download Bom Report',
            response.url,
            'POST',
            response.status,
            this.params
          )
        } catch (error) {
          this.isLoading = false
          this.showDownloadError = true
          if (error.message.includes('Endpoint request timed out')) {
            this.showErrorMessage = 'Error occurred generating report. Request timed out. Kindly restrict results using the filter criteria'
          }
          store.clickStream.obEvent(
            'Error',
            error.message
          )
        }
      },
      async submit () {
        const url = '/api/bom/report'
        if (this.params.powerCoreIndicator === 'Both') {
          delete this.params.powerCoreIndicator
        }
        const body = { ...this.params }
        const headers = {
          Authorization: `Bearer ${store.auth.idToken}`,
          Accept: 'text/csv',
          'Content-Type': 'application/json'
        }

        return fetch(url, {
          method: 'POST',
          headers,
          body: JSON.stringify(body)
        })
      }
    }
  })
}
