<template>
  <ContextMenu
    class="FavoriteSeasonContextMenu"
    :x-offset="-140"
    :arrow-offset="153"
  >
    <template v-slot:default>
      <ul>
        <li>
          <ModalLink
            name="remove-favorite-season"
            :params="{ seasonId: season.seasonId }"
          >
            Remove Favorite
          </ModalLink>
        </li>
      </ul>
    </template>
  </ContextMenu>
</template>

<script>
import ContextMenu from '../ContextMenu.vue'
import ModalLink from '../ModalLink.vue'

export default {
  components: {
    ContextMenu,
    ModalLink
  },
  inject: ['store'],
  props: {
    season: {
      type: Object,
      required: true
    }
  }
}
</script>
