<template>
  <select
    v-model="value"
  >
    <option
      v-for="item in options"
      :key="item.seasonId"
      :value="item.seasonId"
    >
      {{ item.seasonName }}
    </option>
  </select>
</template>

<script>
export default {
  inject: ['store'],
  computed: {
    options () {
      let seasons = this.store.quickBomSearch.seasonOptions
      return seasons.filter(season => season.seasonId !== 'ALL')
    },
    value: {
      get () {
        return this.store.quickBomSearch.seasonId
      },
      set (value) {
        this.store.quickBomSearch.seasonId = value
      }
    }
  },
  mounted () {
    if (this.value === 'ALL') {
      this.value = this.store.seasons.items[1].seasonId
    }
  }
}
</script>
