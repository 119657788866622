<template>
  <ContextMenu
    :x-offset="-24"
    :y-offset="10"
    :arrow-offset="24"
    :use-hover="true"
  >
    <template v-slot:button>
      <BangCircleIcon />
    </template>

    <template v-slot:default>
      <div class="assignSectionNoMaterialsWarning">
        Development BOM section contains Component / Component Location,
        but no materials.
      </div>
    </template>
  </ContextMenu>
</template>

<script>
import BangCircleIcon from '../icons/BangCircleIcon.vue'
import ContextMenu from '../ContextMenu.vue'

export default {
  components: {
    BangCircleIcon,
    ContextMenu
  }
}
</script>

<style lang="scss">
.context-menu-portal-target {
  .assignSectionNoMaterialsWarning {
    padding: 2rem;
  }
}
</style>
