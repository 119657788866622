import './scss/app.scss'
import Vue from 'vue'
import { LicenseManager } from 'ag-grid-enterprise'
import App from './App.vue'
import hotkeys from './hotkeys.js'
import PortalVue from 'portal-vue'
import Store from './state/Store.js'
import VueAsyncComputed from 'vue-async-computed'
import VueDragDrop from 'vue-drag-drop'
import VueObserveVisibility from 'vue-observe-visibility'
import { init as initFullStory, FullStory } from '@fullstory/browser'

LicenseManager.setLicenseKey('0ebb4c943a349f5f3f2f9a2f8e18dc71')

initFullStory({ orgId: process.env.VUE_APP_FULLSTORY_ORGID })
Vue.config.productionTip = false
Vue.use(FullStory)
Vue.use(hotkeys())
Vue.use(PortalVue)
Vue.use(VueAsyncComputed)
Vue.use(VueDragDrop)
Vue.use(VueObserveVisibility)

const store = new Store()
store.init()

new Vue({
  router: store.router.instance,
  provide: {
    store
  },
  render: h => h(App)
}).$mount('#app')
