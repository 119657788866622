import Vue from 'vue'
import axios from 'axios'
import { FullStory } from '@fullstory/browser'
const unknownUser = {
  lscoUserName: '(Unknown User)'
}

const user = (store) => {
  return new Vue({
    computed: {
      isReady () {
        return (
          this.current &&
          this.current.lscoUserName &&
          this.current.lscoUserName.length
        )
      },
      role () {
        return this.current.role || store.codes.roles.view
      }
    },
    asyncComputed: {
      current: {
        default: {},
        lazy: true,
        async get () {
          try {
            const user = await store.api.get({
              url: '/api/me'
            })

            if (user.status === 404 || user.status === 401) {
              return { ...unknownUser }
            }
            store.clickStream.initialisation(
              user.lscoUserName,
              user.lscoFullName,
              user.lscoEmailAddress
            )
            FullStory('trackEvent', {
              name: 'BOMUserRole',
              properties: {
                BOMRole: user.role
              }
            })
            FullStory('trackEvent', {
              name: 'BOMPDC',
              properties: {
                Pdc: user.pdc
              }
            })
            return user
          } catch (error) {
            return { ...unknownUser }
          }
        }
      }
    },
    methods: {
      isRole (role) {
        return this.role === role
      },
      async uploadPackage (file) {
        try {
          const url = '/api/bom/uploadfile'
          let response = await axios.post(url, file, {
            headers: {
              'Authorization': `Bearer ${store.auth.idToken}`,
              'Content-Type': 'multipart/form-data'
            }
          })
          return response
        } catch (er) {
          return null
        }
      }
    }
  })
}

export default user
