import available from './codesData/available.js'
import brandedContent from './codesData/brandedContent.js'
import carryoverOrNew from './codesData/carryoverOrNew.js'
import components, { lookups as componentLookups } from './codesData/components.js'
import earlyDelivery from './codesData/earlyDelivery.js'
import fabricSubtype from './codesData/fabricSubtype.js'

import componentLocations, { lookups as locationLookups } from './codesData/componentLocations.js'
import fabricType from './codesData/fabricType.js'
import genericContent from './codesData/genericContent.js'
import GTMTrack from './codesData/GTMTrack.js'
import prodPricePos from './codesData/prodPricePos.js'
import prodType from './codesData/prodType.js'
import roles from './codesData/roles.js'
import seasons from './codesData/seasons.js'
import sections from './codesData/sections.js'
import sundrySubtype from './codesData/sundrySubtype.js'
import sundryType from './codesData/sundryType.js'
import lscoMrpLock from './codesData/MRPLock.js'
import powerCore from './codesData/powerCore.js'
import Vue from 'vue'

const codes = store => {
  return new Vue({
    data: {
      available,
      brandedContent,
      carryoverOrNew,
      components,
      componentLocations,
      earlyDelivery,
      fabricSubtype,
      fabricType,
      genericContent,
      GTMTrack,
      prodPricePos,
      prodType,
      roles,
      seasons,
      sections,
      sundrySubtype,
      sundryType,
      lscoMrpLock,
      powerCore
    },
    computed: {
      prodPricePosMappings () {
        // Each of the labels maps to the modern code, and one
        // or more legacy codes.
        return {
          // Tier 1, Pinnacle
          '01': '01,10',
          // Tier 2, Best, Better
          '02': '02,09,08',
          // Tier 3, Good
          '03': '03,07',
          // Tier 4, Entry
          '04': '04,05'
        }
      },
      sectionKeys () {
        return Object.keys(this.sections)
      },
      sectionOptions () {
        return this.sectionKeys.map(key => {
          return {
            value: key,
            label: this.sections[key]
          }
        })
      },
      rbacSectionOptions () {
        const { rbac } = store
        return this.sectionOptions.filter(
          section => rbac.hasPermission('edit', 'lineItem', { sectionName: section.value })
        )
      }
    },
    methods: {
      getComponentLabel (component = '') {
        const found = componentLookups[component]
        return found || component || ''
      },
      getLocationLabel (location = '') {
        const found = locationLookups[location]
        return found || location || ''
      },
      mapProdPricePos (commaStringValue = '') {
        const individualCodes = commaStringValue.split(',')
        const mappedCodes = individualCodes.map(codeValue => {
          return this.prodPricePosMappings[codeValue]
        })
        return mappedCodes.join(',')
      }
    }
  })
}

export default codes
