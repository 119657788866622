import localeCompare from '../../util/localeCompare.js'

const all = [{
  value: 'AAG',
  label: 'ACCESSORY ACCOMPANYING GARMENT'
}, {
  value: 'ATTA',
  label: 'Attachment'
}, {
  value: 'BADG',
  label: 'Badge'
}, {
  value: 'BANR',
  label: 'Ban Roll'
}, {
  value: 'BELT',
  label: 'Belt'
}, {
  value: 'BIND',
  label: 'Binding'
}, {
  value: 'BKLE',
  label: 'Buckle'
}, {
  value: 'BKLT',
  label: 'Booklet'
}, {
  value: 'BRAD',
  label: 'Braiding'
}, {
  value: 'BTBG',
  label: 'Button Bag'
}, {
  value: 'BTTN',
  label: 'Button'
}, {
  value: 'BURR',
  label: 'Burr'
}, {
  value: 'CDST',
  label: 'Cordstopper'
}, {
  value: 'CHAN',
  label: 'Chain'
}, {
  value: 'CHM',
  label: 'Chemical'
}, {
  value: 'CLIP',
  label: 'Clip'
}, {
  value: 'CLSP',
  label: 'Clasp'
}, {
  value: 'CLTP',
  label: 'Collar Tip'
}, {
  value: 'COLL',
  label: 'Collar'
}, {
  value: 'DCRD',
  label: 'Draw Cord'
}, {
  value: 'DEMB',
  label: 'Embroidery'
}, {
  value: 'DRNG',
  label: 'D-Ring'
}, {
  value: 'ELAS',
  label: 'Elastic'
}, {
  value: 'ELEC',
  label: 'Electronic'
}, {
  value: 'EYET',
  label: 'Eyelet'
}, {
  value: 'FAST',
  label: 'Fastener'
}, {
  value: 'GROM',
  label: 'Grommet'
}, {
  value: 'GSTR',
  label: 'Strap Band'
}, {
  value: 'HASP',
  label: 'Hasp'
}, {
  value: 'HGTG',
  label: 'Hangtag'
}, {
  value: 'HKEY',
  label: 'Hook & Eye'
}, {
  value: 'HKLO',
  label: 'Hook & Loop'
}, {
  value: 'HTSF',
  label: 'Heat Transfer'
}, {
  value: 'LABL',
  label: 'Label'
}, {
  value: 'LACE',
  label: 'Lace'
}, {
  value: 'METR',
  label: 'Metal Trim'
}, {
  value: 'MISC',
  label: 'Misc'
}, {
  value: 'MTB',
  label: 'Matchbook'
}, {
  value: 'OTH',
  label: 'Other'
}, {
  value: 'PIPE',
  label: 'Piping'
}, {
  value: 'PKTF',
  label: 'Flasher'
}, {
  value: 'PLYB',
  label: 'Poly Bag'
}, {
  value: 'PRTF',
  label: 'Print Foil'
}, {
  value: 'PTCH',
  label: 'Patch'
}, {
  value: 'RIBN',
  label: 'Ribbon'
}, {
  value: 'RIVT',
  label: 'Rivet'
}, {
  value: 'ROCA',
  label: 'Rocap'
}, {
  value: 'SCKT',
  label: 'Socket'
}, {
  value: 'SCRP',
  label: 'Screen Print'
}, {
  value: 'SHNK',
  label: 'Shank'
}, {
  value: 'SLDR',
  label: 'Slider'
}, {
  value: 'SNAP',
  label: 'Snap'
}, {
  value: 'SQIN',
  label: 'Sequins'
}, {
  value: 'STKR',
  label: 'Sticker'
}, {
  value: 'STMP',
  label: 'Stamp'
}, {
  value: 'STUD',
  label: 'Stud'
}, {
  value: 'SUSP',
  label: 'Suspender'
}, {
  value: 'TABS',
  label: 'Tab'
}, {
  value: 'TAPE',
  label: 'Tape'
}, {
  value: 'TGLE',
  label: 'Toggle'
}, {
  value: 'THD',
  label: 'Thread'
}, {
  value: 'TIPG',
  label: 'Tipping'
}, {
  value: 'TKTS',
  label: 'Ticket'
}, {
  value: 'TSLE',
  label: 'Tassle'
}, {
  value: 'UVMS',
  label: 'UVM'
}, {
  value: 'ZIPR',
  label: 'Zipper'
}]

export default all.sort((lhs, rhs) => {
  return localeCompare(lhs.label, rhs.label)
})
