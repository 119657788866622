<template>
  <div>
    <UploadPackage ref="uploadPackage" />
    <ContextMenu
      :x-offset="-160"
      :y-offset="14"
      :arrow-offset="160"
    >
      <template v-slot:button>
        <GearIcon />
      </template>
      <ul class="SettingsMenu">
        <li @click.stop>
          <span class="menuItem">
            {{ store.user.current.lscoUserName }}
          </span>
        </li>
        <li
          v-if="showRole"
          @click.stop
        >
          <span
            class="menuItem"
          >
            {{ store.user.role }}
          </span>
        </li>
        <li v-if="merchants">
          <a @click="packageUpload">Packaging Upload</a>
        </li>
        <li>
          <a @click="onSignOutClick">Sign Out</a>
        </li>
      </ul>
    </ContextMenu>
  </div>
</template>

<script>
import ContextMenu from './ContextMenu.vue'
import GearIcon from './icons/GearIcon.vue'
import UploadPackage from './UploadPackage'

export default {
  components: {
    ContextMenu,
    GearIcon,
    UploadPackage
  },
  inject: ['store'],
  computed: {
    showRole () {
      return this.store.rbac.hasPermission('view', 'userRole')
    },
    merchants () {
      if (this.store.user.role === 'Merch') {
        return true
      } else {
        return false
      }
    }
  },
  methods: {
    async onSignOutClick () {
      await this.store.auth.signOut()
    },
    packageUpload () {
      this.$refs.uploadPackage.open()
    }
  }
}
</script>

<style lang="scss">
.SettingsMenu {
  .menuItem {
    cursor: default;
  }
}
</style>
