<template>
  <div class="Popups">
    <div
      v-for="message in messages"
      :key="message.id"
    >
      <div
        v-if="message.text || message.error || message.linkText"
        class="popupMessage"
      >
        <span
          v-if="message.text"
          class="messageContent"
        >
          {{ message.text }}
        </span>
        <span
          v-if="message.error"
          class="error"
        >
          {{ message.error }}
        </span>
        <span
          v-if="message.linkTarget && message.linkText"
          class="link"
        >
          <router-link
            :to="message.linkTarget"
            @click.native="onLinkClick(message.id)"
          >
            {{ message.linkText }}
          </router-link>
        </span>
        <span
          class="closeButton"
          @click="onCloseClick(message.id)"
        >
          <CloseIcon />
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import CloseIcon from './icons/CloseIcon.vue'
export default {
  components: {
    CloseIcon
  },
  inject: [ 'store' ],
  computed: {
    messages () {
      return this.store.popups.messages
    }
  },
  methods: {
    onCloseClick (id) {
      this.store.popups.remove(id)
    },
    onLinkClick (id) {
      this.store.popups.remove(id)
    }
  }
}
</script>

<style lang="scss">
.Popups {
  position: absolute;
  bottom: 64px;
  left: 16px;
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-start;
  overflow: hidden;
  max-width: calc(100% - 32px);

  .popupMessage {
    display: flex;
    text-align: center;
    font-size: 16px;
    color: white;
    background-color: $gray;
    padding: 16px 24px;
    border-radius: 5px;
    box-shadow: 3px 3px 3px $gray-card-border;
    margin-top: 16px;
    animation: 500ms SlideIn;

    .messageContent {
      display: flex;
      align-items: center;
      margin-right: 16px;
    }

    .error {
      display: flex;
      align-items: center;
      margin-right: 16px;
      color: lighten($red, 15%);
    }

    .status {
      display: flex;
      align-items: center;
      margin-right: 16px;
    }

    .link {
      display: flex;
      align-items: center;
      margin-right: 16px;
    }

    .closeButton {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      flex-shrink: 0;
      transition: background-color 500ms ease;
      cursor: pointer;

      &:hover {
        background-color: $gray-sec;
      }

      svg {
        path {
          stroke: white;
        }
      }
    }
  }
}
</style>
