<template>
  <SweetModal
    ref="modal"
    :has-title="true"
    :has-content="true"
    :hide-close-button="true"
    :blocking="true"
    :allow-outside-click="false"
    :pulse-on-block="false"
    :width="800"
  >
    <div>
      <div>
        <h2 class="headerPos">
          Upload file
        </h2>

        <div
          class="closeIconUpload"
          @click="cancel"
        >
          <CloseIcon />
        </div>
      </div>

      <Spinner v-if="showSpinner" />

      <div class="uploadFiles">
        <label class="custom-file-upload">
          <input
            id="fileUpload"
            type="file"
            @change="uploadFile"
          >
          Choose File
        </label>

        <p v-if="file">
          {{ file.name }}
        </p>
      </div>
      <button
        v-if="file"
        class="button buttonUpFile"
        @click="SubmitPackage"
      >
        Submit
      </button>
    </div>
  </SweetModal>
</template>

<script>

import { SweetModal } from 'sweet-modal-vue'
import CloseIcon from './icons/CloseIcon.vue'
import Spinner from './Spinner.vue'
// './icons/CloseIcon.vue'

export default {
  components: {
    SweetModal,
    CloseIcon,
    Spinner
  },
  inject: ['store'],
  data () {
    return {
      file: null,
      showSpinner: false
    }
  },
  computed: {

  },
  methods: {

    cancel () {
      try {
        document.getElementById('fileUpload').value = ''
      } catch (er) {
        //
      }
      this.$refs.modal.close()
    },
    open () {
      this.file = null
      this.$refs.modal.open()
    },
    async SubmitPackage () {
      let ext = this.file.name.split('.').pop()
      if (ext === 'csv') {
        this.showSpinner = true
        let formData = new FormData()
        formData.append('file', this.file)
        let res = await this.store.user.uploadPackage(formData)
        this.showSpinner = false
        if (res) {
          this.store.popups.messages.push({ 'text': 'Uploaded Successfully!' })
        } else {
          this.store.popups.messages.push({ 'error': 'Failed to upload the file' })
        }
      } else {
        this.store.popups.messages.push({ 'error': 'Please upload packaging csv loader file' })
      }
      this.cancel()
    },
    uploadFile (e) {
      this.file = e.target.files[0]
    }
  }

}

</script>
<style lang="scss">
.headerPos {
  top: 6%;
  left: 4%;
  position: absolute;
  float: left;
}

.closeIconUpload {
  top: 8%;
  right: 2%;
  position: absolute;
  float: right;
}

.uploadFiles {
  margin-top: 5%;

  input[type="file"] {
    display: none;
  }

  .custom-file-upload {
    border: 1px solid #ccc;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;

    &:hover {
      color: white;
      background-color: gray;
    }
  }
}

.buttonUpFile {
  float: right;
}
  </style>
