import chooseSeason from './assign/chooseSeason.js'
import chooseSections from './assign/chooseSections.js'
import enterPC9Codes from './assign/enterPC9Codes.js'
import lookup from './assign/lookup.js'
import reviewSelections from './assign/reviewSelections.js'
import searchResults from './assign/searchResults.js'

const assign = (store) => {
  return {
    chooseSeason: chooseSeason(store),
    chooseSections: chooseSections(store),
    enterPC9Codes: enterPC9Codes(store),
    lookup: lookup(store),
    reviewSelections: reviewSelections(store),
    searchResults: searchResults(store)
  }
}

export default assign
