<template>
  <div class="SeasonSelector">
    <Spinner
      v-if="store.seasonSelector.isLoading"
      class="stretch"
    />
    <ul v-else>
      <li>
        <h5>Brand</h5>
        <RadioGroup
          :options="store.seasonSelector.brandOptions"
          :model="model"
          name="brand"
        />
      </li>
      <li>
        <h5>Season</h5>
        <RadioGroup
          :options="store.seasonSelector.seasonOptions"
          :model="model"
          name="season"
        />
      </li>
      <li>
        <h5>Year</h5>
        <RadioGroup
          :options="store.seasonSelector.yearOptionsWith2020"
          :model="model"
          name="year"
        />
      </li>
      <li>
        <h5>Gender</h5>
        <RadioGroup
          :options="store.seasonSelector.genderOptions"
          :model="model"
          name="gender"
        />
      </li>
      <li>
        <h5>Type</h5>
        <RadioGroup
          :options="store.seasonSelector.typeOptions"
          :model="model"
          name="type"
        />
      </li>
    </ul>
  </div>
</template>

<script>
import RadioGroup from './RadioGroup.vue'
import Spinner from './Spinner.vue'

export default {
  components: {
    RadioGroup,
    Spinner
  },
  inject: [ 'store' ],
  props: {
    model: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss">
.SeasonSelector {
  min-height: 200px;
  min-width: 760px;

  ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    li {
      min-width: 152px;
    }
  }
}

</style>
