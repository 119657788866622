import Vue from 'vue'
import whitespaceToCommas from '../util/whitespaceToCommas.js'
import deepEqual from 'deep-equal'
import allClasses from '../state/classesData/allClasses'
import allSubclasses from '../state/classesData/allSubclasses'

const defaults = {
  carryoverOrNew: [],
  class: 'Any',
  color: '',
  component: '',
  compLocation: '',
  designUser: '',
  isAvailable: ['YES'],
  earlyDelivery: [],
  GTMTrack: [],
  material: '',
  merchUser: '',
  PC5Code: '',
  PC5Name: '',
  PC9Code: '',
  PC9Name: '',
  PDSUser: '',
  PMUser: '',
  prodPricePos: [],
  prodType: [],
  showAllSeasons: false,
  subclass: 'Any',
  isLoading: false,
  body: {},
  lscoMrpLock: [],
  powerCoreIndicator: 'Both'
}

const bomSearch = (store) => {
  return new Vue({
    data: {
      ...defaults
    },
    computed: {
      classOptions () {
        if (this.currentSeason.seasonId === 'ALL') {
          const options = allClasses
          if (options[0].value !== 'Any') {
            options.unshift({
              value: 'Any',
              label: 'Any'
            })
          }
          return options
        } else if (this.currentSeason) {
          const { lscoBrand, lscoGender, lscoCategory } = this.currentSeason
          const classes = store.classes[lscoBrand][lscoGender][lscoCategory]
          const options = Object.keys(classes).map((key) => {
            return store.classes.classLookups[key]
          })
          options.unshift({
            value: 'Any',
            label: 'Any'
          })
          return options
        }
        return []
      },
      currentSeason () {
        if (this.seasonId && this.seasonId.length) {
          return store.seasons.find(this.seasonId)
        }
        return false
      },
      classPH: {
        get () {
          if (this.classOptions.find((option) => {
            return option.value === this.class
          })) {
            return this.class
          }
          return 'Any'
        },
        set (value) {
          this.class = value
        }
      },
      subclassPH: {
        get () {
          if (this.subclassOptions.find((option) => {
            return option.value === this.subclass
          })) {
            return this.subclass
          }
          return 'Any'
        },
        set (value) {
          this.subclass = value
        }
      },
      seasonId: {
        get () {
          if (store.session.seasonId) {
            return store.session.seasonId
          }

          if (!store.favoriteSeasons.isEmpty) {
            return store.favoriteSeasons.items[0].seasonId
          }

          return store.seasons.items[0].seasonId
        },
        set (value) {
          store.session.seasonId = value
        }
      },
      seasonOptions () {
        if (
          store.favoriteSeasons.isEmpty ||
          this.showAllSeasons
        ) {
          return store.seasons.items
        }

        if (store.favoriteSeasons.includes(this.seasonId)) {
          return store.favoriteSeasons.items
        }

        const extraSeason = store.seasons.find(this.seasonId)

        return [extraSeason, ...store.favoriteSeasons.items]
      },
      subclassOptions () {
        if (this.currentSeason.seasonId === 'ALL' && this.classPH !== 'Any') {
          const options = allSubclasses
          if (options[0].value !== 'Any') {
            options.unshift({
              value: 'Any',
              label: 'Any'
            })
          }
          return options
        } else if (this.currentSeason && this.classPH !== 'Any') {
          const { lscoBrand, lscoGender, lscoCategory } = this.currentSeason
          const classes = store.classes[lscoBrand][lscoGender][lscoCategory]
          const subclasses = classes[this.classPH]
          const options = subclasses.map((key) => {
            return store.classes.subclassLookups[key]
          })
          options.unshift({
            value: 'Any',
            label: 'Any'
          })
          return options
        }
        return [{
          value: 'Any',
          label: 'Any'
        }]
      }
    },
    methods: {
      addMultiParam (query, name) {
        if (this[name] && this[name].length) {
          query[name] = this[name].join(',')
        }
      },
      addCommaStringParam (query, name) {
        const value = this[name]
        if (value && value.length) {
          query[name] = whitespaceToCommas(value)
        }
      },
      addParam (query, name) {
        if (this[name] && this[name].length && this[name] !== 'Any') {
          query[name] = this[name]
        }
      },
      buildBody (name) {
        let commaFields = ['color', 'designUser', 'material', 'merchUser', 'PC5Code', 'PC5Name', 'PC9Code', 'PC9Name', 'PDSUser']
        if (commaFields.includes(name)) {
          this.body[name] = whitespaceToCommas(this[name])
        } else if (this[name] && this[name].length && this[name] !== 'Any') {
          this.body[name] = this[name]
        }
      },
      getBody () {
        this.body = {}
        // eslint-disable-next-line standard/array-bracket-even-spacing
        let keyVals = ['carryoverOrNew', 'color', 'component', 'compLocation',
          'designUser', 'GTMTrack', 'earlyDelivery', 'isAvailable', 'classPH',
          'subclassPH', 'material', 'merchUser', 'PC5Code',
          'PC9Code', 'PDSUser', 'PMUser', 'prodPricePos',
          'prodType', 'seasonId', 'lscoMrpLock', this.powerCoreIndicator !== 'Both' ? 'powerCoreIndicator' : null]
        keyVals.map(key => {
          this.buildBody(key)
        })
        return this.body
      },
      getBodyForGrid () {
        let id = {
          bompartIds: []
        }
        store.bomGrid.selectedBoms.map(bom => {
          id.bompartIds.push(bom.bompartId)
        })
        return id
      },
      generateReport () {
        this.isLoading = true
        let url = '/api/bom/report/search'
        let body = JSON.stringify(this.getBody())
        let fileName = 'Bom_Report.csv'
        let subType = 'Download Bom Report'
        this.downloadReport(url, body, fileName, subType)
      },
      generateGridReport () {
        this.isLoading = true
        let url = '/api/bom/report/bom'
        let body = JSON.stringify(this.getBodyForGrid())
        let fileName = 'Bom_Report_Grid.csv'
        let subType = 'Download Bom Report for Grid Editor'
        this.downloadReport(url, body, fileName, subType)
      },
      async downloadReport (url, body, fileName, subType) {
        const headers = {
          Authorization: `Bearer ${store.auth.idToken}`,
          'Content-Type': 'application/json'
        }
        try {
          let response = await fetch(url, {
            method: 'POST',
            headers,
            body: body
          })
          if (response.status === 200) {
            this.isLoading = false
            const blob = await response.blob()
            var link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = fileName
            link.click()
          } else {
            throw new Error(`Report download request failed: ${response.status}`)
          }
          store.clickStream.obEvent(
            'Bom Report',
            subType,
            response.url,
            'POST',
            response.status,
            body
          )
        } catch (er) {
          if (er.message.includes('400') && subType === 'Download Bom Report') {
            store.popups.messages.push({ 'error': 'Please narrow down the filter search criteria. The application has timed out (30 seconds) and is not able to return the result with current criteria applied' })
          }
          this.isLoading = false
          store.clickStream.obEvent(
            'Error',
            er.message
          )
        }
      },
      goToResults () {
        const query = {}
        this.addMultiParam(query, 'carryoverOrNew')
        this.addCommaStringParam(query, 'color')
        this.addParam(query, 'component')
        this.addParam(query, 'compLocation')
        this.addCommaStringParam(query, 'designUser')
        this.addMultiParam(query, 'GTMTrack')
        this.addMultiParam(query, 'earlyDelivery')
        this.addMultiParam(query, 'isAvailable')
        this.addParam(query, 'classPH')
        this.addParam(query, 'subclassPH')
        this.addCommaStringParam(query, 'material')
        this.addCommaStringParam(query, 'merchUser')
        this.addCommaStringParam(query, 'PC5Code')
        this.addParam(query, 'PC5Name')
        this.addCommaStringParam(query, 'PC9Code')
        this.addParam(query, 'PC9Name')
        this.addCommaStringParam(query, 'PDSUser')
        this.addParam(query, 'PMUser')
        this.addMultiParam(query, 'prodPricePos')
        this.addMultiParam(query, 'prodType')
        this.addParam(query, 'seasonId')
        this.addMultiParam(query, 'lscoMrpLock')
        if (this.powerCoreIndicator !== 'Both') {
          this.addCommaStringParam(query, 'powerCoreIndicator')
        }
        if (deepEqual(query, store.router.query)) {
          store.bomEditor.refresh()
        }

        store.router.replace({
          path: '/search',
          query
        })

        store.router.push({
          path: '/search-results',
          query
        })
      },
      reset () {
        Object.assign(this, defaults)
      },
      setValues (query) {
        this.reset()

        // seasonId and classPH must be set first, and in order
        if (query.seasonId) {
          this.seasonId = query.seasonId
          if (query.classPH) {
            this.classPH = query.classPH
          }
        }

        // Now we can set the others
        const keys = Object.keys(query)
        keys.forEach((key) => {
          const value = query[key]
          if (typeof this[key] === 'string') {
            this[key] = value
          } else if (Array.isArray(this[key])) {
            this[key] = value.split(',')
          }
        })
      }
    }
  })
}

export default bomSearch
