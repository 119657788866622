const pc9Bom = ({ bom, lineItem, subsection }) => {
  if (bom && bom.colorwayName) {
    return bom.colorwayName
  } else if (
    lineItem &&
    lineItem.bompartInfo &&
    lineItem.bompartInfo.length
  ) {
    return lineItem.bompartInfo[0].colorwayName
  } else if (subsection && subsection.lineItemDetails) {
    const lineItemDetail = subsection.lineItemDetails[0]
    if (lineItemDetail && lineItemDetail.bompartInfo) {
      const bompartInfo = lineItemDetail.bompartInfo[0]
      if (bompartInfo) {
        return bompartInfo.colorwayName
      }
    }
  }
  return 'this BOM'
}

const pc9Search = ({ bom, lineItem, subsection }) => {
  if (bom && bom.colorwayName) {
    return bom.colorwayName
  } else if (lineItem && lineItem.bompartInfo) {
    const count = lineItem.bompartInfo.length
    if (count === 1) {
      return lineItem.bompartInfo[0].colorwayName
    } else {
      return `${count} BOMs`
    }
  } else if (subsection && subsection.lineItemDetails) {
    const uniqueBoms = subsection.lineItemDetails.reduce((acc, lineItemDetail) => {
      lineItemDetail.bompartInfo.forEach((bompartInfo) => {
        acc.add(bompartInfo.bompartId)
      })
      return acc
    }, new Set())
    if (uniqueBoms.size === 1) {
      return subsection.lineItemDetails[0].bompartInfo[0].colorwayName
    }
    return `${uniqueBoms.size} BOMs`
  }
  return 'this BOM'
}

export default {
  pc9Bom,
  pc9Search
}
