module.exports = {
  '1014': [
    '2208',
    '2207',
    '2206'
  ],
  '1013': [
    '2209'
  ],
  '1027': [
    '2014',
    '2042'
  ],
  '1016': [
    '2210',
    '2211',
    '2212'
  ],
  '1006': [
    '2035'
  ],
  '1020': [
    '2200',
    '2213'
  ],
  '1018': [
    '2201',
    '2202',
    '2203'
  ],
  '1005': [
    '2205',
    '2204',
    '2063'
  ]
}
