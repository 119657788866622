import bomDescription from '../util/bomDescription.js'
import localeCompare from '../util/localeCompare.js'
import Vue from 'vue'

const emptySection = {
  components: []
}

const emptyResponse = {
  sections: {},
  BOMResults: []
}

const bomEditor = (store) => {
  return new Vue({
    data: {
      mode: 'devBom',
      toggles: []
    },
    computed: {
      allSelected () {
        return this.selectedCount === this.count
      },
      allSectionsEmpty () {
        const keys = Object.keys(this.sections)
        return keys.every((key) => {
          return this.sections[key].components.length === 0
        })
      },
      artwork () {
        return this.sections.artwork || emptySection
      },
      boms () {
        return this.results.BOMResults
      },
      bomIds () {
        return this.boms.map((bom) => {
          return bom.bompartId
        })
      },
      bomsSorted () {
        return Array.from(this.boms).sort((a, b) => {
          return a.colorwayName.localeCompare(b.colorwayName)
        })
      },
      bomsUrl () {
        if (this.isPC9Search) {
          if (this.hasSearchParams) {
            return '/api/bom'
          }
        } else if (this.isDevBom) {
          const { id } = store.router.query
          if (id) {
            return `/api/me/developmentBoms/${id}`
          }
        } else if (this.isPC9Edit) {
          const { id } = store.router.query
          if (id) {
            return `/api/bom/${id}`
          }
        }
        return ''
      },
      careLabel () {
        return this.sections.careLabel || emptySection
      },
      count () {
        return this.boms.length
      },
      devBomTitle () {
        if (this.boms.length) {
          return this.boms[0].title || 'Untitled'
        }
        return ''
      },
      embellishment () {
        return this.sections.embellishment || emptySection
      },
      fabric () {
        return this.sections.fabric || emptySection
      },
      finish () {
        return this.sections.finish || emptySection
      },
      filters: {
        get () {
          const { selectedBoms } = store.router.hash
          if (selectedBoms) {
            return JSON.parse(selectedBoms)
          }
          return this.bomIds
        },
        set (value) {
          const selectedBoms = JSON.stringify(value)
          store.router.setHash({
            selectedBoms
          })
        }
      },
      hasSearchParams () {
        if (this.paramsCount === 0) {
          return false
        }
        if (this.paramsCount === 1 && this.params.id) {
          // This _should_ be a single-BOM route, not a search route
          return false
        }
        return true
      },
      hasUrl () {
        return this.bomsUrl && this.bomsUrl.length
      },
      isDevBom () {
        return this.mode === 'devBom'
      },
      isEmpty () {
        return this.isReady && this.boms.length === 0
      },
      isError () {
        return this.$asyncComputed.results.error
      },
      isReady () {
        return this.$asyncComputed.results.success
      },
      isPC9Edit () {
        return this.mode === 'pc9Edit'
      },
      isPC9Search () {
        return this.mode === 'pc9Search'
      },
      labor () {
        return this.sections.labor || emptySection
      },
      noneSelected () {
        return this.selectedCount <= 0
      },
      other () {
        return this.sections.other || emptySection
      },
      params () {
        if (this.isPC9Search) {
          return store.router.query
        }
        return undefined
      },
      paramsCount () {
        return this.params && Object.keys(this.params).length
      },
      pkgAndLabels () {
        return this.sections.pkgAndLabels || emptySection
      },
      pockets () {
        return this.sections.pockets || emptySection
      },
      searchUrlParams () {
        if (this.isPC9Search) {
          const baseParams = store.router.query
          const prodPricePos = store.codes.mapProdPricePos(
            baseParams.prodPricePos
          )
          if (prodPricePos && prodPricePos.length) {
            return {
              ...baseParams,
              ...{ prodPricePos }
            }
          }
          return baseParams
        }
        return {}
      },
      sections () {
        return this.results.sections
      },
      selectedBoms () {
        return this.results.BOMResults.filter((bom) => {
          return this.filters.includes(bom.bompartId)
        })
      },
      selectedBomIds () {
        return this.selectedBoms.map((bom) => {
          return bom.bompartId
        })
      },
      selectedCount () {
        return this.filters.length
      },
      sundries () {
        return this.sections.sundries || emptySection
      },
      thread () {
        return this.sections.thread || emptySection
      }
    },
    asyncComputed: {
      results: {
        lazy: true,
        default: emptyResponse,
        async get () {
          // Don't bother with API calls unless we have a valid
          // combination of URL path and criteria
          if (!this.hasUrl) {
            return emptyResponse
          }

          const response = await store.api.get({
            url: this.bomsUrl,
            params: this.searchUrlParams
          })

          this.toggles = []

          const { sections } = response.BOMSearchMetadata

          const sectionNames = Object.keys(sections)
          sectionNames.forEach((sectionName) => {
            sections[sectionName].components.forEach((component) => {
              const toggleKey = this.toggleKey(sectionName, component)
              this.toggles.push(toggleKey)
            })
          })
          return response.BOMSearchMetadata
        }
      }
    },
    methods: {
      bomDescription ({ bom, lineItem, subsection }) {
        if (this.isDevBom) {
          return this.devBomTitle
        } else if (this.isPC9Edit) {
          return bomDescription.pc9Bom({ bom, lineItem, subsection })
        } else if (this.isPC9Search) {
          return bomDescription.pc9Search({ bom, lineItem, subsection })
        }
        return 'this BOM'
      },
      collapseComponents (sectionName) {
        this.toggles = []
      },
      deselectAll () {
        this.filters = []
      },
      expandComponents (sectionName) {
        this[sectionName].components.forEach((component) => {
          const toggleKey = this.toggleKey(sectionName, component)
          this.toggles.push(toggleKey)
        })
      },
      findBom (bompartId) {
        const foundBom = this.boms.find((bom) => {
          return bom.bompartId === bompartId
        })
        return foundBom
      },
      getColorwayNames (bomIds) {
        return bomIds.map((bomId) => {
          const bom = this.findBom(bomId)
          return bom.colorwayName
        })
      },
      getDefaultQuantity (section) {
        if (section === 'pkgAndLabels') {
          return 1
        }
        return 0.01
      },
      refresh () {
        this.$asyncComputed.results.update()
      },
      reset () {
        this.boms = []
        this.sections = {}
        this.filters = []
        this.toggles = []
      },
      sectionHasMaterials (section) {
        const { components } = this[section]
        return components.some((component) => {
          const lineItemDetails = component.lineItemDetails || []
          return lineItemDetails.some((lineItem) => {
            return lineItem.materialCode
          })
        })
      },
      sectionStatus (section) {
        const { components } = this[section]
        if (components.length === 0) {
          return {
            hasLineItems: false,
            hasMaterials: false
          }
        } else if (this.sectionHasMaterials(section)) {
          return {
            hasLineItems: true,
            hasMaterials: true
          }
        } else {
          return {
            hasLineItems: true,
            hasMaterials: false
          }
        }
      },
      selectAll () {
        this.filters = undefined
      },
      sortBySubsection (sectionName) {
        return (lhs, rhs) => {
          const { sort } = store.subsections.lookups[sectionName]
          const lhsCompSort = sort[lhs.component] || Number.MAX_SAFE_INTEGER
          const rhsCompSort = sort[rhs.component] || Number.MAX_SAFE_INTEGER
          if (lhsCompSort !== rhsCompSort) {
            return lhsCompSort - rhsCompSort
          }
          return localeCompare(lhs.componentLocation, rhs.componentLocation)
        }
      },
      toggleKey (sectionName, component) {
        return `${sectionName}__${component.component}__${
          component.componentLocation
        }`
      },
      // checks if BOM's are locked
      checkMRPlock (id) {
        if (this.isDevBom) {
          return false
        } else {
          const allBomResults = this.results.BOMResults
          const mrpCheck = allBomResults.find((bom) => {
            return bom.bompartId === id
          })
          if (mrpCheck.lscoMrpLock !== 'Y') {
            return false
          } else {
            return true
          }
        }
      },
      // function to check if pc9 has primary fabric
      hasPrimaryFabric (ids, section) {
        if (this.results.section || this.results.sections[section]) {
          const { components } = this.results.sections[section]
          let bomHasPrimarySection = []
          // console.log(components,this.sections)
          components.forEach((cmp) => {
            if (cmp.component === 'A' || cmp.component === 'primaryFinish') {
              cmp.lineItemDetails.forEach((lineItem) => {
                const bomIds = lineItem.bompartInfo.map((bomInfo) => {
                  return bomInfo.bompartId
                })
                bomHasPrimarySection = [...bomHasPrimarySection, ...bomIds]
              })
            }
          })
          return ids.filter((id) => {
            return !(bomHasPrimarySection.indexOf(id) >= 0)
          })
        } else return ids
      },
      // Checking Power Core PC9 BOMs
      checkPowerCore (id) {
        if (this.isDevBom) {
          return false
        } else {
          const allBomResults = this.results.BOMResults
          const powerCore = allBomResults.find((bom) => {
            return bom.bompartId === id
          })
          if (powerCore.powerCoreIndicator !== 'Y') {
            return false
          } else {
            return true
          }
        }
      }
    }
  })
}

export default bomEditor
