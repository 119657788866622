const sort = {
  performanceHeatTransfer: 1,
  heatTransfer: 2,
  heatTransfer2: 10,
  heatTransferCanada: 3,
  screenPrint: 4,
  screenPrint2: 5,
  screenPrintCanada: 6,
  performancePrint: 7,
  printedLabel: 8,
  miscellaneous: 9
}

const components = Object.keys(sort)

const locations = [
  'front',
  'internal',
  'internalWaistband',
  'neck',
  'pocket',
  'side',
  'sleeve',
  'waistband',
  'yoke'
]

export default {
  components,
  locations,
  sort
}
