module.exports = {
  '1012': [
    '2086',
    '2228',
    '2229',
    '2230',
    '2149',
    '2182'
  ],
  '1021': [
    '2225',
    '2226',
    '2245',
    '2163'
  ],
  '1037': [
    '2134'
  ],
  '1002': [
    '2240',
    '2241',
    '2091'
  ],
  '1008': [
    '7781',
    '7782',
    '2238',
    '2239'
  ],
  '1017': [
    '2060'
  ],
  '1019': [
    '2231',
    '2232',
    '2233',
    '2164'
  ]
}
