export default [ {
  'value': '2003',
  'label': 'Relaxed or Boyfriend'
}, {
  'value': '2004',
  'label': 'Capris'
}, {
  'value': '2014',
  'label': 'Dresses'
}, {
  'value': '2033',
  'label': 'Overalls'
}, {
  'value': '2035',
  'label': 'Polos'
}, {
  'value': '2038',
  'label': 'Regular or Straight'
}, {
  'value': '2042',
  'label': 'Rompers'
}, {
  'value': '2047',
  'label': 'Skinny'
}, {
  'value': '2049',
  'label': 'Slim'
}, {
  'value': '2060',
  'label': 'Trinkets'
}, {
  'value': '2063',
  'label': 'Vests'
}, {
  'value': '2069',
  'label': 'Super Skinny'
}, {
  'value': '2086',
  'label': 'Backpack'
}, {
  'value': '2091',
  'label': 'Beanies'
}, {
  'value': '2104',
  'label': 'Briefs'
}, {
  'value': '2134',
  'label': 'Gloves'
}, {
  'value': '2141',
  'label': 'Leggings'
}, {
  'value': '2149',
  'label': 'Messenger'
}, {
  'value': '2163',
  'label': 'Normal Plaque'
}, {
  'value': '2164',
  'label': 'Pouch'
}, {
  'value': '2182',
  'label': 'Tote'
}, {
  'value': '2190',
  'label': 'Loose or Baggy'
}, {
  'value': '2191',
  'label': 'Taper'
}, {
  'value': '2192',
  'label': 'Bootcut or Flare'
}, {
  'value': '2193',
  'label': 'Casual Khaki'
}, {
  'value': '2194',
  'label': 'Refined Khaki'
}, {
  'value': '2195',
  'label': 'Crops'
}, {
  'value': '2196',
  'label': 'Kids Suits or Kids Sets'
}, {
  'value': '2197',
  'label': 'Mid Length Shorts'
}, {
  'value': '2198',
  'label': 'Short Length Shorts'
}, {
  'value': '2199',
  'label': 'Skirts'
}, {
  'value': '2200',
  'label': 'Woven Shirts'
}, {
  'value': '2201',
  'label': 'Unlined Truckers'
}, {
  'value': '2202',
  'label': 'Lined Truckers'
}, {
  'value': '2203',
  'label': 'Sherpa Lined Truckers'
}, {
  'value': '2204',
  'label': 'Blazers'
}, {
  'value': '2205',
  'label': 'Jackets'
}, {
  'value': '2206',
  'label': 'Full Zip Sweatshirts'
}, {
  'value': '2207',
  'label': 'Hooded Sweatshirts'
}, {
  'value': '2208',
  'label': 'Crew Sweatshirts'
}, {
  'value': '2209',
  'label': 'Sweaters'
}, {
  'value': '2210',
  'label': 'Graphic Tees'
}, {
  'value': '2211',
  'label': 'Non Graphic Tees'
}, {
  'value': '2212',
  'label': 'Other Knit Tops'
}, {
  'value': '2213',
  'label': 'Woven Blouses'
}, {
  'value': '2214',
  'label': 'Jogger'
}, {
  'value': '2225',
  'label': 'Reversible'
}, {
  'value': '2226',
  'label': 'Webbing'
}, {
  'value': '2228',
  'label': 'Crossbody'
}, {
  'value': '2229',
  'label': 'Duffle'
}, {
  'value': '2230',
  'label': 'Handbag'
}, {
  'value': '2231',
  'label': 'Bifold'
}, {
  'value': '2232',
  'label': 'Trifold'
}, {
  'value': '2233',
  'label': 'Card case'
}, {
  'value': '2238',
  'label': 'Bandanas'
}, {
  'value': '2239',
  'label': 'Other'
}, {
  'value': '2240',
  'label': 'Caps'
}, {
  'value': '2241',
  'label': 'Hats'
}, {
  'value': '2245',
  'label': 'Normal Buckle'
}, {
  'value': '2247',
  'label': 'Bras'
}, {
  'value': '2248',
  'label': 'Bodysuits'
}, {
  'value': '2249',
  'label': 'Tank or Cami'
}, {
  'value': '2250',
  'label': 'Tees'
}, {
  'value': '7781',
  'label': 'Woven'
}, {
  'value': '7782',
  'label': 'Knit'
}]
