export default [{
  value: 'no',
  label: 'No'
}, {
  value: 'mayd0',
  label: 'May D0'
}, {
  value: 'pullForward',
  label: 'Pull Forward'
}, {
  value: 'novd0',
  label: 'Nov D0'
}, {
  value: 'earlySalesOnly',
  label: 'Early Sales Only'
}]
