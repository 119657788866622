import Vue from 'vue'

const devBomRename = (store) => {
  return new Vue({
    data: {
      state: 'closed',
      bomId: ''
    },
    computed: {
      isEditing () {
        return (
          this.state === 'editing' ||
          this.state === 'error' ||
          this.state === 'saving'
        )
      },
      isError () {
        return this.state === 'error'
      }
    },
    methods: {
      cancel () {
        this.state = 'closed'
      },
      edit (bomId) {
        this.state = 'editing'
        this.bomId = bomId
      },
      async save ({ bomId, title }) {
        try {
          this.state = 'saving'
          const response = await store.devBomApi.updateBom({ bomId, updates: { title } })
          this.state = 'closed'
          this.bomId = ''
          return response
        } catch (error) {
          this.bomId = ''
          this.state = 'error'
        }
      }
    }
  })
}

export default devBomRename
