export const addParams = (url, params = {}) => {
  const query = queryString(params)
  if (query && query.length) {
    return `${url}?${query}`
  }
  return url
}

export const build = (baseUrl, path, params) => {
  const fullUrl = join(baseUrl, path)
  return addParams(fullUrl, params)
}

export const join = (baseUrl, path) => {
  const baseSlash = baseUrl.endsWith('/')
  const pathSlash = path.startsWith('/')
  if (baseSlash && pathSlash) {
    const noSlash = path.replace('/', '')
    return `${baseUrl}${noSlash}`
  } else if (baseSlash || pathSlash) {
    return `${baseUrl}${path}`
  }
  return `${baseUrl}/${path}`
}

export const queryString = (obj = {}) => {
  const params = new URLSearchParams()
  const keys = Object.keys(obj)
  keys.forEach((key) => {
    params.append(key, obj[key])
  })
  return params.toString()
}

export default {
  addParams,
  build,
  join,
  queryString
}
