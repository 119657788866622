import Dashboard from '../views/Dashboard.vue'
import deepEqual from 'deep-equal'
import queryString from 'query-string'
import Router from 'vue-router'
import SignIn from '../views/SignIn.vue'
import SignInRedirect from '../views/SignInRedirect.vue'
import Vue from 'vue'

Vue.use(Router)

const createRouter = (store) => {
  const authorize = (to, from, next) => {
    if (store.auth.isSignedIn) {
      next()
    } else {
      const nextPath = encodeURIComponent(to.fullPath)
      const signInUrl = `/sign-in#nextPath=${nextPath}`
      next(signInUrl)
    }
  }

  const router = new Router({
    mode: 'history',
    base: store.config.baseUrl,
    routes: [{
      path: '/sign-in',
      name: 'sign-in',
      component: SignIn
    }, {
      path: '/auth-callback',
      component: SignInRedirect
    }, {
      path: '/dashboard',
      name: 'dashboard',
      beforeEnter: authorize,
      component: Dashboard
    }, {
      path: '/search-results',
      redirect: '/search-results/fabric'
    }, {
      path: '/search-results/:currentSection',
      name: 'search-results',
      beforeEnter: authorize,
      component: () => import(/* webpackChunkName: "search" */ '../views/SearchResults.vue')
    }, {
      path: '/search',
      name: 'search',
      beforeEnter: authorize,
      component: () => import(/* webpackChunkName: "search" */ '../views/Search.vue')
    }, {
      path: '/dev-boms',
      name: 'dev-boms',
      beforeEnter: authorize,
      component: () => import(/* webpackChunkName: "search" */ '../views/DevBoms.vue')
    }, {
      path: '/dev-bom',
      redirect: '/dev-bom/fabric'
    }, {
      path: '/dev-bom/:currentSection',
      name: 'dev-bom',
      beforeEnter: authorize,
      component: () => import(/* webpackChunkName: "search" */ '../views/DevBom.vue')
    }, {
      path: '/bom',
      redirect: '/bom/fabric'
    }, {
      path: '/bom/:currentSection',
      name: 'bom',
      beforeEnter: authorize,
      component: () => import(/* webpackChunkName: "search" */ '../views/PC9Editor.vue')
    }, {
      path: '/bom-grid',
      name: 'bom-grid',
      beforeEnter: authorize,
      component: () => import(/* webpackChunkName: "bom-grid" */ '../views/BomGridPage.vue')
    }, {
      path: '/edit-lock',
      name: 'edit-lock',
      beforeEnter: authorize,
      component: () => import(/* webpackChunkName: "bom-grid" */ '../views/EditLockPage.vue')
    }, {
      path: '/carry-over',
      name: 'carry-over',
      beforeEnter: authorize,
      component: () => import(/* webpackChunkName: "bom-grid" */ '../views/CarryoverPage.vue')
    }, {
      path: '/bom-report',
      name: 'bom-report',
      beforeEnter: authorize,
      component: () => import(/* webpackChunkName: "bom-report" */ '../views/BomReport.vue')
    }, {
      path: '/info',
      name: 'info',
      beforeEnter: authorize,
      component: () => import(/* webpackChunkName: "bom-report" */ '../views/WhereUsed.vue')
    },
    {
      path: '/plotRelations',
      name: 'plotRelations',
      beforeEnter: authorize,
      component: () => import(/* webpackChunkName: "bom-report" */ '../views/MaterialRelationGraph.vue')
    }, {
      path: '/',
      redirect: '/dashboard'
    }]
  })

  router.updateQuery = (newValues, options = { useReplace: false }) => {
    const { hash, path, query } = router.history.current

    const newQuery = { ...query, ...newValues }

    if (options.useReplace) {
      router.replace({
        hash,
        path,
        query: newQuery
      })
    } else {
      router.push({
        hash,
        path,
        query: newQuery
      })
    }
  }

  router.updateHash = (newValues, options = { useReplace: false }) => {
    const { hash, path, query } = router.history.current

    const hashStr = queryString.parse(hash)
    const newHash = queryString.stringify({ ...hashStr, ...newValues })

    if (newHash === hash) {
      // VueRouter throws an error if we set the same hash
      return
    }

    if (options.useReplace) {
      router.replace({
        hash: newHash,
        path,
        query
      })
    } else {
      router.push({
        hash: newHash,
        path,
        query
      })
    }
  }

  const routerProxy = new Vue({
    data: {
      hashString: {},
      instance: router,
      params: {},
      path: '',
      query: {}
    },
    computed: {
      fullPath () {
        return `${this.path}${this.queryStringWithQuestion}${this.hashStringWithHash}`
      },
      hash () {
        return queryString.parse(this.hashString)
      },
      hashStringWithHash () {
        if (this.hashString.length) {
          return `#${this.hashString}`
        }
        return ''
      },
      queryString () {
        return queryString.stringify(this.query)
      },
      queryStringWithQuestion () {
        if (this.queryString.length) {
          return `?${this.queryString}`
        }
        return ''
      }
    },
    methods: {
      clearHash (names, options) {
        if (!Array.isArray(names)) {
          names = [ names ]
        }
        const params = names.reduce((acc, name) => {
          return { ...acc, [name]: undefined }
        }, {})
        this.setHash(params, options)
      },
      init () {
        routerProxy.path = router.history.current.path
        routerProxy.hashString = router.history.current.hash
        routerProxy.query = router.history.current.query
        routerProxy.params = router.history.current.params

        router.afterEach((to, from) => {
          if (to.hash !== from.hash) {
            routerProxy.hashString = to.hash
          }

          if (!deepEqual(to.query, from.query)) {
            routerProxy.query = to.query
          }

          if (!deepEqual(to.params, from.params)) {
            routerProxy.params = to.params
          }

          if (to.path !== from.path) {
            routerProxy.path = to.path
          }
        })
      },
      push (params) {
        return router.push(params)
      },
      replace (params) {
        return router.replace(params)
      },
      setHash (obj, options) {
        router.updateHash(obj, options)
      },
      setQuery (obj) {
        router.updateQuery(obj)
      }
    }
  })

  routerProxy.init()

  return routerProxy
}

export default createRouter
