import { join } from '../util/url.js'
import jwtDecode from 'jwt-decode'
import pkce from '../util/pkce.js'
import queryString from 'query-string'
import { secondsToHuman } from '../util/time.js'
import Vue from 'vue'

const sessionWarningTime = 5 * 60

const auth = (store) => {
  return new Vue({
    data: {
      sessionWarningId: false
    },
    computed: {
      appRedirectUri () {
        return join(window.location.origin, store.session.nextPath)
      },
      accessToken: {
        get () {
          return store.session && store.session.accessToken
        },
        set (value) {
          store.session.accessToken = value
        }
      },
      accessTokenExpiresAt () {
        return this.decodedAccessToken.exp
      },
      accessTokenIssuedAt () {
        return this.decodedAccessToken.iat
      },
      authUrl () {
        return join(store.config.authUrl, '/authorize')
      },
      decodedAccessToken () {
        if (this.accessToken && this.accessToken.length) {
          return jwtDecode(this.accessToken)
        }
        return false
      },
      decodedIdToken () {
        if (this.idToken && this.idToken.length) {
          return jwtDecode(this.idToken)
        }
        return false
      },
      idToken: {
        get () {
          return store.session.idToken
        },
        set (value) {
          store.session.idToken = value
        }
      },
      idTokenExpiresAt () {
        return this.decodedIdToken.exp
      },
      isSignedIn () {
        return this.accessToken
      },
      signOutRedirectUri () {
        return join(window.location.origin, '/auth-callback')
      },
      signInRedirectUri () {
        return join(window.location.origin, '/auth-callback')
      },
      signOutUrl () {
        return join(store.config.authUrl, '/logout')
      },
      tokenUrl () {
        return '/token'
      }
    },
    methods: {
      async buildAuthorizationParams () {
        const state = pkce.generateRandomString()
        const codeVerifier = pkce.generateRandomString()

        // We will need these params again after the redirect
        // to the OAuth provider.
        store.session.pkceState = state
        store.session.pkceCodeVerifier = codeVerifier

        const params = {
          response_type: 'code',
          client_id: store.config.authClientId,
          state,
          scope: 'openid',
          redirect_uri: this.signInRedirectUri
        }

        return params
      },
      getAccessTokenIsValid () {
        if (this.decodedAccessToken) {
          return this.getAccessTokenSecondsRemaining() > 0
        }
        return false
      },
      getAccessTokenSecondsRemaining () {
        const now = Date.now() / 1000
        return Math.round(this.accessTokenExpiresAt - now)
      },
      getAccessTokenTimeRemaining () {
        return secondsToHuman(this.getAccessTokenSecondsRemaining())
      },
      getIdTokenIsValid () {
        return this.getIdTokenSecondsRemaining() > 0
      },
      getIdTokenSecondsRemaining () {
        const now = Date.now() / 1000
        return this.idTokenExpiresAt - now
      },
      getSignInLinkTarget () {
        const nextPath = encodeURIComponent(store.router.fullPath)
        const linkTarget = {
          path: '/sign-in',
          hash: `nextPath=${nextPath}`
        }
        return linkTarget
      },
      hasValidTokens () {
        return (
          this.getIdTokenIsValid()
        )
      },
      init () {
        setInterval(this.tick.bind(this), 5000)
      },
      async requestAccessToken (code, state) {
        const prevState = store.session.pkceState
        if (prevState !== state) {
          throw new Error(
            'Invalid state returned from OAuth provider',
            `Got: ${state}`,
            `Expected: ${prevState}`
          )
        }

        // const codeVerifier = store.session.pkceCodeVerifier

        const body = {
          grant_type: 'authorization_code',
          code,
          client_id: store.config.authClientId,
          redirect_uri: this.signInRedirectUri,
          scope: 'openid offline_access',
          source: store.config.authSource
        }

        const headers = {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
        }

        // Note the use of `fetch` instead of `store.api`,
        // since this request is so different from our API requests.
        try {
          const response = await fetch(this.tokenUrl, {
            method: 'POST',
            headers,
            body: JSON.stringify(body)
          })

          const data = await response.json()

          if (data.id_token && data.access_token) {
            this.idToken = data.id_token
            this.accessToken = data.access_token

            store.session.pkceState = undefined
            store.session.pkceCodeVerifier = undefined

            if (store.session.nextPath) {
              const nextPath = decodeURIComponent(store.session.nextPath)
              store.session.nextPath = ''
              store.router.replace(nextPath)
            } else {
              store.router.replace('/dashboard')
            }
          } else {
            store.router.replace({
              path: '/auth-callback',
              query: {
                error: 'NO_ACCESS_TOKEN'
              }
            })
          }
        } catch (error) {
          store.router.replace({
            path: '/auth-callback',
            query: {
              error: 'FAILED_TO_FETCH_ACCESS_TOKEN'
            }
          })
        }
      },
      showSessionWarningPopup () {
        const linkTarget = this.getSignInLinkTarget()
        if (this.sessionWarningId) {
          store.popups.update({
            id: this.sessionWarningId,
            text: `Your session will expire in ${this.getAccessTokenTimeRemaining()}`,
            timeout: -1,
            linkText: 'Refresh Session',
            linkTarget
          })
        } else {
          this.sessionWarningId = store.popups.add({
            text: `Your session will expire in ${this.getAccessTokenTimeRemaining()}`,
            timeout: -1,
            linkText: 'Refresh Session',
            linkTarget
          })
        }
      },
      showSignedOutPopup () {
        const linkTarget = this.getSignInLinkTarget()
        if (this.sessionWarningId) {
          store.popups.update({
            id: this.sessionWarningId,
            text: 'Your session has ended, and you have been signed out.',
            timeout: -1,
            linkText: 'Sign In',
            linkTarget
          })
        } else {
          this.sessionWarningId = store.popups.add({
            text: 'Your session has ended, and you have been signed out.',
            timeout: -1,
            linkText: 'Sign In',
            linkTarget
          })
        }
      },
      async signIn (nextPath) {
        store.session.nextPath = nextPath

        const params = await this.buildAuthorizationParams()
        const query = queryString.stringify(params)
        const url = `${this.authUrl}?${query}`
        window.location = url
      },
      async signOut () {
        if (this.getIdTokenIsValid()) {
          const params = {
            id_token_hint: this.idToken,
            post_logout_redirect_uri: this.signInRedirectUri
          }

          const query = queryString.stringify(params)
          this.idToken = undefined
          this.accessToken = undefined

          window.location = `${this.signOutUrl}?${query}`
        } else {
          this.idToken = undefined
          this.accessToken = undefined
          window.location = '/auth-callback'
        }
      },
      tick () {
        if (this.getIdTokenIsValid()) {
          if (this.getIdTokenSecondsRemaining() < sessionWarningTime) {
            this.showSessionWarningPopup()
          }
        } else {
          this.signOut()
        // this.showSignedOutPopup()
        }
      }
    }
  })
}

export default auth
