import Vue from 'vue'

const whereUsed = (store) => {
  return new Vue({
    data: {
      materialName: '',
      params: {
        brands: [],
        seasons: []
      },
      selectedColor: '',
      selectedVendor: '',
      activeNav: 'LINE ITEMS',
      materialData: {}
    },
    computed: {
      color: {
        get () {
          return 'Select Color'
        },
        set (value) {
          this.selectedColor = value
        }
      },
      vendor: {
        get () {
          return 'Select vendor'
        },
        set (value) {
          this.selectedVendor = value
        }
      },
      currentNav: {
        set (value) {
          this.activeNav = value
        }
      },
      supplier () {
        if (this.materialData.materialColors) {
          return this.materialData.materialColors.map(matCol => {
            if (matCol.vendorName) return matCol.vendorName
          })
        } else {
          return ''
        }
      },
      AllColorUsed () {
        if (this.materialData.materialColors) {
          return this.materialData.materialColors.map(matCol => {
            if (matCol.colorName) return matCol.colorName
          })
        } else {
          return ''
        }
      }
    },
    asyncComputed: {
      currentMaterialName: {
        lazy: true,
        default: '',
        async get () {
          if (this.materialName.length > 0) {
            //   console.log("heree")
            const url = `/api/search/material/whereUsed/${this.materialName}`
            const response = await store.api.get({
              url
            })
            if (response) {
              this.materialData = response
              return response
            }
          }
        //   return this.material
        }
      }
    },
    methods: {
      currentMaterial (material) {
        this.materialName = material
      },
      gotToResults () {
        // const query = {}
        // store.router.replace({
        //   path: '/plotRelations'
        // })

        store.router.push({
          path: '/plotRelations'
        })
      }
    }
  })
}

export default whereUsed
