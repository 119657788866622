<template>
  <header class="Header">
    <div class="splitter">
      <ul class="nav">
        <li>
          <router-link
            to="/dashboard"
            :class="getClass('/dashboard')"
          >
            Dashboard
          </router-link>
        </li>
        <li v-if="!viewOnly">
          <router-link
            to="/dev-boms"
            :class="getClass('/dev-boms')"
          >
            Development BOMs
          </router-link>
        </li>
        <li>
          <router-link
            to="/search"
            :class="getClass('/search')"
          >
            <SearchIcon />
            Search
          </router-link>
        </li>
        <li>
          <router-link
            to="/bom-report"
            :class="getClass('/bom-report')"
          >
            <DocPageIcon class="docPageIcon" />
            BOM Report
          </router-link>
        </li>
        <!-- <li>
          <router-link
            to="/info"
            :class="getClass('/info')"
          >
            <DocPageIcon class="docPageIcon" />
            Where Used
          </router-link>
        </li> -->
      </ul>
      <a class="prefs">
        <SettingsMenu />
      </a>
    </div>
  </header>
</template>

<script>
import DocPageIcon from './icons/DocPageIcon.vue'
import SearchIcon from './icons/SearchIcon.vue'
import SettingsMenu from './SettingsMenu.vue'

export default {
  components: {
    DocPageIcon,
    SearchIcon,
    SettingsMenu
  },
  inject: ['store'],
  props: {
    activeNav: {
      type: String,
      default: ''
    }
  },
  computed: {
    viewOnly () {
      return this.store.rbac.hasPermission('view', 'devBoms')
    }
  },
  methods: {
    getClass (which) {
      if (this.$route.path.startsWith(which)) {
        return 'active'
      }
      return ''
    }
  }
}
</script>

<style lang="scss">
/* stylelint-disable no-descending-specificity */
header.Header {
  background: $gray;
  padding: 0;
  position: relative;
  font-weight: 500;
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  justify-content: center;

  .splitter {
    @include centerColumn();

    display: flex;
    justify-content: space-between;
    align-items: center;

    .nav {
      display: flex;

      li {
        margin-right: 3.2rem;

        a {
          padding: 0;
          height: $headerHeight;
          display: flex;
          align-items: center;
          color: $gray-header-links;
          transition: $anim-trans-base;
          position: relative;
          font-size: 1.3rem;
          text-transform: uppercase;
          letter-spacing: 0.05rem;

          &:hover {
            color: $white;

            &::after {
              width: 100%;
              left: 0;
              opacity: 0.15;
            }

            svg path {
              stroke: $white;
            }
          }

          &::after {
            content: "";
            background: $gray-lines;
            position: absolute;
            left: 50%;
            bottom: 0;
            height: 1px;
            width: 0;
            transition: all 0.25s;
            opacity: 0;
          }

          &.active {
            color: $white;

            &::after {
              width: 100%;
              background: $red;
              opacity: 1;
              left: 0;
            }

            svg path {
              stroke: $white;
            }
          }

          svg {
            margin-right: 0.5rem;

            path {
              transition: $anim-trans-base;
            }
          }
        }
      }
    }

    .docPageIcon {
      height: 16px;
      width: 16px;

      path {
        stroke: $gray-header-links;
      }
    }

    .prefs {
      margin-left: auto;
      height: $headerHeight;
      display: flex;
      align-items: center;
    }
  }
}
</style>
