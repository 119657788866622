export default [{
  value: '01',
  label: 'TIER 1'
}, {
  value: '02',
  label: 'TIER 2'
}, {
  value: '03',
  label: 'TIER 3'
}, {
  value: '04',
  label: 'TIER 4'
}]
