import Vue from 'vue'

export default () => {
  return new Vue({
    data: {
      brand: '',
      gender: '',
      season: '',
      type: '',
      year: ''
    },
    computed: {
      seasonId: {
        get () {
          if (
            this.brand.length &&
            this.season.length &&
            this.year.length &&
            this.gender.length &&
            this.type.length
          ) {
            return `${this.brand}-${this.season}-${this.year}-${this.gender}-${this.type}`
          }
          return ''
        },
        set (value = '') {
          const parts = value.split('-')
          this.brand = parts[0] || ''
          this.season = parts[1] || ''
          this.year = parts[2] || ''
          this.gender = parts[3] || ''
          this.type = parts[4] || ''
        }
      }
    }
  })
}
