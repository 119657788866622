import localeCompare from '../../util/localeCompare.js'

const all = [{
  value: '2Hole',
  label: '2-Hole'
}, {
  value: '2HoleBarrel',
  label: '2 Hole Barrel'
}, {
  value: '2Way',
  label: '2 Way'
}, {
  value: '3Hole',
  label: '3-Hole'
}, {
  value: '4Hole',
  label: '4-Hole'
}, {
  value: '6Hole',
  label: '6-Hole'
}, {
  value: 'AAG',
  label: 'ACCESSORY ACCOMPANYING GARMENT'
}, {
  value: 'Adjustable',
  label: 'Adjustable'
}, {
  value: 'Aglet',
  label: 'Aglet'
}, {
  value: 'Applique',
  label: 'Applique'
}, {
  value: 'Attribute',
  label: 'Attribute'
}, {
  value: 'Bag',
  label: 'Bag'
}, {
  value: 'Barbs',
  label: 'Barbs'
}, {
  value: 'Booklet',
  label: 'Booklet'
}, {
  value: 'Box',
  label: 'Box'
}, {
  value: 'Braided',
  label: 'Braided'
}, {
  value: 'Butterfly',
  label: 'Butterfly'
}, {
  value: 'COO',
  label: 'COO'
}, {
  value: 'Cap',
  label: 'Cap'
}, {
  value: 'Capped',
  label: 'Capped'
}, {
  value: 'Care',
  label: 'Care'
}, {
  value: 'Chain',
  label: 'Chain'
}, {
  value: 'Clamp',
  label: 'Clamp'
}, {
  value: 'Clasp',
  label: 'Clasp'
}, {
  value: 'Clip',
  label: 'Clip'
}, {
  value: 'ClosedEnd',
  label: 'Closed End'
}, {
  value: 'ClosedTop',
  label: 'Closed Top'
}, {
  value: 'ClosedatBothEnds',
  label: 'Closed at Both Ends'
}, {
  value: 'CollarBand',
  label: 'Collar Band'
}, {
  value: 'CollarStays',
  label: 'Collar Stays'
}, {
  value: 'CollarStrip',
  label: 'Collar Strip'
}, {
  value: 'CollarTipping',
  label: 'Collar Tipping'
}, {
  value: 'Concave',
  label: 'Concave'
}, {
  value: 'Cord',
  label: 'Cord'
}, {
  value: 'Cordend',
  label: 'Cord-end'
}, {
  value: 'CountrySpec',
  label: 'Country Specific'
}, {
  value: 'Crochet',
  label: 'Crochet'
}, {
  value: 'Curtain',
  label: 'Curtain'
}, {
  value: 'DRing',
  label: 'D-Ring'
}, {
  value: 'Decorative',
  label: 'Decorative'
}, {
  value: 'Delicate',
  label: 'Delicate'
}, {
  value: 'Disclaimer',
  label: 'Disclaimer'
}, {
  value: 'Domed',
  label: 'Domed'
}, {
  value: 'Double',
  label: 'Double'
}, {
  value: 'DoubleProng',
  label: 'Double Prong'
}, {
  value: 'Elastic',
  label: 'Elastic'
}, {
  value: 'Envelope',
  label: 'Envelope'
}, {
  value: 'Fabric',
  label: 'Fabric'
}, {
  value: 'Feature',
  label: 'Feature Hangtag'
}, {
  value: 'FiremanClasp',
  label: 'Fireman Clasp'
}, {
  value: 'FitCallout',
  label: 'Fit Callout'
}, {
  value: 'Flat',
  label: 'Flat'
}, {
  value: 'GarmentBag',
  label: 'Garment Bag'
}, {
  value: 'GarmentBox',
  label: 'Garment Box'
}, {
  value: 'Gripper',
  label: 'Gripper'
}, {
  value: 'Hammered',
  label: 'Hammered'
}, {
  value: 'Hanging',
  label: 'Hanging'
}, {
  value: 'Hasp',
  label: 'Hasp'
}, {
  value: 'HeadtoHead',
  label: 'Head to Head'
}, {
  value: 'HighPile',
  label: 'High Pile'
}, {
  value: 'HookEye',
  label: 'Hook & Eye'
}, {
  value: 'Irregular',
  label: 'Irregular'
}, {
  value: 'LTab',
  label: 'L-Tab'
}, {
  value: 'Loop',
  label: 'Loop'
}, {
  value: 'LowPile',
  label: 'Low Pile'
}, {
  value: 'Main',
  label: 'Main'
}, {
  value: 'Matchbook',
  label: 'Matchbook'
}, {
  value: 'MetalTrim',
  label: 'Metal Trim'
}, {
  value: 'Military',
  label: 'Military'
}, {
  value: 'Misc',
  label: 'Misc.'
}, {
  value: 'NA',
  label: 'Not Applicable'
}, {
  value: 'Nipple',
  label: 'Nipple'
}, {
  value: 'OpenEnd',
  label: 'Open End'
}, {
  value: 'OpenTop',
  label: 'Open Top'
}, {
  value: 'Other',
  label: 'Other'
}, {
  value: 'PigNose',
  label: 'Pig Nose'
}, {
  value: 'PocketFlasher',
  label: 'Pocket Flasher'
}, {
  value: 'PolyPrint',
  label: 'Polybag Printing Format'
}, {
  value: 'PolySticker',
  label: 'Polybag Sticker'
}, {
  value: 'Post',
  label: 'Post'
}, {
  value: 'PriceStickers',
  label: 'Price Stickers'
}, {
  value: 'Printed',
  label: 'Printed'
}, {
  value: 'Prong',
  label: 'Prong'
}, {
  value: 'Pull',
  label: 'Pull'
}, {
  value: 'Pyramid',
  label: 'Pyramid'
}, {
  value: 'RFID',
  label: 'RFID'
}, {
  value: 'Rectangular',
  label: 'Rectangular'
}, {
  value: 'Ribbon',
  label: 'Ribbon'
}, {
  value: 'Round',
  label: 'Round'
}, {
  value: 'SemiOpenTop',
  label: 'Semi-Open Top'
}, {
  value: 'Set',
  label: 'Set'
}, {
  value: 'SingleProng',
  label: 'Single Prong'
}, {
  value: 'Size',
  label: 'Size'
}, {
  value: 'Socket',
  label: 'Socket'
}, {
  value: 'Solid',
  label: 'Solid'
}, {
  value: 'Soustache',
  label: 'Soustache'
}, {
  value: 'Spring',
  label: 'Spring'
}, {
  value: 'Square',
  label: 'Square'
}, {
  value: 'Staples',
  label: 'Staples'
}, {
  value: 'Stem',
  label: 'Stem'
}, {
  value: 'Stud',
  label: 'Stud'
}, {
  value: 'TailtoTail',
  label: 'Tail to Tail'
}, {
  value: 'Tape',
  label: 'Tape'
}, {
  value: 'Tassle',
  label: 'Tassle'
}, {
  value: 'TissuePaper',
  label: 'Tissue Paper'
}, {
  value: 'Tubular',
  label: 'Tubular'
}, {
  value: 'Twill',
  label: 'Twill'
}, {
  value: 'UVM',
  label: 'UVM'
}, {
  value: 'Underpart',
  label: 'Underpart'
}, {
  value: 'VMFold',
  label: 'UVM Fold Over'
}, {
  value: 'VMHangtag',
  label: 'UVM Hangtag'
}, {
  value: 'VMPrinting',
  label: 'UVM Printing Format'
}, {
  value: 'VMSticker',
  label: 'UVM Sticker'
}, {
  value: 'Washer',
  label: 'Washer'
}, {
  value: 'Webbing',
  label: 'Webbing'
}, {
  value: 'Woven',
  label: 'Woven'
}]

export default all.sort((lhs, rhs) => {
  return localeCompare(lhs.label, rhs.label)
})
