import { Base64 } from 'js-base64'

export const encode = (params) => {
  const json = JSON.stringify(params)
  return Base64.encode(json)
}

export const decode = (params) => {
  const json = Base64.decode(params)
  if (json.length === 0) {
    return {}
  }
  return JSON.parse(json)
}
