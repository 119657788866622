import artwork from './codesData/subsections/artwork.js'
import careLabel from './codesData/subsections/careLabel.js'
import embellishment from './codesData/subsections/embellishment.js'
import fabric from './codesData/subsections/fabric.js'
import finish from './codesData/subsections/finish.js'
import localeCompare from '../util/localeCompare.js'
import other from './codesData/subsections/other.js'
import packaging from './codesData/subsections/packaging.js'
import sundries from './codesData/subsections/sundries.js'
import thread from './codesData/subsections/thread.js'
import Vue from 'vue'

const byLabel = (lhs, rhs) => {
  return localeCompare(lhs.label, rhs.label)
}

const bySortingNumber = (lhs, rhs) => {
  // return lhs.sortingNumber - rhs.sortingNumber
  // for alphabetically sorting
  return localeCompare(lhs.label, rhs.label)
}

const subsections = store => {
  return new Vue({
    computed: {
      artwork () {
        return {
          components: this.buildComponents(artwork),
          locations: this.buildLocations(artwork.locations)
        }
      },
      careLabel () {
        return {
          components: this.buildComponents(careLabel),
          locations: this.buildLocations(careLabel.locations)
        }
      },
      embellishment () {
        return {
          components: this.buildComponents(embellishment),
          locations: this.buildLocations(embellishment.locations)
        }
      },
      fabric () {
        return {
          components: this.buildComponents(fabric),
          locations: this.buildLocations(fabric.locations)
        }
      },
      finish () {
        return {
          components: this.buildComponents(finish),
          locations: this.buildLocations(finish.locations)
        }
      },
      lookups () {
        return {
          artwork,
          careLabel,
          embellishment,
          fabric,
          finish,
          other,
          pkgAndLabels: packaging,
          sundries,
          thread
        }
      },
      other () {
        return {
          components: this.buildComponents(other),
          locations: this.buildLocations(other.locations)
        }
      },
      pkgAndLabels () {
        return {
          components: this.buildComponents(packaging),
          locations: this.buildLocations(packaging.locations)
        }
      },
      sundries () {
        return {
          components: this.buildComponents(sundries),
          locations: this.buildLocations(sundries.locations)
        }
      },
      thread () {
        return {
          components: this.buildComponents(thread),
          locations: this.buildLocations(thread.locations)
        }
      }
    },
    methods: {
      buildComponents (section) {
        const pairs = section.components.map((value) => {
          const sortingNumber = section.sort[value]
          const label = store.codes.getComponentLabel(value)
          return {
            value,
            label,
            sortingNumber
          }
        })
        return pairs.sort(bySortingNumber)
      },
      buildLocations (locations) {
        const pairs = locations.map(value => {
          const label = store.codes.getLocationLabel(value)
          return {
            value,
            label
          }
        })
        return pairs.sort(byLabel)
      }
    }
  })
}

export default subsections
