import createLookup from './lookup.js'
import Vue from 'vue'

const lookups = (store) => {
  return new Vue({
    data: {
      instances: []
    },
    methods: {
      hide () {
        this.instances.forEach((instance) => {
          instance.hide()
        })
      },
      register (uid) {
        const lookup = createLookup(store, uid)
        this.instances.push(lookup)
        return lookup
      }
    }
  })
}

export default lookups
