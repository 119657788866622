const sort = {
  miscellaneous: 1
}

const components = Object.keys(sort)

const locations = [
  'allOver',
  'arcuate',
  'back',
  'backNeck',
  'centerBack',
  'centerFront',
  'cfPlacket',
  'chest',
  'collar',
  'cuff',
  'front',
  'hem',
  'hood',
  'internal',
  'internalWaistband',
  'leftChest',
  'mainBody',
  'neck',
  'placket',
  'pocket',
  'shell',
  'side',
  'sleeve',
  'waistband',
  'yoke'
]

export default {
  components,
  locations,
  sort
}
