module.exports = {
  '1004': [
    '2069',
    '2047',
    '2049',
    '2038',
    '2003',
    '2190',
    '2191',
    '2192'
  ],
  '1009': [
    '2197',
    '2198'
  ],
  '1033': [
    '2193',
    '2194'
  ],
  '1001': [
    '2004',
    '2195'
  ],
  '1034': [
    '2033',
    '2214',
    '2196'
  ],
  '1010': [
    '2199'
  ]
}
