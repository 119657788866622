<template>
  <footer class="Footer">
    <div class="center-col">
      <LeviStraussWordmark />
      <span>&copy;2020 Levi Strauss &amp; Co. All rights reserved.</span>
      <Popups />
    </div>
  </footer>
</template>

<script>
import LeviStraussWordmark from './svg/LeviStraussWordmark.vue'
import Popups from './Popups.vue'

export default {
  components: {
    LeviStraussWordmark,
    Popups
  }
}
</script>

<style lang="scss">
footer.Footer {
  height: 4.6rem;
  width: 100%;
  flex-grow: 0;
  flex-shrink: 0;

  .center-col {
    @include centerColumn;

    position: relative;
    border-top: 1px solid $gray-lines;
    display: flex;
    align-items: center;
    color: #818b94;
    height: 100%;

    span {
      margin-left: auto;
    }
  }
}
</style>
