import Vue from 'vue'

export default store => {
  const { roles } = store.codes
  return new Vue({
    methods: {
      hasPermission (operation, resource, params) {
        const ruleName = `rule_${operation}_${resource}`
        return this[ruleName](params)
      },
      isViewOnlyRole () {
        return store.user.isRole(roles.view)
      },
      rule_edit_lineItem (params) {
        if (params.sectionName === 'pkgAndLabels') {
          return (
            store.user.isRole(roles.design) || store.user.isRole(roles.merch) || store.user.isRole(roles.pd)
          )
        }
        return store.user.isRole(roles.design) || store.user.isRole(roles.pd)
      },
      rule_view_devBoms (params) {
        return this.isViewOnlyRole()
      },
      rule_view_userRole (params) {
        return this.isViewOnlyRole()
      }
    }
  })
}
