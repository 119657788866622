import Vue from 'vue'

const devBomApi = (store) => {
  return new Vue({
    methods: {
      async addLineItem ({ bomIds, lineItem, popups }) {
        const url = `/api/me/developmentBoms/${bomIds[0]}/${lineItem.section}`
        const body = lineItem
        return store.api.post({
          url,
          body,
          popups
        })
      },
      async assignToPC9s ({ bomId, seasonId, colorwayCodes }) {
        const url = `/api/me/developmentBoms/${bomId}/assign`

        const body = colorwayCodes.map((colorwayCode) => {
          const productCode = colorwayCode.split('-')[0]
          return {
            productCode,
            seasonId,
            colorwayCode
          }
        })

        return store.api.post({
          url,
          body
        })
      },
      async copyFrom ({ type, bomId, sections }) {
        if (type === 'copyPC9') {
          return this.copyFromPC9({ bomId, sections })
        } else if (type === 'copyDev') {
          return this.copyFromDev({ bomId, sections })
        }
        throw new Error(`Unable to copy from BOM of type: ${type}`)
      },
      copyFromDev ({ bomId, sections }) {
        const url = `/api/me/developmentBoms/${bomId}/copy`
        const body = sections
        return store.api.post({
          url,
          body
        })
      },
      copyFromPC9 ({ bomId, sections }) {
        const url = `/api/bom/${bomId}/copy`
        const body = sections
        return store.api.post({
          url,
          body
        })
      },
      deleteBoms ({ bomIds }) {
        const url = `/api/me/developmentBoms`
        const body = bomIds
        return store.api.delete({
          url,
          body
        })
      },
      find ({ bomId }) {
        const url = `/api/me/developmentBoms/${bomId}`
        return store.api.get({ url })
      },
      async removeLineItem ({ lineItemIds, popups }) {
        // Using the bulk APIs here for consistency across editor modes
        const url = '/api/bom/lineItems'
        const body = {
          lineItemIds
        }
        return store.api.delete({
          url,
          body,
          popups
        })
      },
      async removeMaterial ({ lineItemIds, popups }) {
        const body = {
          lineItemIds
        }
        const url = `/api/me/developmentBoms/materials`
        return store.api.delete({
          url,
          body,
          popups
        })
      },
      share (body) {
        const popups = {
          workingText () {
            if (body.length === 1) {
              return 'Sharing Development BOM…'
            }
            return `Sharing ${body.length} Development BOMs…`
          },
          successText () {
            if (body.length === 1) {
              return 'Development BOM shared.'
            }
            return `${body.length} Development BOMs shared.`
          },
          errorText: 'Error. Development BOM(s) not shared.',
          linkText: 'View Development BOM',
          linkTarget: () => {
            if (body.length === 1) {
              return `/dev-bom/fabric?id=${body[0].id}`
            }
          }
        }

        const url = `/api/me/developmentBoms/share`
        return store.api.post({
          url,
          body,
          popups
        })
      },
      toggleFavoriteBom ({ bomId, isFavorite }) {
        const url = `/api/me/developmentBoms/favorite/${bomId}`
        if (isFavorite) {
          return store.api.post({
            url
          })
        } else {
          return store.api.delete({
            url
          })
        }
      },
      updateBom ({ bomId, updates }) {
        const url = `/api/me/developmentBoms/${bomId}`
        return store.api.put({
          url,
          body: updates
        })
      },
      async updateLineItem ({ lineItemIds, lineItem, bompartId, section, popups }) {
        const bomlinkId = lineItemIds[0]
        const url = `/api/me/developmentBoms/${bompartId}/${section}/${bomlinkId}`
        return store.api.put({
          url,
          body: lineItem,
          popups
        })
      },
      async updateComponent (lineItemIds, lineItem, popups) {
        const url = '/api/bom/lineItems'
        const body = {
          lineItemIds,
          lineItem
        }
        return store.api.put({
          url,
          body,
          popups
        })
      }
    }
  })
}

export default devBomApi
