import Vue from 'vue'

const favoriteDevBoms = (store) => {
  return new Vue({
    data: {
      items: [],
      state: 'idle'
    },
    computed: {
      isEmpty () {
        return (
          this.state === 'ready' &&
          this.items.length === 0
        )
      },
      isReady () {
        return this.state === 'ready'
      }
    },
    methods: {
      async fetch () {
        const params = {
          isFavorite: true,
          showDeleted: false
        }
        this.state = 'loading'
        const response = await store.api.get({
          url: '/api/me/developmentBoms',
          params
        })
        this.items = response.results
        this.state = 'ready'
      }
    }
  })
}

export default favoriteDevBoms
