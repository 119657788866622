import Vue from 'vue'

export default (store) => {
  return new Vue({
    data: {
      bomId: false,
      criteria: {
        sections: [],
        seasonId: false,
        pc9Codes: []
      },
      filterHash: {},
      isSubmitting: true,
      showErrors: false
    },
    computed: {
      allSelected () {
        return this.statusResults.length === Object.keys(this.filterHash).length
      },
      hasCriteria () {
        const { criteria } = this
        return (
          criteria.sections.length &&
          criteria.seasonId &&
          criteria.pc9Codes.length
        )
      },
      isLoading () {
        return this.$asyncComputed.statusResults.updating
      },
      isValid () {
        return this.selectedCodes.length
      },
      selectedCodes () {
        const keys = Object.keys(this.filterHash)
        return keys.filter((pc9Code) => {
          return this.filterHash[pc9Code]
        })
      }
    },
    asyncComputed: {
      statusResults: {
        lazy: true,
        default: [],
        async get () {
          if (this.hasCriteria) {
            const url = '/api/bom/status'
            const body = { ...this.criteria }
            const response = await store.api.post({
              url,
              body
            })
            return response
          }
          return []
        }
      }
    },
    methods: {
      selectAll () {
        const pc9CodeList = this.statusResults
        pc9CodeList.forEach(pc9 => Vue.set(this.filterHash, pc9.PC9Code, true))
      },
      deselectAll () {
        this.filterHash = {}
      },
      isSelected (pc9code) {
        return this.filterHash[pc9code]
      },
      reset ({ bomId, sections, seasonId, pc9Codes }) {
        this.isSubmitting = false
        this.showErrors = false
        this.filterHash = {}
        this.criteria.sections = []
        this.criteria.pc9Codes = []

        // Give vue a tick to render the empty list before setting criteria
        this.$nextTick(() => {
          this.bomId = bomId
          this.criteria.sections = sections
          this.criteria.seasonId = seasonId
          this.criteria.pc9Codes = pc9Codes
        })
      },
      setSelected (pc9code, value) {
        Vue.set(this.filterHash, pc9code, value)
      },
      async submit () {
        this.showErrors = false
        this.isSubmitting = true
        const url = `/api/me/developmentBoms/${this.bomId}/assign`
        const body = {
          sections: this.criteria.sections,
          seasonId: this.criteria.seasonId,
          pc9Codes: this.selectedCodes
        }
        store.clickStream.obEvent(
          'Development BOM',
          'Apply Development BOM',
          url,
          'POST',
          '',
          body
        )
        return store.api.post({
          url,
          body
        })
      }
    }
  })
}
