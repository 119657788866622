<template>
  <ContextMenu
    class="FavoriteDevBomContextMenu"
    :x-offset="-130"
    :arrow-offset="139"
  >
    <ul>
      <li>
        <router-link
          :to="targetUrl"
        >
          View
        </router-link>
      </li>
      <li>
        <ModalLink
          name="share-devbom"
          :params="bomItem"
        >
          Share
        </ModalLink>
      </li>
      <li class="danger newSection">
        <a @click="onRemoveFavoriteClick">Remove Favorite</a>
      </li>
    </ul>
  </ContextMenu>
</template>

<script>
import ContextMenu from '../ContextMenu.vue'
import ModalLink from '../ModalLink.vue'

export default {
  components: {
    ContextMenu,
    ModalLink
  },
  inject: [ 'store' ],
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  computed: {
    bomItem () {
      return {
        boms: [ this.item ]
      }
    },
    targetUrl () {
      return `/dev-bom/fabric?id=${this.item.bompartId}`
    }
  },
  methods: {
    async onRemoveFavoriteClick () {
      const isFavorite = !this.item.isFavorite
      const bomId = this.item.bompartId
      try {
        await this.store.devBomApi.toggleFavoriteBom({
          bomId,
          isFavorite: isFavorite
        })

        this.store.favoriteDevBoms.fetch()
      } catch (error) {
        alert('There was an error removing this BOM from your favorites.')
      }
    }
  }
}
</script>
