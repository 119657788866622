import Vue from 'vue'

const emptyResponse = {
  BOMSearchMetadata: {
    sections: {},
    BOMResults: []
  }
}

export default (store) => {
  return new Vue({
    data: {
      lockFlag: false,
      lockedBOM: [],
      unlockedBOM: [],
      carryoverBOM: [],
      retriggeredBoms: [],
      gridData: ''
    },
    computed: {
      allSelected () {
        return this.selectedCount === this.count
      },
      bomIds () {
        return this.rawBoms.map((bom) => {
          return bom.bompartId
        })
      },
      bomsSorted () {
        return Array.from(this.rawBoms).sort((a, b) => {
          return a.colorwayName.localeCompare(b.colorwayName)
        })
      },
      count () {
        return this.rawBoms.length
      },
      filters: {
        get () {
          const { selectedBoms } = store.router.hash
          if (selectedBoms) {
            return JSON.parse(selectedBoms)
          }
          return this.bomIds
        },
        set (value) {
          const selectedBoms = JSON.stringify(value)
          store.router.setHash({
            selectedBoms
          })
        }
      },
      isDevMode () {
        return store.router.query.mode === 'dev'
      },
      isBomMode () {
        return store.router.query.mode === 'bom'
      },
      isSearchMode () {
        return store.router.query.mode === 'search'
      },
      rawBoms () {
        return this.rawResults.BOMSearchMetadata.BOMResults
      },
      rawSections () {
        return this.rawResults.BOMSearchMetadata.sections
      },
      ready () {
        return this.$asyncComputed.rawResults.success
      },
      // This is used to sort components by section in the prescribed order
      sectionKeySortIndices () {
        return store.codes.sectionKeys.reduce((acc, section, index) => {
          acc[section] = index
          return acc
        }, {})
      },
      sections () {
        const { sectionKeys } = store.codes
        return sectionKeys.map((sectionKey) => {
          const section = this.rawSections[sectionKey]
          if (section) {
            return {
              ...section,
              sectionKey
            }
          }
          const emptySection = {
            components: []
          }
          return emptySection
        })
      },
      selectedBoms () {
        return this.bomsSorted.filter((bom) => {
          return this.filters.includes(bom.bompartId)
        })
      },
      selectedCount () {
        return this.selectedBoms.length
      },
      selectedLineItems () {
        const map = this.sections.reduce((acc, section) => {
          section.components.forEach((component) => {
            const key = `${section.sectionKey}__${component.component}__${component.componentLocation}`
            component.lineItemDetails.forEach((rawLineItem) => {
              rawLineItem.bompartInfo.forEach((bompart) => {
                // We can skip LineItems that aren't selected
                if (!this.filters.includes(bompart.bompartId)) {
                  return
                }

                const lineItem = {
                  ...rawLineItem,
                  bompartInfo: undefined,
                  bomlinkId: bompart.bomlinkId,
                  bompartId: bompart.bompartId,
                  prodType: bompart.prodType,
                  seasonalLookId: bompart.seasonalLookId
                }

                const { bompartId } = bompart
                if (acc.has(key)) {
                  const rootRow = acc.get(key)
                  if (rootRow[bompartId]) {
                    rootRow[bompartId].push(lineItem)
                  } else {
                    rootRow[bompartId] = [ lineItem ]
                  }
                  acc.set(key, rootRow)
                } else {
                  const rootRow = {
                    ...component,
                    section: section.sectionKey,
                    lineItemDetails: undefined
                  }
                  rootRow[bompartId] = [ lineItem ]
                  acc.set(key, rootRow)
                }
              })
            })
          })
          return acc
        }, new Map())
        const array = Array.from(map.values())
        array.sort(this.sortBySectionAndComponent)
        return Vue.observable(array)
      },
      updateMRPLock: {
        get () {
          return this.lockFlag
        },
        set (value) {
          this.lockFlag = value
        }

      }

    },
    asyncComputed: {
      devBomResults: {
        lazy: true,
        default: emptyResponse,
        async get () {

        }
      },
      bomResults: {
        lazy: true,
        default: emptyResponse,
        async get () {
          const { id } = store.router.query
          const url = `/api/bom/${id}`

          return store.api.get({
            url
          })
        }
      },
      searchResults: {
        lazy: true,
        default: emptyResponse,
        async get () {
          const params = store.router.query
          let query = params
          if (store.router.path === '/carry-over') {
            query = { ...params, forCopyCarryover: 'Y' }
          }
          const url = '/api/bom'
          return store.api.get({
            url,
            params: query
          })
        }
      },
      rawResults: {
        lazy: true,
        default: emptyResponse,
        async get () {
          if (this.isDevMode) {
            return this.devBomResults
          } else if (this.isBomMode) {
            return this.bomResults
          } else if (this.isSearchMode) {
            return this.searchResults
          }
          return emptyResponse
        }
      }
    },
    methods: {
      compareSections (lhs, rhs) {
        const lhsIndex = this.sectionKeySortIndices[lhs]
        const rhsIndex = this.sectionKeySortIndices[rhs]
        return lhsIndex - rhsIndex
      },
      deselectAll () {
        this.filters = []
      },
      selectAll () {
        this.filters = undefined
      },
      sortBySectionAndComponent (lhs, rhs) {
        const sectionResult = this.compareSections(lhs.section, rhs.section)
        if (sectionResult === 0) {
          const sortFunc = store.bomEditor.sortBySubsection(lhs.section)
          return sortFunc(lhs, rhs)
        }
        return sectionResult
      },
      // check which BOM's are locked
      gridLockCheck (id) {
        const mrpCheck = this.rawBoms.find(bom => {
          return bom.bompartId === id
        })
        if (mrpCheck.lscoMrpLock !== 'Y') {
          return false
        } else {
          return true
        }
      },
      editMrpLock (locked, unlocked) {
        this.lockedBOM = Array.from(locked)
        this.unlockedBOM = Array.from(unlocked)
        this.updateMRPLock = true
      },
      updateCarryover (carryover, retriggerBoms) {
        this.carryoverBOM = Array.from(carryover)
        this.retriggeredBoms = Array.from(retriggerBoms)
      },
      async updateBom () {
        const res = await store.bomEditorApi.updateMRPLock(this.lockedBOM, this.unlockedBOM)
        this.updateMRPLock = false
        return res
      },
      async carryoverBom () {
        const res = await store.bomEditorApi.updateCarryover(this.carryoverBOM, this.retriggeredBoms)
        return res
      },
      refresh () {
        if (this.isSearchMode) {
          this.$asyncComputed.searchResults.update()
        } else if (this.isBomMode) {
          this.$asyncComputed.bomResults.update()
        }
      },
      // check which BOM's are power core
      gridPowerCoreCheck (id) {
        const powerCheck = this.rawBoms.find(bom => {
          return bom.bompartId === id
        })
        if (powerCheck.powerCoreIndicator !== 'Y') {
          return false
        } else {
          return true
        }
      }

    }
  })
}
