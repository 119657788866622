<template>
  <div class="RadioGroup">
    <label
      v-for="option in options"
      :key="`${name}_${option.value}`"
    >
      <input
        v-model="value"
        type="radio"
        :name="name"
        :value="option.value"
      >
      {{ option.label }}
    </label>
  </div>
</template>

<script>
export default {
  props: {
    model: {
      type: Object,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    options: {
      type: Array,
      default () {
        return []
      }
    }
  },
  computed: {
    value: {
      get () {
        return this.model[this.name]
      },
      set (value) {
        this.model[this.name] = value
      }
    }
  }
}
</script>

<style lang="scss">
.RadioGroup {
  label {
    display: flex;
    align-items: center;
    cursor: pointer;
    color: $gray;
    font-size: 14px;
    text-transform: none;
  }
}
</style>
