import Vue from 'vue'

const bomReview = (store) => {
  return new Vue({
    data: {
      sectionToggles: {
        artwork: true,
        careLabel: true,
        embellishment: true,
        fabric: true,
        finish: true,
        other: true,
        pkgAndLabels: true,
        sundries: true,
        thread: true
      }
    },
    computed: {
      boms () {
        const { selectedBoms } = store.bomEditor
        const orderedBoms = selectedBoms.map((bom) => {
          const bomSections = this.visibleSections.map((sectionName) => {
            const section = store.bomEditor.sections[sectionName]
            if (!section) {
              return {
                sectionName,
                components: []
              }
            }
            const allComponents = section.components
            const bomComponents = allComponents.reduce((componentsAcc, component) => {
              const lineItems = component.lineItemDetails.filter((lineItem) => {
                return lineItem.bompartInfo.some((bompart) => {
                  return bompart.bompartId === bom.bompartId
                })
              })
              if (lineItems.length > 0) {
                componentsAcc.push({
                  ...component,
                  lineItemDetails: lineItems
                })
              }
              return componentsAcc
            }, [])
            return {
              sectionName,
              components: bomComponents
            }
          })
          const bomWithSections = {
            ...bom,
            sections: bomSections
          }
          return bomWithSections
        })
        return orderedBoms
      },
      visibleSections () {
        const keys = store.codes.sectionKeys
        return keys.reduce((acc, key) => {
          if (this.sectionToggles[key]) {
            return [...acc, key]
          }
          return acc
        }, [])
      }
    },
    methods: {
      toggleSection (section) {
        this.sectionToggles[section] = !this.sectionToggles[section]
      }
    }
  })
}

export default bomReview
