import { DateTime } from 'luxon'
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'

TimeAgo.addLocale(en)
const timeAgo = new TimeAgo('en-US')

export const ago = (timestamp) => {
  const dateTime = DateTime.fromISO(timestamp)
  return timeAgo.format(dateTime.toJSDate())
}
export const formatTimestamp = (timestamp) => {
  try {
    return timestamp.toString().split('T')[0]
  } catch (er) {
    return ''
  }
}
export const greeting = () => {
  const now = DateTime.local()
  if (now.hour < 12) {
    return 'Good morning'
  } else if (now.hour < 18) {
    return 'Good afternoon'
  } else if (now.hour < 24) {
    return 'Good evening'
  }
  return 'Welcome'
}

export const secondsToHuman = (seconds) => {
  if (seconds < 60) {
    const padded = seconds.toString().padStart(2, '0')
    return `0:${padded}`
  }
  const minutes = Math.floor(seconds / 60)
  const remainder = Math.round(seconds % 60)
  const paddedRemainder = remainder.toString().padStart(2, '0')
  return `${minutes}:${paddedRemainder}`
}

export default {
  ago,
  greeting,
  secondsToHuman,
  formatTimestamp
}
