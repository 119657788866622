import Vue from 'vue'

const chooseMaterialCode = (store) => {
  return new Vue({
    data: {
      showAdvancedSearch: false,
      brandedContent: '',
      chooseCodeResults: {
        results: [],
        totalResults: 0
      },
      fabricSubtype: '',
      fabricType: '',
      genericContent: '',
      pageSize: 25,
      skip: 0,
      state: 'idle',
      sundrySubtype: '',
      sundryType: ''
    },
    computed: {
      isEmpty () {
        return (
          this.state === 'done' &&
          this.chooseCodeResults.totalResults === 0
        )
      },
      isError () {
        return this.state === 'error'
      },
      isLoading () {
        return this.state === 'loading'
      }
    },
    methods: {
      addParam (params, name) {
        const isNumber = this[name] > 0
        const isString = (this[name] && this[name].length)
        if (isNumber || isString) {
          params[name] = this[name]
        }
      },
      async fetch () {
        try {
          this.state = 'loading'
          const params = {}
          this.addParam(params, 'fabricType')
          this.addParam(params, 'fabricSubtype')
          this.addParam(params, 'brandedContent')
          this.addParam(params, 'genericContent')
          this.addParam(params, 'sundryType')
          this.addParam(params, 'sundrySubtype')
          params.perPage = this.pageSize
          params.skip = this.skip
          params.sortBy = 'materialName'

          const url = '/api/search'
          const response = await store.api.get({
            url,
            params
          })

          this.chooseCodeResults = response
          this.state = 'done'
        } catch (error) {
          this.state = 'error'
        }
      },
      gotoNextPage () {
        if (this.skip + this.pageSize <= this.chooseCodeResults.totalResults) {
          this.skip = this.skip + this.pageSize
          this.fetch()
        }
      },
      gotoPreviousPage () {
        if (this.skip - this.pageSize >= 0) {
          this.skip = this.skip - this.pageSize
          this.fetch()
        }
      },
      reset () {
        this.showAdvancedSearch = false
        this.fabricSubtype = ''
        this.fabricType = ''
        this.sundrySubtype = ''
        this.sundryType = ''
        this.genericContent = ''
        this.brandedContent = ''
        this.error = false
        this.skip = 0
        this.state = 'idle'
        this.chooseCodeResults = {
          results: [],
          totalResults: 0
        }
      }
    }
  })
}

export default chooseMaterialCode
