import Vue from 'vue'

const pc9Api = (store) => {
  return new Vue({
    methods: {
      async addLineItem ({ bomIds, lineItem, popups }) {
        const url = `/api/bom/lineItems`
        const body = {
          bomIds,
          lineItem
        }
        return store.api.post({
          url,
          body,
          popups
        })
      },
      async removeLineItem ({ lineItemIds, popups }) {
        const url = '/api/bom/lineItems'
        const body = {
          lineItemIds
        }
        return store.api.delete({
          url,
          body,
          popups
        })
      },
      async removeMaterial ({ lineItemIds, popups }) {
        const url = '/api/bom/lineItems/materials'
        const body = {
          lineItemIds
        }
        return store.api.delete({
          url,
          body,
          popups
        })
      },
      removeSubsection ({ bomIds, section, component, componentLocation, popups }) {
        const url = `/api/bom/lineItems`
        const body = {
          bomIds,
          sections: [section],
          component,
          componentLocation
        }
        return store.api.delete({
          url,
          body,
          popups
        })
      },
      async updateBomComponent ({ bom, section, updates }) {
        const bompartId = bom.bompartId
        const bomlinkId = bom.bomlinkId
        const url = `/api/me/developmentBoms/${bompartId}/${section}/${bomlinkId}`
        const body = updates
        return store.api.put({
          url,
          body
        })
      },
      async updateLineItem ({ lineItemIds, lineItem, popups }) {
        const url = '/api/bom/lineItems'
        const body = {
          lineItemIds,
          lineItem
        }
        return store.api.put({
          url,
          body,
          popups
        })
      },
      async updateMrpLock ({ bomIds, lscoMrpLock }) {
        const url = '/api/bom/boms'
        const body = {
          bomIds,
          lscoMrpLock
        }

        return store.api.put({
          url,
          body
        })
      },
      async updateCarryover ({ bomIds, retriggeredBoms }) {
        const url = '/api/me/developmentBoms/copytocarryover'
        const body = {
          bompartIds: bomIds,
          retriggerBompartIds: retriggeredBoms
        }
        return store.api.post({
          url,
          body
        })
      },
      async updateComponent (lineItemIds, lineItem, popups) {
        const url = '/api/bom/lineItems'
        const body = {
          lineItemIds,
          lineItem
        }
        return store.api.put({
          url,
          body,
          popups
        })
      }
    }
  })
}

export default pc9Api
