import Vue from 'vue'
import NoMaterialsWarning from '../../components/AssignBom/NoMaterialsWarning.vue'

export default (store) => {
  return new Vue({
    data: {
      selectedSections: [],
      showErrors: false
    },
    computed: {
      isValid () {
        return this.selectedSections.length
      },
      allSelected () {
        return this.selectedSections.length === this.enabledSections.length
      },
      sectionOptions () {
        const { bomEditor, codes } = store
        const viewableOptions = codes.rbacSectionOptions

        return viewableOptions.map((option) => {
          const sectionStatus = bomEditor.sectionStatus(option.value)
          if (sectionStatus.hasMaterials) {
            return {
              ...option
            }
          } else if (
            !sectionStatus.hasMaterials &&
            sectionStatus.hasLineItems
          ) {
            return {
              ...option,
              icon: NoMaterialsWarning
            }
          } else {
            return {
              ...option,
              disabled: true,
              disabledTitle: `Development BOM section does not contain Component / Component Location or materials.`
            }
          }
        })
      },
      enabledSections () {
        return this.sectionOptions.filter(section => !section.disabled).map(section => section.value)
      }
    },
    methods: {
      reset () {
        this.deselectAll()
        this.showErrors = false
      },
      selectAll () {
        this.deselectAll()
        this.enabledSections.forEach(section => this.selectedSections.push(section))
      },
      deselectAll () {
        this.selectedSections = []
      }
    }
  })
}
