<template>
  <Scroller>
    <ul
      ref="list"
      class="FavoriteSeasonList"
      :class="classes"
    >
      <FavoriteSeasonItem
        v-for="season in store.favoriteSeasons.items"
        :key="season.seasonId"
        :season="season"
      />
    </ul>
  </Scroller>
</template>

<script>
import FavoriteSeasonItem from './FavoriteSeasonItem.vue'
import Scroller from '../Scroller.vue'
import Sortable from 'sortablejs'

export default {
  components: {
    FavoriteSeasonItem,
    Scroller
  },
  inject: [ 'store' ],
  data () {
    return {
      isSorting: false,
      sortable: null
    }
  },
  computed: {
    classes () {
      return {
        isSorting: this.isSorting
      }
    }
  },
  mounted () {
    const { favoriteSeasons } = this.store

    this.sortable = new Sortable(this.$refs.list, {
      animation: 150,
      forceFallback: true,
      handle: '.handle',
      onChoose: this.onSortChoose,
      onEnd: this.onSortEnd,
      onStart: this.onSortStart,
      onUnchoose: this.onSortUnchoose,
      store: {
        get: favoriteSeasons.getSortOrder,
        set: favoriteSeasons.setSortOrder
      }
    })
  },
  beforeDestroy () {
    this.sortable.destroy()
  },
  methods: {
    onSortChoose () {
      this.isSorting = true
    },
    onSortEnd (event) {
      this.isSorting = false
    },
    onSortStart () {
      this.isSorting = true
    },
    onSortUnchoose () {
      this.isSorting = false
    }
  }
}
</script>

<style lang="scss">
.FavoriteSeasonList {
  display: block;
  width: 100%;
  padding: 8px 16px;
  color: $gray;

  &.isSorting {
    * {
      cursor: grabbing !important;
    }

    .contextMenu,
    .handle {
      opacity: 0;
    }
  }
}
</style>
