const sort = {
  thread: 1,
  embroidery: 2,
  embroideryThread: 3,
  repairThread: 4,
  miscellaneous: 5,
  handStitching: 6,
  heavyStitching: 7
}

const components = Object.keys(sort)

const locations = [
  'allOver',
  'arcuate',
  'backNeck',
  'beltLoop',
  'button',
  'button1',
  'button2',
  'button3',
  'centerBack',
  'centerFront',
  'cfPlacket',
  'chest',
  'collar',
  'collarBand',
  'contrastButton',
  'cuff',
  'embroidery',
  'fly',
  'front',
  'gusset',
  'hem',
  'hookAndEye',
  'interior',
  'internal',
  'internalWaistband',
  'label',
  'leftChest',
  'leftSideSeam',
  'loop',
  'mainBody',
  'neck',
  'patch',
  'placket',
  'pocket',
  'position1',
  'position2',
  'position3',
  'position4',
  'position5',
  'position6',
  'position7',
  'position8',
  'position9',
  'position10',
  'position11',
  'position12',
  'position13',
  'position14',
  'position15',
  'position16',
  'position17',
  'position18',
  'position19',
  'position20',
  'shell',
  'shoulder',
  'side',
  'sleeve',
  'waistband',
  'yoke'
]

export default {
  components,
  locations,
  sort
}
