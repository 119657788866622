import debounce from 'debounce-promise'
import Vue from 'vue'

const devBoms = (store) => {
  const fetchItems = debounce(async function (params) {
    const response = await store.api.get({
      url: '/api/me/developmentBoms',
      params
    })
    return response
  }, 300)

  const emptyResponse = {
    results: [],
    totalResults: 0
  }

  return new Vue({
    data: {
      noteEditToggles: {},
      perPage: 10,
      selected: {}
    },
    computed: {
      allSelected: {
        get () {
          if (this.items.length === 0) {
            return false
          }
          return this.items.every((item) => {
            return this.selected[item.bompartId]
          })
        },
        set (value) {
          return this.items.forEach((item) => {
            this.setItemSelection(item.bompartId, value)
          })
        }
      },
      anySelected () {
        return Object.values(this.selected).some(value => value)
      },
      currentPage: {
        get () {
          return Number(store.router.query.currentPage) || 1
        },
        set (value) {
          store.router.setQuery({ currentPage: value })
        }
      },
      hasParams () {
        return Object.keys(this.params).length
      },
      isFavorites () {
        return this.showing === 'favorites'
      },
      isReady () {
        return this.$asyncComputed.response.success
      },
      params () {
        const params = {}
        if (this.title && this.title.length) {
          params.title = this.title
          params.note = this.title
          params.authorName = this.title
        }
        if (this.isFavorites) {
          params.isFavorite = this.isFavorites
        }
        params.showDeleted = false

        params.perPage = this.perPage
        params.sortBy = this.sortBy
        params.skip = this.skip
        return params
      },
      selectedBoms () {
        return this.selectedIds.map((id) => {
          return this.find(id)
        })
      },
      selectedIds () {
        return Object.keys(this.selected).filter((key) => {
          return this.selected[key]
        }, [])
      },
      showing: {
        get () {
          return store.router.query.showing || 'all'
        },
        set (value) {
          this.currentPage = 1
          store.router.setQuery({ showing: value })
        }
      },
      skip () {
        return this.perPage * (this.currentPage - 1)
      },
      sortBy: {
        get () {
          return store.router.query.sortBy || 'modifyStamp DESC'
        },
        set (value) {
          store.router.setQuery({ sortBy: value })
        }
      },
      title: {
        get () {
          return store.router.query.title
        },
        set (value) {
          this.currentPage = 1
          store.router.setQuery({ title: value })
        }
      }
    },
    asyncComputed: {
      items: {
        default: [],
        lazy: true,
        async get () {
          return this.response.results
        }
      },
      response: {
        default: emptyResponse,
        lazy: true,
        async get () {
          if (this.hasParams) {
            return fetchItems(this.params)
          }
          return emptyResponse
        }
      },
      totalItemsCount: {
        default: 0,
        lazy: true,
        async get () {
          return this.response.totalResults
        }
      }
    },
    methods: {
      clearSelection () {
        this.selected = {}
      },
      fetch () {
        return this.$asyncComputed.response.update()
      },
      find (bomId) {
        return this.items.find((bom) => {
          return bom.bompartId === bomId
        })
      },
      setNoteEdit (bompartId, value) {
        Vue.set(this.noteEditToggles, bompartId, value)
      },
      setItemSelection (bompartId, value) {
        Vue.set(this.selected, bompartId, value)
      }
    }
  })
}

export default devBoms
