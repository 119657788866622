import Vue from 'vue'

const emptyResponse = {
  results: [],
  sections: {},
  totalResults: 0
}

const copyFromDevBom = (store) => {
  return new Vue({
    data: {
      bomId: ''
    },
    asyncComputed: {
      bom: {
        lazy: true,
        default: emptyResponse,
        async get () {
          if (this.bomId && this.bomId.length) {
            const response = await store.devBomApi.find({ bomId: this.bomId })
            return response.BOMSearchMetadata
          }
          return emptyResponse
        }
      }
    }
  })
}

export default copyFromDevBom
