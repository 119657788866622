import Vue from 'vue'

const bomEditorApi = (store) => {
  return new Vue({
    computed: {
      api () {
        if (store.router.path === '/bom-grid') {
          if (store.bomGrid.isDevMode) {
            return store.devBomApi
          }
          return store.pc9Api
        }

        if (store.bomEditor.isPC9Search || store.bomEditor.isPC9Edit || store.router.path === '/edit-lock' || store.router.path === '/carry-over') {
          return store.pc9Api
        } else if (store.bomEditor.isDevBom) {
          return store.devBomApi
        }
        return {}
      }
    },
    methods: {
      async addLineItem ({ bomIds, lineItem, popups }) {
        const response = await this.api.addLineItem({
          bomIds,
          lineItem,
          popups
        })
        store.clickStream.obEvent(
          'Bom Line Item',
          'Create Bom Line Item',
          response.url,
          'POST',
          response.status,
          bomIds
        )
        if (Array.isArray(response)) {
          return response
        }
        return [ response ]
      },
      async updateLineItem ({
        bompartId,
        lineItemIds,
        lineItem,
        section,
        popups
      }) {
        store.clickStream.obEvent(
          'Bom Line Item',
          'Update Bom Line Item'
        )
        return this.api.updateLineItem({
          bompartId,
          lineItemIds,
          lineItem,
          section,
          popups
        })
      },
      async removeLineItem ({ lineItemIds, description = 'Line Item' }) {
        const popups = {
          workingText: `Removing ${description}…`,
          successText: `${description} was removed.`,
          errorText: `There was an error removing ${description}.`
        }
        store.clickStream.obEvent(
          'Bom Line Item',
          'Remove Bom Line Item'
        )
        return this.api.removeLineItem({ lineItemIds, popups })
      },
      async removeMaterial ({ lineItemIds, materialDescription = 'Material' }) {
        const popups = {
          errorText: `There was an error removing ${materialDescription}.`
        }
        return this.api.removeMaterial({ lineItemIds, popups })
      },
      removeSubsection ({
        bomIds,
        section,
        component,
        componentLocation,
        description
      }) {
        const popups = {
          workingText: `Removing ${description}`,
          successText: `${description} was removed.`,
          errorText: `There was an error removing ${description}`
        }

        // HACK: We were never given an endpoint on the DevBom API
        // Defaulting to the pc9Api for now. Hopefully it continues to work?
        return store.pc9Api.removeSubsection({
          bomIds,
          popups,
          section,
          component,
          componentLocation
        })
      },
      updateMRPLock (lockedBOM, unlockedBOM, updatedLock) {
        let lscoMrpLock = ''
        let bomIds = []
        let res = {}
        if (Array.isArray(lockedBOM) && lockedBOM.length) {
          lscoMrpLock = 'Y'
          bomIds = lockedBOM
          res = this.api.updateMrpLock({ bomIds, lscoMrpLock })
        } if (Array.isArray(unlockedBOM) && unlockedBOM.length) {
          lscoMrpLock = null
          bomIds = unlockedBOM
          res = this.api.updateMrpLock({ bomIds, lscoMrpLock })
        }
        return res
      },
      updateCarryover (carryover, retriggeredBoms) {
        let bomIds = []
        let res = {}
        if ((Array.isArray(carryover) && carryover.length) || (Array.isArray(retriggeredBoms) && retriggeredBoms.length)) {
          bomIds = carryover
          res = this.api.updateCarryover({ bomIds, retriggeredBoms })
        }
        return res
      },
      async updateComponent (
        lineItemIds,
        lineItem,
        popups
      ) {
        store.clickStream.obEvent(
          'Bom Line Item',
          'Update componet and Sub Location'
        )
        return this.api.updateComponent(
          lineItemIds,
          lineItem,
          popups
        )
      }
    }
  })
}

export default bomEditorApi
