export default [{
  value: 'standard',
  label: 'Standard'
}, {
  value: 'flaGap',
  label: 'FLA Gaps'
}, {
  value: 'lawGap',
  label: 'LAW Gaps'
}, {
  value: 'rflaGap',
  label: 'RFLA Gaps'
}, {
  value: 'graphicTeeTrack',
  label: 'Graphic Tee Track'
}, {
  value: 'srt',
  label: 'SRT'
}, {
  value: 'fastTrack',
  label: 'Fast Track'
}, {
  value: 'lateAdd',
  label: 'Late Add'
}]
