<template>
  <div
    v-if="store.alerts.isShowing"
    class="Alerts"
  >
    <div
      v-for="error in errors"
      :key="error.id"
      class="error"
    >
      <span>{{ error.message }}</span>
      <a @click="closeError(error.id)">
        <CloseIcon />
      </a>
    </div>
    <div
      v-for="warning in warnings"
      :key="warning.id"
      class="warning"
    >
      <span>{{ warning.message }}</span>
      <CloseIcon @click="closeWarning(warning.id)" />
    </div>
  </div>
</template>

<script>
import CloseIcon from './icons/CloseIcon.vue'

export default {
  inject: [ 'store' ],
  components: {
    CloseIcon
  },
  computed: {
    errors () {
      return this.store.alerts.errors
    },
    warnings () {
      return this.store.alerts.warnings
    }
  },
  methods: {
    closeError (id) {
      this.store.alerts.removeError(id)
    },
    closeWarning (id) {
      this.store.alerts.removeWarning(id)
    }
  }
}
</script>

<style lang="scss">
.Alerts {
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  top: 0;
  width: 100vw;
  height: 0;

  .error {
    border: 1px solid $red;
    background-color: lighten($red, 25%);
  }

  .warning {
    border: 1px solid $yellow;
    background-color: lighten($yellow, 20%);
  }

  .error,
  .warning {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2.4rem;
    padding: 0.5rem 1rem;
    color: $gray;
    border-radius: 3px;
    box-shadow: 1px 1px 3px $gray-sec;
    font-size: 14px;
    font-weight: bold;

    svg {
      width: 1.25rem;
      height: 1.25rem;
      margin-left: 1rem;
      cursor: pointer;

      path {
        stroke: $gray;
      }
    }
  }
}
</style>
