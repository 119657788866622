const sort = {
  matchbook: 1,
  attachment: 2,
  flasher: 3,
  sizeSticker: 4,
  foldingVAS: 5,
  polybagVAS: 6,
  uvmFlasher: 7,
  uvmMatchbook: 8,
  uvmSticker: 9,
  attributeSticker: 10,
  envelope: 11,
  mainHangtag: 12,
  featureHangtag: 13,
  featureHangtag2: 14,
  featureHangtag3: 15,
  featureHangtag4: 16,
  disclaimerHangtag: 17,
  disclaimerHangtag2: 18,
  disclaimerHangtag3: 19,
  performanceHangtag: 20,
  capsuleHangtag: 21,
  otherHangtag: 22,
  uvmHangtag: 23,
  miscellaneous: 24
}

const components = Object.keys(sort)

const locations = []

export default {
  components,
  locations,
  sort
}
