<template>
  <div
    v-if="!store.addFavoriteSeason.error"
    class="AddFavoriteSeason"
  >
    <SeasonSelector
      :model="store.addFavoriteSeason"
    />
    <div class="seasonFooter">
      <div>
        <label>Selected Season</label>
        <span
          v-if="store.addFavoriteSeason.isEmpty"
          class="empty"
        >
          Select your season above…
        </span>
        <span v-else>
          {{ store.addFavoriteSeason.selectedText }}
          <Checkmark v-if="store.addFavoriteSeason.isValid" />
        </span>
      </div>
      <button
        class="button push"
        :disabled="!store.addFavoriteSeason.isValid"
        @click="onAddClick"
      >
        Add Season
      </button>
    </div>
  </div>
  <div
    v-else
    class="error"
  >
    <h4>We're sorry, the season options failed to load.</h4>
    <h4>Please try again later.</h4>
  </div>
</template>

<script>
import Checkmark from '../icons/Checkmark.vue'
import SeasonSelector from '../SeasonSelector.vue'

export default {
  components: {
    Checkmark,
    SeasonSelector
  },
  inject: ['store'],
  mounted () {
    this.store.addFavoriteSeason.reset()
  },
  methods: {
    async onAddClick () {
      await this.store.addFavoriteSeason.add()
      this.store.modals.hide()
    }
  }
}
</script>

<style lang="scss">
.AddFavoriteSeason {
  min-width: 760px;
  min-height: 388px;

  .seasonFooter {
    display: flex;
    margin: 3rem 0 0;
    padding-top: 4rem;
    border-top: 1px solid $gray-lines;
    justify-content: space-between;

    svg {
      margin-left: 3px;
    }

    .empty {
      font-style: italic;
      color: $gray-sec;
    }
  }
}
</style>
