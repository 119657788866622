<template>
  <div
    class="ContextMenuButton"
    :class="{ open: isOpen }"
    @click="onClick"
  >
    <ContextMenuTriggerIcon />
  </div>
</template>

<script>
import ContextMenuTriggerIcon from './icons/ContextMenuTriggerIcon.vue'

export default {
  components: {
    ContextMenuTriggerIcon
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false
    },
    onClick: {
      type: Function,
      default: () => {}
    }
  }
}
</script>

<style lang="scss">
.ContextMenuButton {
  display: flex;
  width: 32px;
  height: 28px;
  transition: $anim-trans-base;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 4px;
  box-sizing: border-box;

  svg {
    transition: $anim-trans-base;
  }

  &:hover,
  &.open {
    border: 1px solid $blue;

    svg {
      circle {
        fill: $blue;
      }
    }
  }
}
</style>
