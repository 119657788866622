const sort = {
  careLabel1: 1,
  careLabel2: 2,
  careLabel3: 3,
  careLabel4: 4,
  careLabel5: 5,
  careLabel5Japan: 6,
  careLabel5Lse: 7,
  careLabel6: 8,
  careLabel7: 9,
  careLabel8: 10,
  careLabel9: 11,
  careLabelMexico: 12,
  careLabelQuad: 13,
  canadaFillLabel: 14,
  careLabel: 15,
  miscellaneous: 16
}

const components = Object.keys(sort)

const locations = [
  'side',
  'waistband',
  'inside'
]

export default {
  components,
  locations,
  sort
}
