<template>
  <portal to="modal">
    <div
      v-if="isShowing"
      v-hotkey="keymap"
      class="Modal"
      @click="onClick"
    >
      <div
        class="modalFrame"
        :class="name"
      >
        <h3 class="modalHeader">
          <span class="title">
            {{ title }}
          </span>
          <a @click="onCloseClick">
            <CloseIcon />
          </a>
        </h3>

        <div class="modalContent">
          <slot />
        </div>
      </div>
    </div>
  </portal>
</template>

<script>
import CloseIcon from './icons/CloseIcon.vue'

export default {
  components: {
    CloseIcon
  },
  inject: [ 'store' ],
  props: {
    name: {
      type: String,
      required: true
    },
    onClose: {
      type: Function,
      default: () => {}
    },
    title: {
      type: String,
      default: ''
    }
  },
  computed: {
    keymap () {
      return {
        scope: 'modal',
        keys: {
          esc: this.close
        }
      }
    },
    isShowing () {
      return this.store.modals.isShowing(this.name)
    }
  },
  methods: {
    close () {
      this.store.modals.hide()
      this.onClose()
    },
    onClick (event) {
      if (event.target.className === 'Modal') {
        this.close()
      }
    },
    onCloseClick () {
      this.close()
    }
  }
}
</script>

<style lang="scss">
/* stylelint-disable no-descending-specificity */
.Modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;

  .modalFrame {
    background-color: white;
    border-radius: 4px;
    position: relative;

    .modalHeader {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 1.7rem;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      background: $page-background;
      box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1), 0 1px 0 rgba(0, 0, 0, 0.05);
    }

    .highlight {
      color: $blue;
    }

    .action-block {
      text-align: right;
      margin-top: 3rem;

      a {
        color: $gray;

        &:hover {
          text-decoration: underline;
        }
      }

      button {
        margin-left: 1.5rem;
      }
    }

    input,
    select {
      font-size: 1.4rem;
    }

    h5 {
      font-weight: 500;
      display: block;
      font-size: 1.4rem;
      color: $gray;
      margin: 0 0 1rem 0;
    }

    .flex-row {
      display: flex;
      align-items: center;
      margin: 2rem 0;

      label {
        display: flex;
        align-items: center;
        font-size: 1.4rem;
        text-transform: none;
        letter-spacing: normal;
        width: 20rem;
        padding-top: 0.7rem;
      }
    }

    .checkbox-list {
      &.radios {
        ul {
          li {
            width: 19%;
          }
        }
      }

      ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;

        li {
          width: 48%;
        }
      }
    }
  }

  .modalContent {
    padding: 4rem;
  }

  &.full {
    width: 100%;
    height: 100%;

    .modal-body {
      padding: 0;
      height: 100%;
      overflow-x: hidden;
      overflow-y: auto;
    }

    .modal-hd {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      z-index: 9;
    }
  }

  .modal-hd {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    display: flex;
    align-items: center;
    padding: 1.7rem;
    background: $page-background;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1), 0 1px 0 rgba(0, 0, 0, 0.05);

    a {
      color: $gray;
      margin-left: 3rem;
      padding: 0.2rem;
      transition: $anim-trans-base;

      &:hover {
        opacity: 0.7;
      }

      svg {
        margin-left: 0.5rem;
      }
    }

    button {
      margin-left: auto;

      svg path {
        transition: $anim-trans-base;
      }

      &:hover {
        svg path {
          stroke: #687179;
        }
      }
    }
  }

  .modal-body {
    padding: 4rem;

    p {
      margin: 0 0 2rem;
    }
  }
}
</style>
