<template>
  <div
    id="app"
    @click="onClick"
  >
    <div v-if="isSignInFlow">
      <router-view />
    </div>
    <Spinner
      v-else-if="isLoading"
      class="stretch"
    />
    <div v-else-if="isSignedIn">
      <main>
        <Header />

        <div class="pages">
          <router-view />
        </div>

        <Footer />
      </main>

      <portal-target name="modal" />
      <portal-target
        name="context-menu"
        class="context-menu-portal-target"
      />
      <portal-target
        name="auto-complete"
        class="auto-complete-portal-target"
      />
      <ConfirmationDialog />
      <Alerts />
      <InfoAlert />
    </div>
    <div v-else-if="isTokenUnauthorized">
      {{ showUnauthorizedError() }}
    </div>
    <div v-else>
      {{ showUnknownError() }}
    </div>
  </div>
</template>

<script>
import Alerts from './components/Alerts.vue'
import ConfirmationDialog from './components/ConfirmationDialog.vue'
import InfoAlert from './components/InfoAlert.vue'
import Footer from './components/Footer.vue'
import Header from './components/Header.vue'
import Spinner from './components/Spinner.vue'

export default {
  components: {
    Alerts,
    ConfirmationDialog,
    Footer,
    Header,
    Spinner,
    InfoAlert
  },
  inject: ['store'],
  computed: {
    isLoading () {
      return (
        this.store.seasons.isLoading
      )
    },
    isSignedIn () {
      return (
        this.store.auth.hasValidTokens() &&
        this.store.seasons.isReady
      )
    },
    isSignInFlow () {
      return (
        this.store.router.path === '/sign-in' ||
        this.store.router.path === '/auth-callback'
      )
    },
    isTokenUnauthorized () {
      return this.store.seasons.isUnauthorized
    }
  },
  created () {
    window.addEventListener('scroll', this.onScroll)
  },
  destroyed () {
    window.removeEventListener('scroll', this.onScroll)
  },
  methods: {
    onClick (event) {
      this.store.contextMenu.hide()
      this.store.lookups.hide()
    },
    onScroll () {
      this.store.contextMenu.hide()
      this.store.lookups.hide()
    },
    showUnauthorizedError () {
      this.$router.replace({
        path: '/auth-callback',
        query: {
          error: 'ACCESS_TOKEN_UNAUTHORIZED'
        }
      })
    },
    showUnknownError () {
      this.$router.replace({
        path: '/auth-callback',
        query: {
          error: 'UNKNOWN_ERROR'
        }
      })
    }
  }
}
</script>

<style lang="scss">
@import "~ag-grid-community/dist/styles/ag-grid.css";
@import "~ag-grid-community/dist/styles/ag-theme-balham.css";

#app {
  overflow: hidden;

  main {
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: flex-start;

    .pages {
      height: 100%;
      flex-shrink: 2;
      flex-grow: 2;
      overflow-y: hidden;
    }
  }

  main.loading {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
