import { queryString } from '../util/url.js'
import Vue from 'vue'
import whitespaceToCommas from '../util/whitespaceToCommas.js'

const quickBomSearch = (store) => {
  return new Vue({
    data: {
      query: ''
    },
    computed: {
      seasonId: {
        get () {
          if (store.session.seasonId) {
            return store.session.seasonId
          }

          if (
            store.favoriteSeasons.isReady &&
            !store.favoriteSeasons.isEmpty
          ) {
            return store.favoriteSeasons.items[0].seasonId
          }

          return store.seasons.items[0].seasonId
        },
        set (value) {
          store.session.seasonId = value
        }
      },
      seasonOptions () {
        if (store.favoriteSeasons.isEmpty) {
          return store.seasons.items
        }

        if (store.favoriteSeasons.includes(this.seasonId)) {
          return store.favoriteSeasons.items
        }

        const extraSeason = store.seasons.find(this.seasonId)

        return [extraSeason, ...store.favoriteSeasons.items]
      }
    },
    methods: {
      async goToSearch () {
        const PC9Code = whitespaceToCommas(this.query)

        const params = {
          seasonId: this.seasonId,
          PC9Code
        }

        const qs = queryString(params)

        const url = `/search-results?${qs}`
        store.router.push(url)
      }
    }
  })
}

export default quickBomSearch
