<template>
  <a @click="onClick">
    <slot />
  </a>
</template>

<script>
export default {
  inject: [ 'store' ],
  props: {
    name: {
      type: String,
      required: true
    },
    params: {
      type: Object,
      default: () => { return {} }
    }
  },
  methods: {
    onClick (event) {
      this.store.modals.show(this.name, this.params)
    }
  }
}
</script>
