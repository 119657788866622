import localeCompare from '../../util/localeCompare.js'

const all = [{
  value: 1,
  label: 'Acetate'
}, {
  value: 2,
  label: 'Acrylic'
}, {
  value: 3,
  label: 'Cotton'
}, {
  value: 4,
  label: 'Elastane'
}, {
  value: 5,
  label: 'Lambswool'
}, {
  value: 6,
  label: 'Linen'
}, {
  value: 9,
  label: 'Modacrylic'
}, {
  value: 11,
  label: 'Polyester'
}, {
  value: 12,
  label: 'Polyurethane'
}, {
  value: 13,
  label: 'Polyamide'
}, {
  value: 14,
  label: 'Ramie'
}, {
  value: 16,
  label: 'Silk'
}, {
  value: 18,
  label: 'Polyethylene'
}, {
  value: 19,
  label: 'Tussah Silk'
}, {
  value: 20,
  label: 'Viscose'
}, {
  value: 21,
  label: 'Wool'
}, {
  value: 22,
  label: 'Other Fibers'
}, {
  value: 23,
  label: 'Mohair'
}, {
  value: 28,
  label: 'Flax'
}, {
  value: 29,
  label: 'Hemp'
}, {
  value: 32,
  label: 'Lyocell'
}, {
  value: 34,
  label: 'Alpaca'
}, {
  value: 35,
  label: 'Angora'
}, {
  value: 36,
  label: 'Cashmere'
}, {
  value: 39,
  label: 'Leather - Buffalo [HIDDEN]'
}, {
  value: 41,
  label: 'Leather - Goat [HIDDEN]'
}, {
  value: 42,
  label: 'Leather - Pig [HIDDEN]'
}, {
  value: 44,
  label: 'Bamboo'
}, {
  value: 45,
  label: 'Leather - Sheep [HIDDEN]'
}, {
  value: 47,
  label: 'Metallic Fiber'
}, {
  value: 48,
  label: 'Modal'
}, {
  value: 57,
  label: 'Leather - Horse [HIDDEN]'
}, {
  value: 59,
  label: 'Elastolefin'
}, {
  value: 61,
  label: 'Elastomultiester'
}, {
  value: 62,
  label: 'Ethylene Acrylic Acid'
}, {
  value: 63,
  label: 'Polyolefin'
}, {
  value: 81,
  label: 'Cupro'
}, {
  value: 83,
  label: 'Eri Silk'
}, {
  value: 84,
  label: 'Triexta'
}, {
  value: 85,
  label: 'Mulberry Silk'
}, {
  value: 86,
  label: 'Polypropylene'
}, {
  value: 87,
  label: 'Rabbit Hair'
}, {
  value: 89,
  label: 'Paper'
}]

export default all.sort((lhs, rhs) => {
  return localeCompare(lhs.label, rhs.label)
})
