import Vue from 'vue'

const defaultOptions = {
  brand: [],
  gender: [],
  season: [],
  type: [],
  year: [],
  yearsWithout2020: []
}

const seasonSelector = (store) => {
  return new Vue({
    computed: {
      brandOptions () {
        return this.buildOptions('brand')
      },
      genderOptions () {
        return this.buildOptions('gender')
      },
      isLoading () {
        return this.$asyncComputed.options.updating
      },
      seasonOptions () {
        return this.buildOptions('season').sort((lhs, rhs) => {
          return lhs.label.localeCompare(rhs.label)
        })
      },
      typeOptions () {
        return this.buildOptions('type')
      },
      yearOptions () {
        return this.buildYearOptions('2020')
      },
      yearOptionsWith2020 () {
        return this.buildYearOptions()
      }
    },
    asyncComputed: {
      options: {
        default: defaultOptions,
        lazy: true,
        async get () {
          const url = '/api/seasons/options'
          const options = await store.api.get({ url })
          const yearsWithout2020 = options.Year.filter((year) => {
            return year !== '2020'
          })

          return {
            brand: options.Brand,
            gender: options.Gender,
            season: options.Season,
            type: options.Type,
            year: options.Year,
            yearsWithout2020: yearsWithout2020
          }
        }
      }
    },
    methods: {
      buildOptions (key) {
        const options = this.options[key].map((value) => {
          const label = store.codes.seasons[key][value] || value
          return {
            value,
            label
          }
        })
        return options.sort((lhs, rhs) => {
          return lhs.label.localeCompare(rhs.label)
        })
      },
      buildYearOptions (year) {
        if (this.options) {
          let options
          if (year) {
            options = this.options[`yearsWithout${year}`].map((year) => {
              return {
                value: year,
                label: year
              }
            })
          } else {
            options = this.options.year.map((year) => {
              return {
                value: year,
                label: year
              }
            })
          }
          return options.sort((lhs, rhs) => {
            return rhs.label.localeCompare(lhs.label)
          })
        }
        return []
      }
    }
  })
}

export default seasonSelector
