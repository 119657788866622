import Vue from 'vue'

const createDevBomFromScratch = (store) => {
  return new Vue({
    data: {
      note: '',
      state: 'idle',
      title: ''
    },
    computed: {
      isError () {
        return this.state === 'error'
      },
      isLoading () {
        return this.state === 'loading'
      }
    },
    methods: {
      async create () {
        try {
          this.state = 'loading'
          const url = '/api/me/developmentBoms'
          const body = {
            isFavorite: false,
            note: this.note,
            title: 'Untitled'
          }
          if (this.title.length) {
            body.title = this.title
          }

          const response = await store.api.post({
            url,
            body
          })
          this.state = 'done'
          return response
        } catch (error) {
          this.state = 'error'
        }
      },
      reset () {
        this.title = ''
        this.note = ''
      }
    }
  })
}

export default createDevBomFromScratch
