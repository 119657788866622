<template>
  <div
    class="Scroller"
    @scroll.passive="onScroll"
  >
    <slot />
  </div>
</template>

<script>
export default {
  inject: [ 'store' ],
  methods: {
    onScroll (event) {
      // HACK: Don't close the filter list in the BomEditor onScroll!
      if (this.store.contextMenu.currentId !== 'BomResultsMenu') {
        this.store.contextMenu.hide()
      }
      this.store.lookups.hide()
    }
  }
}
</script>

<style lang="scss">
.Scroller {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 4rem;
  padding-bottom: 8rem;
  overflow-y: overlay;
  overflow-x: hidden;
}
</style>
