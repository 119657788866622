<template>
  <div class="DevBomConfirmShare">
    <div>
      <label>To</label>
      <UserLookup
        :model="user"
        name="shareUser"
      />
    </div>
    <div class="action-block">
      <button
        class="button cancel"
        @click="onCancelClick"
      >
        Cancel
      </button>
      <button
        class="button"
        @click="onConfirmClick"
      >
        Share
      </button>
    </div>
  </div>
</template>

<script>
import UserLookup from '../BomSearch/UserLookup.vue'
export default {
  components: {
    UserLookup
  },
  inject: [ 'store' ],
  data () {
    return {
      user: {
        shareUser: ''
      }
    }
  },
  computed: {
    bomIds () {
      return this.boms.map((bom) => {
        return bom.bompartId
      })
    },
    boms () {
      return this.store.modals.params.boms || []
    }
  },
  methods: {
    onCancelClick () {
      this.store.modals.hide()
    },
    async onConfirmClick () {
      const params = this.bomIds.map((id) => {
        return {
          id,
          sections: this.store.codes.sectionKeys,
          shareWith: this.user.shareUser
        }
      })
      this.store.devBomApi.share(params)
      this.store.modals.hide()
    }
  }
}
</script>

<style lang="scss">
.DevBomConfirmShare {
  .error {
    color: $red;
  }
}
</style>
