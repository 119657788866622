import Vue from 'vue'

const addFavoriteSeason = (store) => {
  return new Vue({
    data: {
      brand: '',
      error: false,
      gender: '',
      lastSeasonId: false,
      options: false,
      season: '',
      searchState: '',
      type: '',
      year: ''
    },
    computed: {
      brandLabel () {
        return store.codes.seasons.brand[this.brand] || ''
      },
      currentSeason () {
        return store.seasons.findByParts(this)
      },
      genderLabel () {
        return store.codes.seasons.gender[this.gender] || ''
      },
      isEmpty () {
        return (
          this.brand === '' &&
          this.gender === '' &&
          this.season === '' &&
          this.type === '' &&
          this.year === ''
        )
      },
      isValid () {
        return this.currentSeason
      },
      seasonLabel () {
        return store.codes.seasons.season[this.season] || ''
      },
      selectedText () {
        return `${this.brandLabel} ${this.season} ${this.year} ${this.genderLabel} ${this.typeLabel}`
      },
      typeLabel () {
        return store.codes.seasons.type[this.type] || ''
      }
    },
    methods: {
      async add () {
        this.lastSeasonId = this.currentSeason.seasonId
        if (store.favoriteSeasons.find(this.lastSeasonId)) {
          return
        }

        const sortingNumber = store.favoriteSeasons.nextSortingNumber

        try {
          await store.api.post({
            url: '/api/me/seasons',
            body: {
              seasonId: this.lastSeasonId,
              sortingNumber
            }
          })

          store.favoriteSeasons.fetch()
        } catch (ex) {
          // We can ignore the error if they added one they already have
          // on their list.
          if (ex.message !== '400') {
            throw ex
          }
        }
      },
      reset () {
        this.brand = ''
        this.error = false
        this.gender = ''
        this.lastSeasonId = false
        this.season = ''
        this.type = ''
        this.year = ''
      }
    }
  })
}

export default addFavoriteSeason
