<template>
  <div
    v-if="component"
    v-hotkey="keymap"
    class="InfoDialog"
    @click="onCancelClick"
  >
    <div
      class="modalFrame"
      @click.stop
    >
      <h3 class="modalHeader">
        <span class="title">
          {{ title }}
        </span>
        <a @click="onCancelClick">
          <CloseIcon />
        </a>
      </h3>

      <div class="modalContent">
        <component
          :is="component"
          ref="component"
          v-bind="props"
        />
        <!-- <div class="buttons">
          <button
            class="button cancel"
            :class="cancelClass"
            @click="onCancelClick"
          >
            {{ cancelText }}
          </button>
          <button
            class="button confirm"
            :class="confirmClass"
            @click="onConfirmClick"
          >
            {{ confirmText }}
          </button>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import CloseIcon from './icons/CloseIcon.vue'

export default {
  components: {
    CloseIcon
  },
  inject: [ 'store' ],
  computed: {
    keymap () {
      return {
        scope: 'dialogue',
        keys: {
          esc: this.onCancelClick
        }
      }
    },
    cancelClass () {
      return this.cd.cancelClass || ''
    },
    cd () {
      return this.store.alertDialog
    },
    component () {
      return this.cd.component
    },
    props () {
      return this.cd.props
    },
    title () {
      return this.cd.title
    }
  },
  methods: {
    onCancelClick () {
      this.store.alertDialog.cancel()
    }
  }
}
</script>

<style lang="scss">
.InfoDialog {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;

  .modalFrame {
    background-color: white;
    border-radius: 4px;
    position: relative;

    .modalHeader {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 1.7rem;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      background: $page-background;
      box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1), 0 1px 0 rgba(0, 0, 0, 0.05);

      .title {
        margin-right: 3rem;
      }
    }

    .modalContent {
      padding: 4rem;
    }
  }

  .buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 4rem;

    .button {
      margin-left: 2rem;

      &.danger {
        background-color: red;
      }
    }
  }
}
</style>
