import addFavoriteSeason from './addFavoriteSeason.js'
import alerts from './alerts.js'
import api from './api.js'
import assign from './assign.js'
import auth from './auth.js'
import bomGrid from './bomGrid.js'
import bomSearch from './bomSearch.js'
import bomEditor from './bomEditor.js'
import bomEditorApi from './bomEditorApi.js'
import bomReport from './bomReport.js'
import bomReview from './bomReview.js'
import chooseMaterialCode from './chooseMaterialCode.js'
import classes from './classes.js'
import codes from './codes.js'
import components from './components.js'
import config from './config.js'
import confirmationDialog from './confirmationDialog.js'
import alertDialog from './alertDialog.js'
import contextMenu from './contextMenu.js'
import copyFromDevBom from './copyFromDevBom.js'
import copyFromPC9 from './copyFromPC9.js'
import createDevBomFromScratch from './createDevBomFromScratch.js'
import createDevBomPreview from './createDevBomPreview.js'
import devBomRename from './devBomRename.js'
import devBomApi from './devBomApi.js'
import devBoms from './devBoms.js'
import favoriteDevBoms from './favoriteDevBoms.js'
import favoriteSeasons from './favoriteSeasons.js'
import findMaterial from './findMaterial.js'
import lookups from './lookups.js'
import modals from './modals.js'
import pc9Api from './pc9Api.js'
import popups from './popups.js'
import quickBomSearch from './quickBomSearch.js'
import rbac from './rbac.js'
import router from './router.js'
import seasons from './seasons.js'
import seasonSelector from './seasonSelector.js'
import session from './session.js'
import subsections from './subsections.js'
import user from './user.js'
import whereUsed from './whereUsed.js'
import clickStream from './clickStream'

class Store {
  constructor () {
    this.addFavoriteSeason = addFavoriteSeason(this)
    this.alerts = alerts(this)
    this.api = api(this)
    this.assign = assign(this)
    this.auth = auth(this)
    this.bomGrid = bomGrid(this)
    this.bomEditor = bomEditor(this)
    this.bomEditorApi = bomEditorApi(this)
    this.bomReport = bomReport(this)
    this.bomReview = bomReview(this)
    this.bomSearch = bomSearch(this)
    this.chooseMaterialCode = chooseMaterialCode(this)
    this.classes = classes(this)
    this.codes = codes(this)
    this.components = components(this)
    this.config = config(this)
    this.confirmationDialog = confirmationDialog(this)
    this.alertDialog = alertDialog(this)
    this.contextMenu = contextMenu(this)
    this.copyFromDevBom = copyFromDevBom(this)
    this.copyFromPC9 = copyFromPC9(this)
    this.createDevBomFromScratch = createDevBomFromScratch(this)
    this.createDevBomPreview = createDevBomPreview(this)
    this.devBomRename = devBomRename(this)
    this.devBomApi = devBomApi(this)
    this.devBoms = devBoms(this)
    this.favoriteDevBoms = favoriteDevBoms(this)
    this.favoriteSeasons = favoriteSeasons(this)
    this.findMaterial = findMaterial(this)
    this.lookups = lookups(this)
    this.modals = modals(this)
    this.pc9Api = pc9Api(this)
    this.popups = popups(this)
    this.quickBomSearch = quickBomSearch(this)
    this.rbac = rbac(this)
    this.router = router(this)
    this.seasons = seasons(this)
    this.seasonSelector = seasonSelector(this)
    this.session = session(this)
    this.subsections = subsections(this)
    this.user = user(this)
    this.whereUsed = whereUsed(this)
    this.clickStream = clickStream(this)
  }

  init () {
    this.auth.init()
    this.popups.init()
  }
}

export default Store
