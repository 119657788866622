import classesData from './classesData/classesData.js'
import Vue from 'vue'

const classes = (store) => {
  return new Vue({
    data: classesData,
    computed: {
      classLookups () {
        return this.allClasses.reduce((acc, value) => {
          acc[value.value] = value
          return acc
        }, {})
      },
      subclassLookups () {
        return this.allSubclasses.reduce((acc, value) => {
          acc[value.value] = value
          return acc
        }, {})
      }
    }
  })
}

export default classes
