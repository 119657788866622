<template>
  <div class="dashboard-dev-boms FavoriteDevBomList">
    <div class="preCardHeader">
      <h3>
        <DocPageIcon />
        <span>Favorite Development BOMs</span>
      </h3>
      <div class="links">
        <router-link to="/dev-boms">
          See All
        </router-link>
      </div>
    </div>
    <div class="card">
      <div class="dataTable">
        <div class="th">
          <div class="td title">
            <a>Title</a>
          </div>
          <div class="td createdBy">
            <a>Created By</a>
          </div>
          <div class="td lastEdited">
            <a>
              Last Edited
            </a>
          </div>
          <div class="td" />
        </div>
        <Scroller>
          <div
            v-if="isReady && !isEmpty"
            class="favoriteDevBomColumns"
          >
            <FavoriteDevBomItem

              v-for="item in store.favoriteDevBoms.items"
              :key="item.bompartId"
              :item="item"
            />
          </div>
          <div
            v-else-if="isEmpty"
            class="empty"
          >
            <div>
              <p>
                You have not added any Favorite Development BOMs.
              </p>
              <p>
                You can find and create Development BOMs on the
                <router-link to="dev-boms">
                  Development BOMs page
                </router-link>.
              </p>
            </div>
          </div>
          <Spinner
            v-else
            class="stretch"
          />
        </Scroller>
      </div>
    </div>
    <Modal
      name="share-devbom"
      title="Confirm Share"
    >
      <DevBomConfirmShare />
    </Modal>
  </div>
</template>

<script>
import FavoriteDevBomItem from './FavoriteDevBomItem.vue'
import DevBomConfirmShare from '../DevBomLibrary/DevBomConfirmShare.vue'
import DocPageIcon from '../icons/DocPageIcon.vue'
import Modal from '../Modal.vue'
import Scroller from '../Scroller.vue'
import Spinner from '../Spinner.vue'

export default {
  components: {
    DevBomConfirmShare,
    DocPageIcon,
    FavoriteDevBomItem,
    Modal,
    Scroller,
    Spinner
  },
  inject: ['store'],
  computed: {
    isEmpty () {
      return this.store.favoriteDevBoms.isEmpty
    },
    isReady () {
      return this.store.favoriteDevBoms.isReady
    }
  },
  mounted () {
    this.store.favoriteDevBoms.fetch()
  }
}
</script>

<style lang="scss">
.FavoriteDevBomList {
  display: flex;
  flex-direction: column;

  .preCardHeader {
    @include preCardHeader();
  }

  .card {
    @include card;

    overflow: hidden;
    flex-shrink: 1;
    display: flex;
    flex-direction: column;

    .dataTable {
      @include dataTable();

      flex-shrink: 1;
      overflow: hidden;

      .th,
      .tr {
        grid-template-columns: 42% 25% 25% 8%;
      }

      .Scroller {
        padding: 0;
      }

      .Spinner {
        height: 128px;
      }

      .empty {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding: 2rem;
        color: $gray-sec;
        flex-shrink: 1;
      }
    }
  }
}
</style>
