import { addParams } from '../util/url.js'
import parseJson from '../util/parseJson.js'
import Vue from 'vue'

const defaultHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json'
}

const api = (store) => {
  return new Vue({
    methods: {
      buildHeaders (headers = defaultHeaders) {
        if (store.auth.idToken) {
          headers.Authorization = `Bearer ${store.auth.idToken}`
        }
        return headers
      },
      async delete ({ url, params, body, popups }) {
        const headers = this.buildHeaders()
        const urlWithParams = addParams(url, params)
        const promise = fetch(urlWithParams, {
          method: 'DELETE',
          headers,
          body: JSON.stringify(body)
        }).then(this.handleResponse)

        if (popups) {
          store.popups.forPromise(promise, popups)
        }
        return promise
      },
      async get ({ url, params, popups }) {
        const headers = this.buildHeaders()
        const urlWithParams = addParams(url, params)

        const promise = fetch(urlWithParams, {
          method: 'GET',
          headers
        }).then(this.handleResponse)

        if (popups) {
          store.popups.forPromise(promise, popups)
        }
        return promise
      },
      async handleResponse (response) {
        if (response.status === 401) {
          const message = 'Unable to connect. You may have been signed out?'
          throw new Error(message)
        }
        if (response.status > 399 && response.status !== 404) {
          const json = await parseJson(response)
          if (json && json.message) {
            throw new Error(json.message)
          }

          const message = `A network request failed. Status code ${response.status}.`
          throw new Error(message)
        }
        return parseJson(response)
      },
      async post ({ url, params, body, popups }) {
        const headers = this.buildHeaders()
        const urlWithParams = addParams(url, params)

        const promise = fetch(urlWithParams, {
          method: 'POST',
          headers,
          body: JSON.stringify(body)
        }).then(this.handleResponse)

        if (popups) {
          store.popups.forPromise(promise, popups)
        }
        return promise
      },
      async put ({ url, params, body, popups }) {
        const headers = this.buildHeaders()
        const urlWithParams = addParams(url, params)

        const promise = fetch(urlWithParams, {
          method: 'PUT',
          headers,
          body: JSON.stringify(body)
        }).then(this.handleResponse)

        if (popups) {
          store.popups.forPromise(promise, popups)
        }
        return promise
      }
    }
  })
}

export default api
