<template>
  <div
    class="Spinner"
    :style="style"
  >
    <SpinnerIcon />
  </div>
</template>

<script>
import SpinnerIcon from './icons/SpinnerIcon.vue'

export default {
  components: {
    SpinnerIcon
  },
  props: {
    color: {
      type: String,
      default: undefined
    }
  },
  computed: {
    style () {
      return {
        '--color': this.color
      }
    }
  }
}
</script>

<style lang="scss">

.Spinner {
  $offset: 187;
  $duration: 1.4s;

  svg {
    animation: rotator $duration linear infinite;

    circle {
      stroke-dasharray: $offset;
      stroke-dashoffset: 0;
      transform-origin: center;
      animation:
        dash $duration ease-in-out infinite,
        colors ($duration * 4) ease-in-out infinite;
    }
  }

  @keyframes rotator {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(270deg);
    }
  }

  @keyframes colors {
    0% {
      stroke: var(--color, #687179);
    }

    50% {
      stroke: var(--color, #909aa0);
    }

    100% {
      stroke: var(--color, #b3b8bc);
    }
  }

  @keyframes dash {
    0% {
      stroke-dashoffset: $offset;
    }

    50% {
      stroke-dashoffset: calc($offset / 4);
      transform: rotate(135deg);
    }

    100% {
      stroke-dashoffset: $offset;
      transform: rotate(450deg);
    }
  }

  &.stretch {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    flex-grow: 2;
    min-height: inherit;
  }
}

</style>
