const sort = {
  fusible: 1,
  interlining: 2,
  fill: 3,
  pocketing: 12,
  qLoop: 4,
  miscellaneous: 5,
  pocketing2: 13,
  pocketing3: 14,
  pocketing4: 15,
  debossLogo: 16,
  embossLogo: 17,
  engraveLogo: 18
}

const components = Object.keys(sort)

const locations = [
  'backPocketWelt',
  'beltLoop',
  'coinPocketWelt',
  'collarBand',
  'cuff',
  'embroidery',
  'fly',
  'front',
  'frontPocketFlap',
  'internal',
  'internalWaistband',
  'neck',
  'placket',
  'pocket',
  'pocketBack',
  'pocketFlap',
  'pocketWelt',
  'rocap',
  'shell',
  'side',
  'sleeve',
  'waistband',
  'yoke'
]

export default {
  components,
  locations,
  sort
}
