const sort = {
  primaryFinish: 1,
  finishCrossSource: 2,
  finish: 3,
  secondaryFinish: 4,
  press: 5,
  miscellaneous: 6
}

const components = Object.keys(sort)

const locations = []

export default {
  components,
  locations,
  sort
}
