<template>
  <div class="ContextMenu">
    <div
      ref="button"
      class="buttonWrapper"
      @click="onButtonClick"
      @mouseenter="onMouseenter"
      @mouseover="onMouseover"
      @mouseleave="onMouseleave"
    >
      <slot
        name="button"
        :isOpen="isOpen"
      >
        <ContextMenuButton
          :is-open="isOpen"
          @mouseover.native="onMouseover"
        />
      </slot>
    </div>
    <portal
      v-if="isOpen"
      to="context-menu"
    >
      <div
        ref="menuFrame"
        class="menuFrame"
        :class="frameClass"
      >
        <slot name="default" />
      </div>
    </portal>
  </div>
</template>

<script>
import ContextMenuButton from './ContextMenuButton.vue'

export default {
  components: {
    ContextMenuButton
  },
  inject: ['store'],
  props: {
    arrowOffset: {
      type: Number,
      default: 13
    },
    forceBelow: {
      type: Boolean,
      default: false
    },
    frameClassEx: {
      type: String,
      default: ''
    },
    menuId: {
      type: String,
      default: undefined
    },
    useHover: {
      type: Boolean,
      default: false
    },
    xOffset: {
      type: Number,
      default: 0
    },
    yOffset: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      menuFrameClass: 'hidden'
    }
  },
  computed: {
    frameClass () {
      return `${this.menuFrameClass} ${this.frameClassEx}`
    },
    isOpen () {
      return this.store.contextMenu.currentId === this.uid
    },
    uid () {
      return this.menuId || this._uid
    }
  },
  beforeUpdate () {
    // Waiting for the next tick gives the children
    // a chance to render.
    this.$nextTick(this.updateFramePos)
  },
  methods: {
    getButtonPos () {
      return this.$refs.button.getBoundingClientRect()
    },
    onButtonClick (event) {
      event.stopPropagation()
      event.preventDefault()
      this.store.contextMenu.toggle(this.uid)
    },
    onMouseenter (event) {
      event.stopPropagation()
      event.preventDefault()
      if (this.useHover) {
        this.store.contextMenu.show(this.uid)
      }
    },
    onMouseover (event) {
      event.stopPropagation()
      event.preventDefault()
      if (this.useHover) {
        this.store.contextMenu.show(this.uid)
      }
    },
    onMouseleave (event) {
      event.stopPropagation()
      event.preventDefault()
      if (this.useHover) {
        this.store.contextMenu.hide()
      }
    },
    onScroll () {
      this.store.contextMenu.hide()
    },
    updateFramePos () {
      if (this.isOpen && this.$refs.menuFrame) {
        const el = this.$refs.menuFrame
        const menuPos = el.getBoundingClientRect()
        const buttonPos = this.getButtonPos()
        const left = `${buttonPos.left + this.xOffset}px`

        el.style.setProperty('--context-menu-left', left)
        el.style.setProperty('--context-menu-arrow-offset', `${this.arrowOffset}px`)

        const isRoomBelow = buttonPos.bottom + menuPos.height <= window.innerHeight
        if (this.forceBelow || isRoomBelow) {
          this.menuFrameClass = 'below'
          const top = `${buttonPos.bottom + this.yOffset}px`
          el.style.setProperty('--context-menu-top', top)
        } else {
          this.menuFrameClass = 'above'
          const top = `${buttonPos.top - menuPos.height - this.yOffset}px`
          el.style.setProperty('--context-menu-top', top)
        }
      } else {
        this.menuFrameClass = 'hidden'
      }
    }
  }
}
</script>

<style lang="scss">
.context-menu-portal-target {
  .menuFrame {
    $context-menu-shadow: 1px solid rgba(0, 0, 0, 0.1);

    position: fixed;
    background: $white;
    min-width: 19rem;
    border-radius: 4px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    padding: 0.8rem 0;
    transition: 0.5s transform, 0.5s opacity;
    border: $context-menu-shadow;
    left: var(--context-menu-left, 0);
    top: var(--context-menu-top, 0);

    &.hidden {
      opacity: 0;
    }

    &::after {
      content: "";
      width: 12px;
      height: 12px;
      border-radius: 2px;
      transform: rotate(45deg);
      background: $white;
      position: absolute;
      left: var(--context-menu-arrow-offset, 0);
    }

    &.below::after {
      top: -7px;
      border-left: $context-menu-shadow;
      border-top: $context-menu-shadow;
    }

    &.above::after {
      bottom: -7px;
      border-right: $context-menu-shadow;
      border-bottom: $context-menu-shadow;
    }

    li {
      a,
      .menuItem {
        display: block;
        padding: 1.2rem 1.5rem;
        color: $gray;
        transition: $anim-trans-base;

        &:hover {
          background: $gray-lines;
        }
      }

      a:hover {
        color: $blue;
      }

      &.newSection {
        border-top: 1px solid $gray-lines;
      }

      &.danger {
        a:hover {
          color: $red;
        }
      }

      a.noOptionsAvailable {
        cursor: default;

        &:hover {
          background: transparent;
          color: $gray;
        }
      }
    }
  }
}
</style>
