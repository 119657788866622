<template>
  <AutoComplete
    type="users"
    value-key="lscoUserName"
    :labeler="labeler"
    :model="model"
    :name="name"
  />
</template>

<script>
import AutoComplete from '../AutoComplete.vue'

export default {
  components: {
    AutoComplete
  },
  props: {
    model: {
      type: Object,
      required: true
    },
    name: {
      type: String,
      required: true
    }
  },
  methods: {
    labeler (item) {
      return `${item.lscoFullName} (${item.lscoUserName})`
    }
  }
}
</script>
