<template>
  <div class="Dashboard page">
    <DashboardSearch />
    <section class="favorites">
      <FavoriteDevBomList v-if="!viewOnly" />
      <FavoriteSeasonCard />
    </section>
  </div>
</template>

<script>
import DashboardSearch from '../components/Dashboard/DashboardSearch.vue'
import FavoriteDevBomList from '../components/Dashboard/FavoriteDevBomList.vue'
import FavoriteSeasonCard from '../components/Dashboard/FavoriteSeasonCard.vue'

export default {
  inject: ['store'],
  components: {
    DashboardSearch,
    FavoriteDevBomList,
    FavoriteSeasonCard
  },
  computed: {
    viewOnly () {
      return this.store.rbac.hasPermission('view', 'devBoms')
    }
  }
}
</script>

<style lang="scss">
.Dashboard.page {
  height: 100%;
  display: flex;
  flex-direction: column;

  section.favorites {
    @include centerColumn;

    padding-top: 3rem;
    padding-bottom: 2rem;
    display: flex;
    flex-grow: 1;
    flex-shrink: 1;
    justify-content: space-between;
    overflow: hidden;

    .FavoriteDevBomList {
      width: 70%;
      padding-right: 2.5rem;
      height: 100%;
    }

    .FavoriteSeasonCard {
      width: 30%;
      height: 100%;
    }
  }
}
</style>
