import Vue from 'vue'

var _id = 0
const defaultTimeout = 10000

export default (store) => {
  return new Vue({
    data: {
      messages: []
    },
    methods: {
      add (message) {
        if (!message.id) {
          _id++
          message.id = _id
        }

        if (message.timeout === undefined) {
          message.timeout = defaultTimeout
        }

        if (message.timeout > 0 && message.promise === undefined) {
          message.exp = new Date().valueOf() + message.timeout
        }
        this.messages.push(message)
        return message.id
      },
      init () {
        setInterval(this.tick, 1000)
      },
      remove (id) {
        this.messages = this.messages.filter((message) => {
          return message.id !== id
        })
      },
      resolveParam (param) {
        if (typeof param === 'function') {
          return param()
        }
        return param
      },
      tick () {
        const now = new Date().valueOf()
        this.messages = this.messages.filter((message) => {
          if (message.exp) {
            return message.exp > now
          }
          return true
        })
      },
      update (updates) {
        this.messages = this.messages.map((message) => {
          if (message.id === updates.id) {
            if (updates.timeout > 0 || message.timeout > 0) {
              const timeout = (updates.timeout || message.timeout)
              const exp = new Date().valueOf() + timeout
              return { ...message, ...updates, ...{ exp } }
            }
            return { ...message, ...updates }
          }
          return message
        })
      },
      async forPromise (promise, { errorText, linkTarget, linkText, successText, workingText }) {
        const id = this.add({
          promise,
          text: this.resolveParam(workingText)
        })
        try {
          await promise
          this.update({
            id,
            text: this.resolveParam(successText),
            linkTarget: this.resolveParam(linkTarget),
            linkText: this.resolveParam(linkText)
          })
        } catch (error) {
          this.update({
            id,
            error: error.message,
            text: this.resolveParam(errorText)
          })
        }
      }
    }
  })
}
